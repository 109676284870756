import {
  Avatar,
  Box,
  Button,
  Checkbox as MuiCheckbox,
  IconButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  styled,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from '@mui/material';

export const TableWrapper = styled(Box)`
  padding: 12px 20px;
`;

export const ActionButton = styled(Button)`
  gap: 0 4px;
  padding: 6px 12px;
  font-weight: 600;
  color: #fff;
  background-color: rgba(21, 22, 45, 1);
  border: 1px solid rgba(255, 255, 255, 0.12);
`;

export const TableRow = styled(MuiTableRow, {
  shouldForwardProp: (prop) => prop !== 'isLastRow',
})<{ isLastRow: boolean }>`
  border-bottom: ${({ isLastRow }) =>
    isLastRow ? 'none' : '1px solid #E6ECF1'};

  td {
    border-bottom: ${({ isLastRow }) =>
      isLastRow ? 'none' : '1px solid rgba(224, 224, 224, 1)'};
  }
`;

export const TableCell = styled(MuiTableCell, {
  shouldForwardProp: (prop) => prop !== 'isFirstCol',
})<{ isFirstCol?: boolean }>`
  width: ${({ isFirstCol }) => (isFirstCol ? '48px' : 'auto')};
  padding: ${({ isFirstCol }) => (isFirstCol ? '0 2px' : '0 12px')};
  color: ${({ theme }) => theme.palette.info.dark};
`;

export const Checkbox = styled(MuiCheckbox)`
  &.MuiCheckbox-root {
    svg path {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const UserAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
`;

export const Status = styled(Box)`
  display: inline-flex;
  align-items: center;
  gap: 0 8px;
  padding: 4px 6px 4px 8px;
  border-radius: 6px;
`;

export const Dot = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const MenuButton = styled(IconButton)`
  padding: 0;
  border-radius: 6px;
`;

export const Menu = styled(MuiMenu)`
  .MuiPaper-root {
    width: 210px;
    border: 1px solid rgba(38, 40, 66, 0.12);
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgba(38, 40, 66, 0.1);
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  gap: 0 8px;
  color: rgba(38, 40, 66, 1);

  svg {
    color: rgba(139, 140, 158, 1);
  }
`;
