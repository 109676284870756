import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

type Props = {
  children: ReactNode;
  title?: string;
  maxWidth?: number;
};

export const OverflowTooltip: FC<Props> = ({ children, title, maxWidth }) => {
  const tooltipRef = useRef<HTMLDivElement>();
  const [isOverflowed, setIsOverflow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (tooltipRef.current) {
        setIsOverflow(
          tooltipRef?.current?.scrollWidth > tooltipRef?.current?.clientWidth
        );
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Tooltip
      title={title ? title : children}
      arrow
      disableHoverListener={!isOverflowed}
    >
      <div
        ref={tooltipRef}
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: `${maxWidth}px`,
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
