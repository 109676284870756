export enum MatomoKeys {
  // Когда пользователь кликнул на Витрину
  CLICK_TAB_ALL_COURSES = 'Click_on_tab_all_courses',
  // Юзер кликнул на Мои курсы
  CLICK_TAB_MY_COURSES = 'Click_on_tab_my_courses',
  // Юзер кликнул "Назначить обучение"
  CLICK_BTN_ASSING_TRAINING = 'Click_on_btn_assign_training',
  // Команда "Назначить" в конце процессе назначения
  CLICK_SUBMIT_ASSIGN = 'Click_on_submit_assign',
  // Клик на таб Прогресс обучения
  CLICK_TAB_PROGRESS = 'Click_on_tab_progress',
  // Когда юзер кликнул на курс чтобы посмотреть прогресс по студентам
  CLICK_ON_TABLE_ROW_COURSE_PROGRESS = 'Click_on_table_row_course_progress',
  // Когда юзер кликнул в Списке сотрудников
  CLICK_ON_TABLE_ROW_EMPLOYEE_STAT = 'Click_on_table_row_employee_stat',
  // Клик на таб Список сотрудников
  CLICK_TAB_EMPLOYEE_LIST = 'Click_on_tab_employee_list',
  // Клик на таб Управление курсами
  CLICK_TAB_COURSE_MANAGE = 'Click_on_tab_course_manage',
  // Студент нажал на Получить доступ
  CLICK_BTN_COURSE_GET_ACCESS = 'Click_on_btn_get_access',
  // Студент кликнул на "Начать обучение" в модалке
  CLICK_BTN_COURSE_START = 'Click_on_btn_start_course',
  // Студент кликнул на продолжить обучение
  CLICK_BTN_COURSE_CONTINIUE = 'Click_on_btn_continue_course',
  // Юзер кликнул на Создать курс
  CLICK_BTN_CREATE_COURSE = 'Click_on_btn_create_course',
  // Когда после выбора воркспейса (-ов) кликнул на Опубликовать
  CLICK_ON_SUBMIT_COURSE_PUBLISH = 'Click_on_submit_course_publish',
}

export const addMatomoEvent = (
  matomo: MatomoKeys,
  additionalInfo?: Record<string, unknown>
) => {
  try {
    const workspace = JSON.parse(localStorage.getItem('workspace'));
    window._paq?.push([
      'trackEvent',
      'Training',
      matomo,
      JSON.stringify({
        holdingId: workspace?.holdingId,
        companyId: workspace?.id,
        additionalInfo,
      }),
    ]);
  } catch (e) {
    console.error(e);
  }
};
