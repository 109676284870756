import { FC, useMemo } from 'react';
import { AssignCourseForm, FeedCourseItem } from '@dar/api-interfaces';
import { AutoCompleteSelect } from '@dartech/dms-ui';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<AssignCourseForm, any>;
  coursesList: FeedCourseItem[];
  locale: { search: string; apply: string; clear: string };
  loading: boolean;
};

export const CourseSelect: FC<Props> = ({
  control,
  coursesList,
  locale,
  loading,
}) => {
  const { t } = useTranslation();

  const coursesForSelect = useMemo(() => {
    return coursesList.map((course) => ({
      id: course.id,
      name:
        course.course_info.fullname || (course.course_info['name'] as string),
    }));
  }, [coursesList]);

  return (
    <Controller
      name="courses"
      control={control}
      rules={{
        validate: (val) => {
          if (!val || (val && val.length === 0)) {
            return t('assign_course.courselist_empty_error');
          }
        },
      }}
      render={({ field, fieldState }) => (
        <AutoCompleteSelect
          {...field}
          locale={locale}
          loading={loading}
          error={fieldState?.error?.message}
          label={t('assign_course.course_label')}
          onSelect={(value: { id: string; name: string }) =>
            field.onChange(value)
          }
          popperWidth={412}
          prompt={t('assign_course.prompt')}
          valueId="id"
          valueName="name"
          variant="filter"
          options={coursesForSelect}
          // styleClasses={{
          //   outlinedButton: styles.select,
          //   error: styles.autocompleteError,
          // }}
          value={field.value as unknown as { id: string; name: string }}
        />
      )}
    />
  );
};
