import { FC } from 'react';
import { Typography } from '@mui/material';
import theme from 'apps/dar-front/src/theme';
import { useTranslation } from 'react-i18next';
import { SectionIndex, SelectorContainer } from './CourseSectionSelector.style';

type Props = {
  activeSection: number;
  sectionIndexes: number[];
  onChangeSection: (sectionId: number) => void;
};

export const CourseSectionSelector: FC<Props> = ({
  activeSection,
  onChangeSection,
  sectionIndexes,
}) => {
  const { t } = useTranslation();
  return (
    <SelectorContainer>
      <Typography variant="h6" sx={{ margin: '16px' }}>
        {t('progress.detailtable_section')}
      </Typography>
      {sectionIndexes.map((section, idx) => (
        <SectionIndex
          key={section}
          variant={activeSection === section ? 'contained' : 'text'}
          sx={{
            color:
              activeSection === section ? undefined : theme.palette.info.dark,
          }}
          onClick={() => onChangeSection(section)}
        >
          {idx + 1}
        </SectionIndex>
      ))}
    </SelectorContainer>
  );
};
