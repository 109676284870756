import { Box, styled } from '@mui/material';

export const Root = styled(Box)`
  display: flex;
  width: 100%;
  flex: 1;
`;

export const Sidebar = styled(Box)`
  width: 300px;
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 0;
`;
