import React, { FC, useCallback, useState } from 'react';
import { AssignSort, StatusEnum } from '@dar/api-interfaces';
import { DropdownMenuButton } from '@dar/components/dropdownMenuButton';
import { SearchInput } from '@dar/components/searchInput';
import { useDepartmentPositionDirectory } from '@dar/hooks/useDepartmentPoistionDirectory';
import { getAssigns } from '@dar/services/5q';
import { Box, CircularProgress, MenuItem, Typography } from '@mui/material';
import { useChannelInfo } from 'apps/dar-front/src/contexts/channel.context';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkmark.svg';
import { ReactComponent as NotFoundIcon } from '../../../assets/icons/course-empty.svg';
import { EmptyContainer } from '../employeesList/components/employeesTable/EmployeesTable.style';
import { Toolbar } from './AssignsPage.styles';
import { AssignTable } from './components/AssignTable';
import { getStatusText } from './utils';

type Props = {
  courseId: number;
};

export const AssignsPage: FC<Props> = ({ courseId }) => {
  const { t } = useTranslation();
  const { channelId } = useChannelInfo();
  const [search, setSearch] = React.useState('');
  const [sort, setSort] = useState<AssignSort>({
    field: 'name',
    sort_criteria: 'asc',
  });
  const [filter, setFilter] = React.useState<StatusEnum | undefined>();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
  });
  const [nextPageClickDisabled, setNextPageClickDisabled] = useState(false);

  const { departmentsList } = useDepartmentPositionDirectory();

  const {
    data: assigns,
    isFetching,
    refetch,
  } = useQuery(
    [
      'assigns',
      courseId,
      search,
      sort,
      filter,
      paginationData,
      departmentsList,
      channelId,
    ],
    {
      enabled:
        Boolean(courseId) && Boolean(departmentsList) && Boolean(channelId),
      queryFn: async () => {
        return getAssigns({
          course_id: `course_${courseId}`, //
          page: paginationData.page.toString(),
          size: paginationData.size.toString(),
          search,
          sort_by: sort,
          filter_by: filter
            ? { status: [filter], channel_id: `${channelId}` }
            : {
                channel_id: `${channelId}`,
              },
        }).then(
          (res) =>
            res.assigns?.map((assign) => {
              const dep = departmentsList?.find(
                (p) => p.localization['en'] === assign.Employee.department
              )?.value;

              return {
                ...assign,
                Employee: {
                  ...assign.Employee,
                  department: dep || assign.Employee.department,
                },
              };
            }) || []
        );
      },
      onSuccess(data) {
        if (data === null) {
          return [];
        }
        if (data.length > rowsPerPage) {
          setNextPageClickDisabled(false);
          return data.splice(-1);
        } else {
          setNextPageClickDisabled(true);
          return data;
        }
      },
      onError(err) {
        console.error(err);
      },
    }
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPaginationData({
      page: 0,
      size: parseInt(event.target.value, 10),
    });
  };

  const nextPageClick = () => {
    setPaginationData((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const prevPageClick = useCallback(() => {
    setPaginationData((prev) => ({ ...prev, page: prev.page - 1 }));
    if (nextPageClickDisabled) {
      setNextPageClickDisabled(false);
    }
  }, [nextPageClickDisabled]);

  const menuItems = [
    {
      label: getStatusText(StatusEnum.PROGRESS, t),
      value: StatusEnum.PROGRESS,
    },
    {
      label: getStatusText(StatusEnum.COMPLETED, t),
      value: StatusEnum.COMPLETED,
    },
    {
      label: getStatusText(StatusEnum.OVERDUE, t),
      value: StatusEnum.OVERDUE,
    },
    {
      label: getStatusText(StatusEnum.NOT_STARTED, t),
      value: StatusEnum.NOT_STARTED,
    },
  ];

  return (
    <Box>
      <Toolbar>
        <SearchInput onChange={setSearch} />
        <DropdownMenuButton
          fieldname="status"
          buttonText={t('assign_table.status')}
          onClearClick={() => setFilter(undefined)}
          isFilterActive={!!filter}
          activeValueTitle={getStatusText(filter, t)}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={item.value}
              sx={{
                justifyContent: 'space-between',
                padding: '11px 12px',
                backgroundColor:
                  filter === item.value
                    ? 'rgba(230, 245, 253, 1)'
                    : 'transparent',
                marginBottom: menuItems.length - 1 === index ? '8px' : 0,
              }}
              onClick={() => setFilter(item.value)}
            >
              {item.label}
              {filter === item.value && (
                <CheckIcon color="rgba(3, 155, 230, 1)" />
              )}
            </MenuItem>
          ))}
        </DropdownMenuButton>
      </Toolbar>
      {isFetching ? (
        <EmptyContainer>
          <CircularProgress size={50} />
        </EmptyContainer>
      ) : assigns && assigns.length > 0 ? (
        <AssignTable
          assigns={assigns}
          sort={sort}
          nextPageClickDisabled={nextPageClickDisabled}
          paginationData={paginationData}
          rowsPerPage={rowsPerPage}
          courseId={courseId}
          onSort={setSort}
          onNextPageClick={nextPageClick}
          onPrevPageClick={prevPageClick}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onRefetch={() => refetch()}
        />
      ) : (
        <EmptyContainer>
          <NotFoundIcon />
          <Typography variant="h5" sx={{ color: '#6D6E85', marginTop: '16px' }}>
            {t('assigns.not_assigns')}
          </Typography>
        </EmptyContainer>
      )}
    </Box>
  );
};
