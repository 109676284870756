import { FC } from 'react';
import { StudentProgress } from '@dar/api-interfaces';
import { getEmployeesByEmailsOrIds } from '@dar/services/users';
import { TimeUtil } from '@dar/utils/timeUtil';
import { theme } from '@dartech/dms-ui';
import { Box, TableCell, Tooltip, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as Check } from '../../../../../../../assets/icons/checkmark.svg';
import { ReactComponent as Dissmiss } from '../../../../../../../assets/icons/dismiss.svg';
import {
  AccessText,
  StickyTableCell,
  StyledProgressBar,
  StyledTableBody,
  StyledTableRow,
  UserAvatar,
} from './JournalTableBody.style';

type Props = {
  studentProgresses: StudentProgress[];
};

export const JournalTableBody: FC<Props> = ({ studentProgresses }) => {
  const { t } = useTranslation();

  const { data, isFetching } = useQuery(['hcms-employees-journal'], () => {
    const userEmails = studentProgresses.map((student) => student.email);
    return getEmployeesByEmailsOrIds({ emails: userEmails });
  });

  return (
    <StyledTableBody>
      {studentProgresses.map((student, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        const enrollDateFormatted = format(
          new Date(Number(student.enrolldate) * 1000),
          'dd.MM.yyyy'
        );
        const daysAgo = differenceInDays(
          new Date(),
          new Date(Number(student.lastaccess) * 1000)
        );
        const lastAccessIsMoreThanMonthAgo = daysAgo > 30;

        const userInfo = data?.find(
          (user) => user.employeeInfo.email === student.email
        );

        return (
          <StyledTableRow tabIndex={-1} key={student.id} hover>
            <StickyTableCell>
              <Box display="flex" alignItems="center">
                <UserAvatar
                  alt={student.fullname}
                  src={userInfo?.employeeInfo.photo}
                />
                <Typography variant="h6">{student.fullname}</Typography>
              </Box>
            </StickyTableCell>
            <TableCell id={labelId}>
              <Typography sx={{ width: '100px' }} variant="body1">
                {enrollDateFormatted}
              </Typography>
            </TableCell>
            <TableCell>
              {student.lastaccess ? (
                <Tooltip
                  arrow
                  placement="top"
                  title={TimeUtil.getAgoTime(Number(student.lastaccess), t)}
                >
                  <AccessText
                    color={lastAccessIsMoreThanMonthAgo ? 'error' : 'initial'}
                  >
                    {TimeUtil.getAgoTime(Number(student.lastaccess), t)}
                  </AccessText>
                </Tooltip>
              ) : (
                '-'
              )}
            </TableCell>

            <TableCell>
              <Box
                display="flex"
                alignItems="center"
                color={theme.palette.secondary['40']}
              >
                <StyledProgressBar
                  variant="determinate"
                  color={+student.progress === 100 ? 'secondary' : 'primary'}
                  value={Number(student.progress)}
                />
                <span>{student.progress}%</span>
              </Box>
            </TableCell>
            {student.mods.map((mod) => (
              <TableCell
                sx={{ color: mod.is_completed ? '#2DB77B' : undefined }}
                key={mod.name}
              >
                {mod.is_completed ? <Check /> : <Dissmiss />}
              </TableCell>
            ))}
          </StyledTableRow>
        );
      })}
    </StyledTableBody>
  );
};
