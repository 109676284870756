import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import {
  Wrapper,
  Button,
  CounterText,
  Divider,
  IconButton,
} from './ActionToolbar.styles';

type Props = {
  selectCount: number;
  buttons: ReactNode;
  onSelectAllClick: () => void;
  onClearClick: () => void;
};

export const ActionToolbar: FC<Props> = ({
  selectCount,
  buttons,
  onSelectAllClick,
  onClearClick,
}) => {
  return (
    <Wrapper>
      <Box display="flex" alignItems="center" gap="0 8px">
        <Button onClick={onSelectAllClick}>Выбрать все</Button>
        {selectCount > 0 && <CounterText>{selectCount} выбрано</CounterText>}
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" gap="0 8px">
        {buttons}
      </Box>
      <IconButton onClick={onClearClick}>
        <CloseIcon />
      </IconButton>
    </Wrapper>
  );
};
