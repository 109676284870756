import { useState } from 'react';
import { EmployeeAnalyticsDto } from '@dar/api-interfaces';
import { MatomoKeys, addMatomoEvent } from '@dar/utils/metrics';
import { AnalyticsLayout } from 'apps/dar-front/src/layout/analytics/AnalyticsLayout';
import { useTranslation } from 'react-i18next';
import { EmployeesTable } from './components/employeesTable/EmployeesTable';
import { EmployeeStatictics } from './components/employeeStatistics/EmployeeStatictics';
import { ContentWrapper } from './EmployeeListPage.style';

export const EmployeesListPage = () => {
  const { t } = useTranslation();
  const [employee, setEmployee] = useState<EmployeeAnalyticsDto | null>(null);
  const [employeeStatsOpen, setEmployeeStatsModalOpen] = useState(false);

  const handleEmployeeClick = (employee: EmployeeAnalyticsDto) => {
    addMatomoEvent(MatomoKeys.CLICK_ON_TABLE_ROW_EMPLOYEE_STAT, {
      employeeId: employee.employeeId,
    });
    setEmployee(employee);
    setEmployeeStatsModalOpen(true);
  };

  const handleModalClose = () => {
    setEmployeeStatsModalOpen(false);
    setEmployee(null);
  };

  return (
    <AnalyticsLayout breadcrumbs={t('analytics.employees_analytics')}>
      <ContentWrapper>
        <EmployeesTable onEmployeeClick={handleEmployeeClick} />
        {employee && employeeStatsOpen && (
          <EmployeeStatictics
            open={employeeStatsOpen}
            onClose={handleModalClose}
            employee={employee}
          />
        )}
      </ContentWrapper>
    </AnalyticsLayout>
  );
};
