import { Box, styled } from '@mui/material';

export const Toolbar = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  borderBottom: `1px solid #26284214`,
  padding: '12px 24px',
  backgroundColor: 'white',
}));

export const EmptyContainer = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 223px)',
  width: 'calc(100vw - 346px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  columnGap: '16px',
  color: theme.palette.secondary['50'],
}));

export const ContentWrapper = styled(Box)(() => ({
  width: '100%',
  height: 'calc(100vh - 228px)',
  padding: '16px 14px',
  backgroundColor: '#F7F8FC',
  overflowY: 'auto',
}));
