import {
  DirectoryDefinitionDTO,
  DirectoryEntityDTO,
  Employee,
  WorkspaceByHolding,
} from '@dar/api-interfaces';
import { environment } from '@dar/environments/environment';
import { apiClient } from './clients';

export const getEmployeesList = (controller?: AbortController) => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return apiClient
    .get<Employee[]>(
      `${environment.dmsBaseApi}/hcms/main-api/structure/${workspace.id}/employees`,
      {
        params: {
          status: 'ACTIVE',
        },
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const getDirectoriesList = (controller?: AbortController) => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return apiClient
    .get<DirectoryDefinitionDTO[]>(
      `${environment.hcmsBaseApi}/directory/list/new/v2?holdingId=${workspace.holdingId}`,
      { signal: controller?.signal }
    )
    .then((res) => res.data);
};

export const getDirectoryById = (
  directoryId: string,
  controller?: AbortController
) => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return apiClient
    .get<{ content: DirectoryEntityDTO[] }>(
      `${environment.hcmsBaseApi}/directory/list/new/v2/${directoryId}?holdingId=${workspace.holdingId}`,
      {
        signal: controller?.signal,
        headers: {
          'Accept-language': localStorage.getItem('i18nextLng'),
        },
      }
    )
    .then((res) => res.data.content);
};

export const getEmployeesByEmailsOrIds = (
  body: { emails?: string[]; employeeIds?: string[] },
  controller?: AbortController
) => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return apiClient
    .get<Employee[]>(
      `${environment.dmsBaseApi}/hcms/main-api/structure/in-holding/all?holdingId=${workspace.holdingId}`,
      {
        params: {
          emails: body.emails?.toString(),
          employeeIds: body.employeeIds?.toString(),
          statuses: 'ACTIVE',
        },
        headers: {
          'dar-dms-user-id': JSON.parse(localStorage.getItem('profile'))?.id,
          'dar-dms-org-id': JSON.parse(localStorage.getItem('workspace'))?.id,
        },
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const getEmployeeItem = (profileId: string, companyId: string) => {
  return apiClient
    .get<{ name: { first: string; last: string } }>(
      `${environment.dmsBaseApi}/hcms/main-api/employee/${profileId}/shortinfo`,
      {
        params: {
          companyId,
        },
        headers: {
          'dar-dms-user-id': JSON.parse(localStorage.getItem('profile'))?.id,
          'dar-dms-org-id': JSON.parse(localStorage.getItem('workspace'))?.id,
        },
      }
    )
    .then((res) => res.data.name);
};

export const getWorkspaces = () => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return apiClient
    .get<WorkspaceByHolding[]>(
      `${environment.dmsBaseApi}/hcms/main-api/workspace/v2/all`,
      {
        params: {
          holdingId: workspace.holdingId,
        },
      }
    )
    .then((res) => res.data);
};
