import { FC } from 'react';
import { AssignEditInfo } from '@dar/api-interfaces';
import { deleteAssigns } from '@dar/services/5q';
import { Button } from '@dartech/dms-ui';
import { Modal } from '@mui/material';
import { useChannelInfo } from 'apps/dar-front/src/contexts/channel.context';
import { useTranslation } from 'react-i18next';
import { useToaster } from '../../../../../contexts/toast.context';
import {
  Wrapper,
  Header,
  ModalTitle,
  StyledCloseIcon,
  Footer,
  Body,
  Description,
} from './DeleteModal.styles';

type Props = {
  open: boolean;
  courseId?: number;
  employeeInfo?: AssignEditInfo;
  onClose: () => void;
  onRefetch: () => void;
};

export const DeleteModal: FC<Props> = ({
  open,
  courseId,
  employeeInfo,
  onClose,
  onRefetch,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToaster();
  const { channelId } = useChannelInfo();
  if (!employeeInfo && !courseId) {
    return null;
  }

  const handleDeleteClick = async () => {
    const { deleted } = await deleteAssigns({
      course_id: `course_${courseId}`,
      employee_ids: employeeInfo.id,
      channel_id: `${channelId}`,
    });

    if (deleted) {
      showToast(t('assigns_delete.delete_success'), 'success');

      onClose();
      onRefetch();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Wrapper>
        <Header>
          <ModalTitle>
            {t('assigns_delete.title')} ({employeeInfo.id.length}) ?
          </ModalTitle>
          <StyledCloseIcon onClick={onClose} />
        </Header>
        <Body>
          <Description>{t('assigns_delete.description')}</Description>
        </Body>
        <Footer>
          <Button onClick={onClose}>{t('assigns_delete.cancel_btn')}</Button>
          <Button variant="contained" onClick={handleDeleteClick}>
            {t('assigns_delete.delete_btn')}
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};
