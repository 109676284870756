import { ChangeEvent, FC } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants';
import { PaginationButton } from './TablePaginationWOTotal.style';

type Props = {
  rowsPerPage: number;
  paginationData: { page: number; size: number };
  nextPageClickDisabled: boolean;
  handleChangeRowsPerPage: (e: ChangeEvent<any>) => void;
  elementsOnPage: number;
  nextPageClick: () => void;
  prevPageClick: () => void;
};

export const TablePaginationWOTotal: FC<Props> = ({
  rowsPerPage,
  paginationData,
  elementsOnPage,
  handleChangeRowsPerPage,
  nextPageClickDisabled,
  nextPageClick,
  prevPageClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      paddingTop="16px"
    >
      <Typography style={{ marginRight: '24px', color: '#6D6E85' }}>
        {rowsPerPage * paginationData.page + 1} -{' '}
        {rowsPerPage > elementsOnPage
          ? elementsOnPage + paginationData.page * rowsPerPage
          : (paginationData.page + 1) * rowsPerPage}{' '}
        {t('employee_statistics.pagination_results')}
      </Typography>
      <Typography style={{ color: '#6D6E85', marginRight: '8px' }}>
        {t('progress.table_pagination_show')}
      </Typography>
      <Select
        onChange={handleChangeRowsPerPage}
        value={rowsPerPage || 10}
        style={{ marginRight: '16px', borderRadius: '10px' }}
        IconComponent={ExpandMoreIcon}
      >
        {ROWS_PER_PAGE_OPTIONS.map((size) => (
          <MenuItem key={size} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
      <PaginationButton
        variant="outlined"
        onClick={prevPageClick}
        disabled={paginationData.page === 0}
      >
        <LeftIcon />
      </PaginationButton>
      <PaginationButton
        variant="outlined"
        onClick={nextPageClick}
        disabled={nextPageClickDisabled}
      >
        <RightIcon />
      </PaginationButton>
    </Box>
  );
};
