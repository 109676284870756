import { FC } from 'react';
import { CourseStatuses } from '@dar/api-interfaces';
import { updateCourse } from '@dar/services/5q';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/icons/dismiss-regular.svg';
import {
  Body,
  Button,
  CloseButton,
  Footer,
  Header,
  Title,
  Wrapper,
} from './archiveCourseModal.styles';

type Props = {
  courseId: null | number;
  onClose: () => void;
  onSuccess: () => void;
};

export const ArchiveCourseModal: FC<Props> = ({
  courseId,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const handleOnArchive = async () => {
    if (!courseId) return;

    const response = await updateCourse({
      courses: [
        {
          customfields: [
            {
              type: 'course_status',
              value: `${CourseStatuses.ARCHIVED}`,
            },
          ],
          id: courseId,
        },
      ],
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <Modal open={!!courseId} onClose={onClose}>
      <Wrapper>
        <Header>
          <Title>{t('archive_course_modal.title')}</Title>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Body>{t('archive_course_modal.description')}</Body>

        <Footer>
          <Button variant="outlined" onClick={onClose}>
            {t('archive_course_modal.cancel_btn')}
          </Button>
          <Button variant="contained" onClick={handleOnArchive}>
            {t('archive_course_modal.submit_btn')}
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};
