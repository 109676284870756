import React, { ChangeEvent, FC } from 'react';
import { AssignSort, AssignSortField } from '@dar/api-interfaces';
import {
  styled,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableSortLabel as MuiTableSortLabel,
  Checkbox as MuiCheckbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SortIcon } from '../../../../../../assets/icons/sort_icon_default.svg';

type Props = {
  sort: AssignSort;
  selectedLength: number;
  rowCount: number;
  onSortClick: (v: AssignSort) => void;
  onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TableHeader: FC<Props> = ({
  sort,
  selectedLength,
  rowCount,
  onSortClick,
  onSelectAll,
}) => {
  const { t } = useTranslation();

  const rows: {
    label: string;
    sort: AssignSortField;
    align: 'left' | 'right';
  }[] = [
    {
      label: t('assign_table.name'),
      sort: 'name',
      align: 'left',
    },
    {
      label: t('assign_table.department'),
      sort: 'department',
      align: 'left',
    },
    {
      label: t('assign_table.assign_date'),
      sort: 'created',
      align: 'right',
    },
    {
      label: t('assign_table.start_date'),
      sort: 'started',
      align: 'right',
    },
    {
      label: t('assign_table.end_date'),
      sort: 'ended',
      align: 'right',
    },
    {
      label: t('assign_table.finish_date'),
      sort: 'finished',
      align: 'right',
    },
    {
      label: t('assign_table.status'),
      sort: 'status',
      align: 'left',
    },
  ];

  const handleSortClick = (sortField: AssignSortField) => {
    if (sortField === sort.field) {
      onSortClick({
        field: sort.field,
        sort_criteria: sort.sort_criteria === 'asc' ? 'desc' : 'asc',
      });
    } else {
      onSortClick({ field: sortField, sort_criteria: 'asc' });
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell isFirstCol>
          <Checkbox
            indeterminate={selectedLength > 0 && selectedLength < rowCount}
            checked={rowCount > 0 && selectedLength === rowCount}
            onChange={onSelectAll}
          />
        </TableCell>
        {rows.map((row) => (
          <TableCell key={row.sort} align={row.align}>
            <TableSortLabel
              onClick={() => handleSortClick(row.sort)}
              IconComponent={SortIcon}
              active={sort.field === row.sort}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

const TableCell = styled(MuiTableCell, {
  shouldForwardProp: (prop) => prop !== 'isFirstCol',
})<{ isFirstCol?: boolean }>`
  width: ${({ isFirstCol }) => (isFirstCol ? '48px' : 'auto')};
  padding: ${({ isFirstCol }) => (isFirstCol ? '0 2px' : '0 12px')};
  font-weight: 600;
  background-color: #f7f8fc;
  color: #6d6e85;
`;

export const TableSortLabel = styled(MuiTableSortLabel)`
  position: relative;
  flex-direction: row;
  color: #6d6e85;

  & svg {
    opacity: 1;
    overflow: initial;

    &$active {
      &svg {
        &path {
          fill: #262842 !important;
        }
      }
    }
  }

  &.MuiTableSortLabel-active > svg > path {
    fill: #262842;
  }
`;

export const Checkbox = styled(MuiCheckbox)`
  &.MuiCheckbox-root {
    svg path {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
