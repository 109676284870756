import { Box, Button, styled } from '@mui/material';

export const SelectorContainer = styled(Box)(() => ({
  width: '66px',
  height: 'calc(100vh - 228px)',
  overflowY: 'auto',
  overflowX: 'hidden',
  background: 'white',
  borderRight: '1px solid #26284214',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '4px',
  '& ::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
}));

export const SectionIndex = styled(Button)(() => ({
  width: '40px',
  minWidth: 'unset',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '11px 16px',
}));
