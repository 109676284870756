import React, { useEffect, useMemo, useState } from 'react';
import { MyAssignedCoursesInfo, StatusEnum } from '@dar/api-interfaces';
import { CourseCard } from '@dar/components/courseCard';
import { CoursesNotFound } from '@dar/components/coursesNotFound';
import { DropdownMenuButton } from '@dar/components/dropdownMenuButton';
import { SearchInput } from '@dar/components/searchInput';
import {
  getMyInvitedCourses,
  setNotificationsCounterRead,
} from '@dar/services/5q';
import { MenuItem, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkmark.svg';
import { getStatusText } from '../assignsPage/utils';
import { ContentWrapper, Grid, Toolbar } from './MyCoursesPage.styles';

export const MyCoursesPage = () => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState<MyAssignedCoursesInfo[]>([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = React.useState<StatusEnum | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const profile = JSON.parse(localStorage.getItem('profile'));

    getMyInvitedCourses({
      page: 0,
      size: 100,
      filter_by: { status: filter },
      user_id: profile.id,
    })
      .then(({ assigns }) => {
        if (!assigns) {
          setCourses([]);
          return;
        }

        setCourses(
          assigns.map((course) => {
            return {
              id: course.course_id.replace('course_', ''),
              fullName: course.course_name,
              endDate: course.end_date,
              assignedForm: course.assigned_from,
              status: course.status,
              progress: course.progress,
              completed_activities: 0,
              total_activities: 0,
              total_sections: 0,
              courseCardImage: '',
              endAction: course.end_action,
            };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  const filteredCourses = useMemo(() => {
    if (!search) {
      return courses;
    }
    return courses.filter((c) =>
      c.fullName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  }, [search, courses]);

  useEffect(() => {
    setTimeout(() => {
      setNotificationsCounterRead().then((res) => {
        console.log(res);
      });
    }, 2000);
  }, []);

  const menuItems = [
    {
      label: getStatusText(StatusEnum.ACTIVE, t),
      value: StatusEnum.ACTIVE,
    },
    {
      label: getStatusText(StatusEnum.FINISHED, t),
      value: StatusEnum.FINISHED,
    },
  ];

  return (
    <>
      <Toolbar>
        <SearchInput
          placeholder={t('common.course_search_placeholder')}
          onChange={(value) => setSearch(value)}
        />
        <DropdownMenuButton
          fieldname="status"
          buttonText={t('assign_table.status')}
          onClearClick={() => setFilter(undefined)}
          isFilterActive={!!filter}
          activeValueTitle={getStatusText(filter, t)}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={item.value}
              sx={{
                justifyContent: 'space-between',
                padding: '11px 12px',
                backgroundColor:
                  filter === item.value
                    ? 'rgba(230, 245, 253, 1)'
                    : 'transparent',
                marginBottom: menuItems.length - 1 === index ? '8px' : 0,
              }}
              onClick={() => setFilter(item.value)}
            >
              {item.label}
              {filter === item.value && (
                <CheckIcon color="rgba(3, 155, 230, 1)" />
              )}
            </MenuItem>
          ))}
        </DropdownMenuButton>
      </Toolbar>

      <ContentWrapper>
        {isLoading ? (
          <SkeletonWrapper />
        ) : filteredCourses.length > 0 ? (
          <Grid>
            {filteredCourses.map((course) => (
              <CourseCard
                key={course.id}
                id={course.id}
                img={course.courseCardImage}
                name={course.fullName}
                inviteStatus="invited"
                endDate={course.endDate}
                endAction={course.endAction}
              />
            ))}
          </Grid>
        ) : (
          <CoursesNotFound withoutDescription={!!search} />
        )}
      </ContentWrapper>
    </>
  );
};

const SkeletonWrapper = () => {
  const skeletonArray = new Array(12).fill('_');

  return (
    <Grid>
      {skeletonArray.map((i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          width={272}
          height={314}
          sx={{ borderRadius: '12px' }}
        />
      ))}
    </Grid>
  );
};
