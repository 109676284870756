import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from '../dateRangePicker';
import { DropdownMenuButton } from '../dropdownMenuButton';

type Props = {
  value: {
    startDate: string;
    endDate: string;
  };
  onChange: (value: { startDate: string; endDate: string }) => void;
  onClearClick: () => void;
};

export const DateRangeDropdownFilter: FC<Props> = ({
  value,
  onChange,
  onClearClick,
}) => {
  const { t } = useTranslation();

  const activeValueTitle = useMemo(() => {
    return value.startDate && value.endDate
      ? `${value.startDate} - ${value.endDate}`
      : '';
  }, [value.endDate, value.startDate]);

  return (
    <DropdownMenuButton
      fieldname="date_assign"
      buttonText={t('progress.filter_assign_date')}
      activeValueTitle={activeValueTitle}
      onClearClick={onClearClick}
      isFilterActive={!!value.startDate && !!value.endDate}
    >
      <DateRangePicker
        filterValue={value}
        onChange={onChange}
        handleClear={onClearClick}
      />
    </DropdownMenuButton>
  );
};
