import { FrontendLogger } from '@dar/api-interfaces';
import { environment } from '@dar/environments/environment';
import { apiClient } from '@dar/services/clients';

const showError = (data: FrontendLogger) => {
  return apiClient
    .post(`${environment.commsBaseApi}/logger`, data)
    .then((res) => res.data);
};
export default showError;
