import { useNavigate } from 'react-router-dom';
import { AclAction, DmsLmsPermissions } from '@dar/api-interfaces';
import { usePermissions } from '@dar/hooks/usePermissions';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BookIcon } from '../../../assets/icons/book.svg';
import { ReactComponent as EmployeeIcon } from '../../../assets/icons/people.svg';
import {
  ContentWrapper,
  IconWrapper,
  LinkWrapper,
} from './AnalyticsPage.style';

export const AnalyticsPage = () => {
  const { t } = useTranslation();
  const { checkPermissions } = usePermissions();
  const navigate = useNavigate();
  return (
    <ContentWrapper>
      {checkPermissions(DmsLmsPermissions.LMS_USER_PROGRESS, AclAction.READ) ? (
        <>
          <Typography variant="textLarge_2">
            {t('analytics.employees')}
          </Typography>
          <LinkWrapper onClick={() => navigate('/lms/analytics/employees')}>
            <IconWrapper bgcolor="#FCF8EB">
              <EmployeeIcon />
            </IconWrapper>
            <Typography variant="textMedium_1" paddingTop="2px">
              {t('analytics.employees_analytics')}
            </Typography>
          </LinkWrapper>
        </>
      ) : null}
      {checkPermissions(DmsLmsPermissions.LMS_COURSE_STATS, AclAction.READ) ? (
        <>
          <Typography variant="textLarge_2">
            {t('analytics.courses')}
          </Typography>
          <LinkWrapper onClick={() => navigate('/lms/analytics/progress')}>
            <IconWrapper bgcolor="#EBF4FC">
              <BookIcon />
            </IconWrapper>
            <Typography variant="textMedium_1" paddingTop="2px">
              {t('analytics.courses_analytics')}
            </Typography>
          </LinkWrapper>
        </>
      ) : null}
    </ContentWrapper>
  );
};
