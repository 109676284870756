import { FC } from 'react';
import { DirectoryEntityDTO, EmployeeFilterForm } from '@dar/api-interfaces';
import { AutoCompleteSelect } from '@dartech/dms-ui';
import { Box, styled } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<EmployeeFilterForm, any>;
  departmentsList: DirectoryEntityDTO[];
  locale: { search: string; apply: string; clear: string };
  loading: boolean;
};

export const DepartmentSelect: FC<Props> = ({
  control,
  departmentsList,
  locale,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Controller
        name="departments"
        control={control}
        render={({ field, fieldState }) => (
          <AutoCompleteSelect
            {...field}
            locale={locale}
            loading={loading}
            error={fieldState?.error?.message}
            label={t('employee_statistics.department')}
            onSelect={(value: DirectoryEntityDTO) => field.onChange(value)}
            popperWidth={412}
            prompt={t('assign_course.prompt')}
            valueId="id"
            valueName="value"
            variant="filter"
            options={departmentsList}
            value={field.value as unknown as DirectoryEntityDTO}
            styleClasses={{ outlinedButton: classes.select }}
          />
        )}
      />
    </Wrapper>
  );
};

const classes = {
  select: 'lms-select',
};

const Wrapper = styled(Box)(({ theme }) => ({
  [`& .${classes.select}`]: {
    height: '48px',
    borderRadius: '12px',
  },
}));
