import { alpha, Box, styled } from '@mui/material';

export const ContentWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 163px)',
  padding: '20px',
  rowGap: '16px',
}));

export const LinkWrapper = styled(Box)(() => ({
  borderRadius: '10px',
  border: `1px solid ${alpha('#262842', 0.12)}`,
  padding: '16px',
  display: 'flex',
  columnGap: '11px',
  cursor: 'pointer',
  width: '50%',
  alignItems: 'center',
}));

export const IconWrapper = styled(Box)(() => ({
  borderRadius: '50%',
  padding: '3px',
}));
