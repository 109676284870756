import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AssignmentModStatus,
  AssignmentSubmissionOrderField,
  ButtonTab,
  GetAssignmentSubmissionsParams,
  GetSubmissionsTableSubmission,
  HomeworkModStatus,
  MoodleOrderKind,
  MoodlePagination,
} from '@dar/api-interfaces';
import { ButtonTabs } from '@dar/components/buttonTabs/ButtonsTabs';
import { SearchInput } from '@dar/components/searchInput';
import { TablePagination } from '@dar/components/tablePagination';
import { getAssignmentSubmissions } from '@dar/services/5q';
import { useQuery } from '@dar/utils/courses';
import {
  CircularProgress,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';
import { ROWS_PER_PAGE_OPTIONS } from 'apps/dar-front/src/constants';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoStudentsIcon } from '../../../../../assets/icons/course-empty.svg';
import { SubmissionTableBody } from './components/submissionTable/SubmissionTableBody';
import { SubmissionTableHeader } from './components/tableHeader/SubmissionTableHeader';
import {
  ContentWrapper,
  EmptyContainer,
  Toolbar,
} from './SubmissionsList.style';

type Props = {
  courseId: number;
};

export const SubmissionsList: FC<Props> = ({ courseId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();

  const [search, setSearch] = useState('');
  const [activeStatus, setActiveStatus] = useState<AssignmentModStatus>(
    AssignmentModStatus.SUBMITTED
  );
  const [sortOrder, setSortOrder] = useState<MoodleOrderKind>(
    MoodleOrderKind.DESC
  );
  const [sortField, setSortField] = useState<AssignmentSubmissionOrderField>(
    AssignmentSubmissionOrderField.DATE
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState<MoodlePagination>({
    per_page: 10,
    page: 1,
    total: 0,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [submissionsList, setSubmissionsList] = useState<
    GetSubmissionsTableSubmission[]
  >([]);

  const handleStatusChange = useCallback(
    (v: AssignmentModStatus) => {
      navigate(
        `/lms/analytics/progress/course/${courseId}/homework?status=${v}`
      );
      setActiveStatus(v);
    },
    [courseId, navigate]
  );

  const pageCount = useMemo(
    () => Math.ceil(paginationData.total / rowsPerPage),
    [paginationData.total, rowsPerPage]
  );
  const currentPage = useMemo(
    () => Number(paginationData.page),
    [paginationData.page]
  );

  const buttonTabs: ButtonTab[] = [
    {
      value: HomeworkModStatus.SUBMITTED,
      text: t('progress.submission_not_checked'),
    },
    {
      value: HomeworkModStatus.CHECKED,
      text: t('progress.submission_checked'),
    },
  ];

  const fetchAssignmentSubmissions = useCallback(
    async (
      body: GetAssignmentSubmissionsParams,
      controller?: AbortController
    ) => {
      setIsFetching(true);
      try {
        const data = await getAssignmentSubmissions(body, controller);
        setPaginationData(data.pagination);
        setSubmissionsList(data.submissions);
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  const requestBodyWithoutPage = useMemo(
    () => ({
      course_id: Number(courseId),
      type: activeStatus,
      sort: {
        field: sortField,
        order: sortOrder,
      },
      search: search,
      groupIds: [],
    }),
    [courseId, activeStatus, sortField, sortOrder, search]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchAssignmentSubmissions(
      {
        ...requestBodyWithoutPage,
        page: 1,
        per_page: 10,
      },
      controller
    );
    return () => {
      controller.abort();
    };
  }, [requestBodyWithoutPage, fetchAssignmentSubmissions]);

  const handleRequestSort = (
    sortField: AssignmentSubmissionOrderField,
    sortOrder: MoodleOrderKind
  ) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const handleChangePage = useCallback(
    async (event: unknown, page: number) => {
      await fetchAssignmentSubmissions({
        ...requestBodyWithoutPage,
        page: page,
        per_page: rowsPerPage,
      });
    },
    [requestBodyWithoutPage, rowsPerPage, fetchAssignmentSubmissions]
  );

  const handleChangeRowsPerPage = useCallback(
    async (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      await fetchAssignmentSubmissions({
        ...requestBodyWithoutPage,
        page: 1,
        per_page: parseInt(event.target.value, 10),
      });
    },
    [requestBodyWithoutPage, fetchAssignmentSubmissions]
  );

  useEffect(() => {
    const statusQuery = query.get('status')?.toLowerCase();
    if (statusQuery) {
      statusQuery === 'checked'
        ? setActiveStatus(AssignmentModStatus.CHECKED)
        : setActiveStatus(AssignmentModStatus.SUBMITTED);
    }
  }, [query]);

  return (
    <div>
      <Toolbar>
        <ButtonTabs
          buttons={buttonTabs}
          activeButton={activeStatus}
          handleClick={handleStatusChange}
        />
        <SearchInput
          onChange={(value) => setSearch(value)}
          placeholder={t('progress.detailtable_search_placeholder')}
        />
      </Toolbar>
      {isFetching ? (
        <EmptyContainer>
          <CircularProgress size={50} />
        </EmptyContainer>
      ) : submissionsList.length > 0 ? (
        <ContentWrapper>
          <TableContainer
            sx={{
              width: '100%',
              borderRadius: '12px',
            }}
          >
            <Table>
              <SubmissionTableHeader
                onRequestSort={handleRequestSort}
                sortOrder={sortOrder}
                sortField={sortField}
              />
              <SubmissionTableBody
                courseId={courseId}
                submissions={submissionsList}
              />
            </Table>
          </TableContainer>
          <TablePagination
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalItems={paginationData.total}
            count={pageCount}
            handlePageChange={handleChangePage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            handleRowPerPageChange={handleChangeRowsPerPage}
          />
        </ContentWrapper>
      ) : (
        <EmptyContainer>
          <NoStudentsIcon />
          <Typography variant="h5" sx={{ color: '#6D6E85', marginTop: '16px' }}>
            {activeStatus === AssignmentModStatus.CHECKED
              ? t('progress.no_checked_submissions')
              : search !== ''
              ? t('progress.no_submitted_submissions_by_search')
              : t('progress.no_submitted_submissions')}
          </Typography>
        </EmptyContainer>
      )}
    </div>
  );
};
