import { FC, useRef, useState } from 'react';
import { TimeUtil } from '@dar/utils/timeUtil';
import {
  Box,
  ClickAwayListener,
  createSvgIcon,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {
  DigitalClock,
  LocalizationProvider,
  TimeField,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { TimePickerWrapper, Popper } from './TimePicker.styles';

type Props = {
  value: Date | null;
  format: string;
  disabled?: boolean;
  onChange: (v: Date | null) => void;
};

export const TimePicker: FC<Props> = ({
  value,
  format,
  disabled,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (newValue: Date | null, reason: string) => {
    handleClose();
    onChange(newValue);
  };

  const v = value || new Date(new Date().setHours(0, 0, 0, 0));

  return (
    <TimePickerWrapper isOpen={open}>
      <LocalizationProvider
        adapterLocale={TimeUtil.getLocale()}
        dateAdapter={AdapterDateFns}
      >
        <Box ref={anchorRef}>
          <TimeField
            value={v}
            format={format}
            disabled={disabled}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled={disabled} onClick={handleOpen}>
                        <ChevronIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            }}
            onChange={onChange}
            onBlur={handleClose}
          />
        </Box>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          style={{ zIndex: 1300 }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <DigitalClock
              value={v}
              onChange={handleChange}
              minutesStep={30}
              ampm={false}
            />
          </ClickAwayListener>
        </Popper>
      </LocalizationProvider>
    </TimePickerWrapper>
  );
};

const ChevronIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.76644 7.74167L9.99977 10.975L13.2331 7.74167C13.5581 7.41667 14.0831 7.41667 14.4081 7.74167C14.7331 8.06667 14.7331 8.59167 14.4081 8.91667L10.5831 12.7417C10.2581 13.0667 9.73311 13.0667 9.40811 12.7417L5.58311 8.91667C5.25811 8.59167 5.25811 8.06667 5.58311 7.74167C5.90811 7.42501 6.44144 7.41667 6.76644 7.74167Z"
      fill="#8B8C9E"
    />
  </svg>,
  'CalendarIcon'
);
