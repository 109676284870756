import {
  styled,
  Box,
  IconButton,
  Typography,
  Button as MuiButton,
} from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '500px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  border: '1px solid rgba(38,40,66, 0.08)',
  borderRadius: '12px',
  overflow: 'hidden',
}));

export const Header = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 24px 12px',
}));

export const Title = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '24px',
}));

export const CloseButton = styled(IconButton)(() => ({
  padding: 0,
  color: '#262842',

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const Body = styled(Box)(() => ({
  padding: '0 24px 24px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#6D6E85',
}));

export const Footer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '0 8px',
  padding: '10px 16px',
  borderTop: '1px solid rgba(38,40,66, 0.08)',
}));

export const Button = styled(MuiButton)(() => ({
  minHeight: '40px',
}));
