import { FC, useEffect, useRef, useState } from 'react';
import {
  Input,
  StyledIconButton,
} from '@dar/components/searchInput/SearchInput.styles';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import { IconButton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

type Props = {
  onChange: (value: string) => void;
  placeholder?: string;
};

export const SearchInput: FC<Props> = ({ onChange, placeholder }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  useDebounce(
    () => {
      onChange(search);
    },
    500,
    [search]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (open) ref.current.querySelector('input').focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  return (
    <Input
      ref={ref}
      isOpen={open}
      value={search}
      onChange={(event) => setSearch(event.target.value)}
      size="medium"
      variant="standard"
      placeholder={placeholder ? placeholder : t('filter.input_placeholder')}
      onBlur={(e) => {
        if (!e.target.value) setOpen(false);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <StyledIconButton
              isOpen={open}
              disabled={open}
              onClick={() => setOpen(true)}
            >
              <Search />
            </StyledIconButton>
          </InputAdornment>
        ),
        endAdornment:
          search.length > 0 ? (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  setSearch('');
                  setOpen(false);
                }}
              >
                <Clear fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
    />
  );
};
