import { CircularProgress, styled } from '@mui/material';

export const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'mainColor' && prop !== 'bgColor',
})<{ mainColor?: string; bgColor?: string }>(
  ({ theme, mainColor, bgColor }) => ({
    '&.MuiCircularProgress-determinate': {
      height: '24px!important',
      width: '24px!important',
    },
    '&.MuiCircularProgress-colorSecondary': {
      '& svg': {
        color: bgColor ? bgColor : theme.palette.main.background3,
      },
    },
    '&.MuiCircularProgress-colorPrimary': {
      '& svg': {
        color: mainColor ? mainColor : theme.palette.main.permanentPrimary,
      },
    },
  })
);
