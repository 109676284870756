import {
  format,
  formatDistanceToNowStrict,
  isToday,
  isYesterday,
} from 'date-fns';
import { kk, ru, enUS, de, id } from 'date-fns/locale';
import { TFunction } from 'react-i18next';

export class TimeUtil {
  static getLocale() {
    const currentLanguage = localStorage.getItem('i18nextLng');
    if (currentLanguage === 'ru') return ru;
    if (currentLanguage === 'en') return enUS;
    if (currentLanguage === 'de') return de;
    return kk;
  }

  static getFormatted(date: Date, formatString?: string, locale?: string) {
    const localStorageLocale = this.getLocale();

    let externalLocale;

    switch (locale) {
      case 'ru':
        externalLocale = ru;
        break;
      case 'de':
        externalLocale = de;
        break;
      case 'kk':
        externalLocale = kk;
        break;
      case 'id':
        externalLocale = id;
        break;
      case 'en':
      default:
        externalLocale = enUS;
        break;
    }

    if (date.getFullYear() === new Date().getFullYear()) {
      return format(date, formatString || 'd MMMM', {
        locale: locale ? externalLocale : localStorageLocale,
      });
    }

    return format(date, formatString || 'd MMMM YYY', {
      locale: locale ? externalLocale : localStorageLocale,
    });
  }

  static getAgoTime(timestamp: number, t: TFunction) {
    const date = new Date(timestamp * 1000);
    if (isToday(date)) {
      return t('common.today');
    }
    if (isYesterday(date)) {
      return t('common.yesterday');
    }

    return formatDistanceToNowStrict(date, {
      locale: this.getLocale(),
      addSuffix: true,
    });
  }
}
