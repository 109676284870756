import {
  DirectoryDefinitionDTO,
  DirectoryEntityDTO,
} from '@dar/api-interfaces';
import { getDirectoriesList, getDirectoryById } from '@dar/services/users';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const useDepartmentPositionDirectory = () => {
  const { i18n } = useTranslation();
  const { data, isFetching } = useQuery<{
    departmentDirectory: DirectoryDefinitionDTO;
    positionDirectory: DirectoryDefinitionDTO;
  }>(['directories', i18n.language], {
    queryFn: async () =>
      await getDirectoriesList().then((res) => {
        const departmentDirectory = res.find(
          (directory) => directory.directoryName === 'Department'
        );
        const positionDirectory = res.find(
          (directory) => directory.directoryName === 'Position'
        );
        return { departmentDirectory, positionDirectory };
      }),
    staleTime: 30000,
    cacheTime: 1200000,
  });

  const { data: positionsList, isFetching: isPositionsFetching } = useQuery<
    DirectoryEntityDTO[]
  >(
    ['positions', data?.positionDirectory?.id, i18n.language],
    async () => {
      return getDirectoryById(data.positionDirectory.id);
    },
    {
      enabled: !!data?.positionDirectory?.id,
      staleTime: 30000,
      cacheTime: 1200000,
    }
  );

  const { data: departmentsList, isFetching: isDepartmentsFetching } = useQuery<
    DirectoryEntityDTO[]
  >(
    ['departments', data?.departmentDirectory?.id, i18n.language],
    async () => {
      return getDirectoryById(data.departmentDirectory.id);
    },
    {
      enabled: !!data?.departmentDirectory?.id,
      staleTime: 30000,
      cacheTime: 1200000,
    }
  );

  return {
    isFetching,
    isDepartmentsFetching,
    isPositionsFetching,
    departmentsList,
    positionsList,
  };
};
