import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AclAction, DmsLmsPermissions, LmsTabTypes } from '@dar/api-interfaces';
import { AssignCourseModal } from '@dar/components/assignCourseModal';
import { usePermissions } from '@dar/hooks/usePermissions';
import { addMatomoEvent, MatomoKeys } from '@dar/utils/metrics';
import { Page, theme } from '@dartech/dms-ui';
import { Button } from '@dartech/dms-ui/dist/v2';
import DownloadIcon from '@dartech/dms-ui/dist/v2/icons/file/Download';
import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROUTER_PREFIX } from '../../constants';
import { useToaster } from '../../contexts/toast.context';
import { ButtonsWrapper, HeaderWrapper } from './AnalyticsLayout.style';

type Props = {
  breadcrumbs: string;
  handleDownload?: () => void;
  isDownloading?: boolean;
};

export const AnalyticsLayout: FC<Props> = ({
  children,
  breadcrumbs,
  handleDownload,
  isDownloading,
}) => {
  const { t } = useTranslation();
  const { checkPermissions } = usePermissions();
  const { showToast } = useToaster();
  const navigate = useNavigate();

  const [openAssignModal, setOpenAssignModal] = useState<'add' | null>(null);

  const hasAccessToAssign = useMemo(() => {
    return (
      checkPermissions(DmsLmsPermissions.LMS_COURSE_ASSIGN, AclAction.WRITE) &&
      checkPermissions(DmsLmsPermissions.LMS_COURSE_STATS, AclAction.READ)
    );
  }, [checkPermissions]);

  const handleToast = ({ isSuccessfull }: { isSuccessfull: boolean }) => {
    showToast(
      isSuccessfull
        ? t('assign_course.toast_success')
        : t('assign_course.toast_error'),
      isSuccessfull ? 'success' : 'error'
    );
  };

  return (
    <>
      <Page.Grid hasBreadcrumbs>
        <Page.HeaderArea>
          <HeaderWrapper>
            <Box display="flex">
              <Link
                component={Typography}
                variant="headlineSmall_1"
                color={theme.palette.main.permanentNeutralVariant}
                onClick={() =>
                  navigate(`/${ROUTER_PREFIX}/${LmsTabTypes.ANALYTICS}`)
                }
              >
                {`${t('tabs.analytics')} >`}&nbsp;
              </Link>
              <Typography variant="headlineSmall_1">{breadcrumbs}</Typography>
            </Box>
            <ButtonsWrapper>
              {handleDownload ? (
                <Button
                  variant="secondary"
                  IconLeft={<DownloadIcon />}
                  size="medium"
                  title={t('filter.download_report')}
                  onClick={handleDownload}
                  loading={isDownloading}
                />
              ) : null}
              {hasAccessToAssign && (
                <Button
                  variant="primary"
                  title={t('main.assign_button')}
                  size="medium"
                  onClick={() => {
                    setOpenAssignModal('add');
                    addMatomoEvent(MatomoKeys.CLICK_BTN_ASSING_TRAINING);
                  }}
                />
              )}
            </ButtonsWrapper>
          </HeaderWrapper>
        </Page.HeaderArea>
        <Page.ContentArea>{children}</Page.ContentArea>
      </Page.Grid>
      {openAssignModal && (
        <AssignCourseModal
          openType={openAssignModal}
          onClose={() => setOpenAssignModal(null)}
          setNotification={handleToast}
        />
      )}
    </>
  );
};
