import { Box, Button, styled } from '@mui/material';

export const EmptyContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 223px)',
  width: 'calc(100vw - 346px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  columnGap: '16px',
  color: theme.palette.secondary['50'],
}));

export const Toolbar = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  borderBottom: `1px solid #26284214`,
  padding: '12px 24px',
  backgroundColor: 'white',
}));

export const ReportButton = styled(Button)(() => ({
  borderRadius: '10px',
  '& .MuiButton-label': {
    color: '#262842',
  },
  height: '32px',
  marginLeft: '18px',
}));

export const ContentWrapper = styled(Box)(() => ({
  width: '100%',
  height: 'calc(100vh - 228px)',
  padding: '16px 14px',
  backgroundColor: '#F7F8FC',
  overflowY: 'auto',
}));
