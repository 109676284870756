import {
  Box,
  styled,
  Button as MuiButton,
  Typography,
  IconButton as MuiIconButton,
} from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 4px;
  color: #fff;
  background-color: rgba(38, 40, 66, 1);
  border-radius: 12px;
`;

export const Button = styled(MuiButton)`
  padding: 6px 12px;
  font-weight: 600;
  color: #fff;
  background-color: rgba(21, 22, 45, 1);
  border: 1px solid rgba(255, 255, 255, 0.12);
`;

export const CounterText = styled(Typography)`
  font-weight: 600;
`;

export const Divider = styled(Box)`
  width: 1px;
  height: 32px;
  margin: 0 8px;
  background-color: rgba(255, 255, 255, 0.12);
`;

export const IconButton = styled(MuiIconButton)`
  margin-left: auto;
  padding: 3px;
  color: #fff;
  background-color: rgba(21, 22, 45, 1);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 8px;
`;
