import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { environment } from '../environments/environment';

const langVersion = 'v1.0';
const supportedLngs = ['en', 'ru', 'kz', 'id', 'de'];
export const namespace = 'lms';
const loadPath = `${environment.dmsBaseApi}/dictionary-api/localization/all?lang={{lng}}&module={{ns}}`;

i18n
  .use(detector)
  .use(ChainedBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: namespace,
    ns: [namespace, 'hcms'],
    backend: {
      backends: [Backend],
      backendOptions: [
        // {
        //   expirationTime: 7 * 24 * 60 * 60 * 1000,
        //   versions: supportedLngs.reduce(
        //     (acc, cur) => ({ ...acc, [cur]: langVersion }),
        //     {}
        //   ),
        // },
        {
          loadPath,
        },
      ],
    },
    supportedLngs,
    fallbackLng: 'en', //if resources not found use "en"

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
