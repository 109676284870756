import { Button, styled } from '@mui/material';

export const PaginationButton = styled(Button)(() => ({
  width: '40px',
  height: '40px',
  padding: 0,
  minWidth: 0,
  marginRight: '8px',
  backgroundColor: '#fff',
  borderRadius: '10px',
}));
