import { TableBody as MuiTableBody, TableRow, styled } from '@mui/material';

export const StyledTableRow = styled(TableRow)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const TableBody = styled(MuiTableBody)(() => ({
  background: '#fff',
}));
