import { TableSortLabel, Typography, styled } from '@mui/material';

export const ModTitle = styled(Typography)(() => ({
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '80px',
  color: '#6D6E85',
  paddingTop: '10px',
}));

export const StyledSortLabel = styled(TableSortLabel)(() => ({
  position: 'relative',
  flexDirection: 'row',
  color: '#6D6E85',
  '& svg': {
    opacity: 1,
    overflow: 'initial',
    '&$active': {
      '&svg': {
        '&path': {
          fill: '#262842 !important',
        },
      },
    },
  },
  '&.MuiTableSortLabel-active > svg > path': {
    fill: '#262842',
  },
}));
