import { FC, useCallback, useMemo, useState } from 'react';
import {
  AssignType,
  EmployeeAnalyticsDto,
  EmployeeSort,
  EmployeeSortFields,
} from '@dar/api-interfaces';
import { DateRangeDropdownFilter } from '@dar/components/dateRangeDropdownFilter';
import { FilledCircularProgress } from '@dar/components/filledCircularProgress/FilledCircularProgress';
import { FilterListDropdown } from '@dar/components/filterListDropdown';
import { OverflowTooltip } from '@dar/components/overflowTooltip/OverflowTooltip';
import { SearchInput } from '@dar/components/searchInput';
import { TablePaginationWOTotal } from '@dar/components/tablePaginationWOTotal/TablePaginationWOTotal';
import { useDepartmentPositionDirectory } from '@dar/hooks/useDepartmentPoistionDirectory';
import { getEmployeesAnalytics } from '@dar/services/5q';
import { theme } from '@dartech/dms-ui';
import {
  Box,
  CircularProgress,
  Link,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DEFAULT_DATE_FORMAT } from 'apps/dar-front/src/constants';
import { useChannelInfo } from 'apps/dar-front/src/contexts/channel.context';
import { format, formatISO, parse, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as DoneIcon } from '../../../../../assets/icons/checkmark.svg';
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter_btn.svg';
import { ReactComponent as NoEmployeeIcon } from '../../../../../assets/icons/no_employee.svg';
import { ReactComponent as SortIcon } from '../../../../../assets/icons/sort_icon_default.svg';
import { EmployeeFilter } from './components/filter/EmployeeFilter';
import {
  EmptyContainer,
  FilterButton,
  HeaderTableCell,
  ProgressIconContainer,
  StyledButtonText,
  StyledCloseIcon,
  StyledSortLabel,
  TableBody,
  TableHeaderRow,
  Toolbar,
  UserAvatar,
} from './EmployeesTable.style';

type Props = {
  onEmployeeClick: (value: EmployeeAnalyticsDto) => void;
};

export const EmployeesTable: FC<Props> = ({ onEmployeeClick }) => {
  const { t } = useTranslation();
  const { channelId } = useChannelInfo();
  const [search, setSearch] = useState('');
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
  });

  const [filterValues, setFilterValues] = useState<{
    departments?: string[];
    positions?: string[];
  }>(null);

  const [openFilter, setOpenFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortValues, setSortValues] = useState<EmployeeSort>({
    field: EmployeeSortFields.NAME,
    sort_criteria: 'asc',
  });
  const [nextPageClickDisabled, setNextPageClickDisabled] = useState(false);
  const [assignType, setAssignType] = useState<AssignType | null>(null);
  const [dateFilterValue, setDateFilterValue] = useState<{
    from: string;
    to: string;
  }>({ from: '', to: '' });

  const {
    isFetching: isDirectoryFetching,
    isPositionsFetching,
    isDepartmentsFetching,
    departmentsList,
    positionsList,
  } = useDepartmentPositionDirectory();

  const directoryLoading =
    isDirectoryFetching || isPositionsFetching || isDepartmentsFetching;
  const { data: employeesList, isFetching } = useQuery(
    [
      'employees',
      paginationData,
      sortValues,
      search,
      filterValues,
      departmentsList,
      positionsList,
      assignType,
      dateFilterValue,
      channelId,
    ],
    {
      enabled:
        Boolean(departmentsList) &&
        Boolean(positionsList) &&
        Boolean(channelId),
      queryFn: async () => {
        return getEmployeesAnalytics({
          page: paginationData.page.toString(),
          size: paginationData.size.toString(),
          sort_by: sortValues,
          search,
          filter_by: {
            channel_id: `${channelId}`,
            ...filterValues,
            ...(assignType ? { assign_type: assignType } : {}),
            ...(dateFilterValue.from && dateFilterValue.to
              ? { date: dateFilterValue }
              : {}),
          },
        }).then<EmployeeAnalyticsDto[]>(
          (res) =>
            res.Employees?.map((e) => {
              const position = e.position
                ? positionsList?.find(
                    (p) => p.localization['en'] === e.position
                  )?.value
                : '';
              const dep = e.department
                ? departmentsList?.find(
                    (p) => p.localization['en'] === e.department
                  )?.value
                : '';
              return {
                ...e,
                position: position || e.position || '',
                department: dep || e.department || '',
              };
            }) || []
        );
      },
      onSuccess(data) {
        if (data === null) {
          return [];
        }
        if (data.length > rowsPerPage) {
          setNextPageClickDisabled(false);
          return data.splice(-1);
        } else {
          setNextPageClickDisabled(true);
          return data;
        }
      },
      onError(err) {
        console.error(err);
      },
    }
  );

  const nextPageClick = () => {
    setPaginationData((prev) => ({ ...prev, page: prev.page + 1 }));
  };
  const prevPageClick = useCallback(() => {
    setPaginationData((prev) => ({ ...prev, page: prev.page - 1 }));
    if (nextPageClickDisabled) {
      setNextPageClickDisabled(false);
    }
  }, [nextPageClickDisabled]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPaginationData({
      page: 0,
      size: parseInt(event.target.value, 10),
    });
  };

  const handleSortClick = useCallback(
    (sortfield: EmployeeSortFields) => {
      if (sortfield === sortValues.field) {
        setSortValues((prev) => ({
          ...prev,
          sort_criteria: sortValues.sort_criteria === 'asc' ? 'desc' : 'asc',
        }));
      } else {
        setSortValues({ field: sortfield, sort_criteria: 'asc' });
      }
    },
    [sortValues]
  );

  const activeFilters = useMemo(() => {
    let numberOfFilters = 0;
    if (filterValues?.departments?.length > 0) {
      numberOfFilters = numberOfFilters + 1;
    }
    if (filterValues?.positions?.length > 0) {
      numberOfFilters = numberOfFilters + 1;
    }

    return numberOfFilters;
  }, [filterValues]);

  const handleResetFilters = (e) => {
    e.stopPropagation();
    setFilterValues({
      departments: null,
      positions: null,
    });
  };

  const assignTypeFilterOptions = [
    { title: t('common.assign_showcase'), value: AssignType.SHOWCASE },
    { title: t('common.assign_manual'), value: AssignType.MANUAL },
  ];

  const handleAssignTypeClick = (value: AssignType) => {
    setAssignType(value);
  };

  const handleAssignTypeClear = () => {
    setAssignType(null);
  };

  const handleDateFilterChange = (value: { from: string; to: string }) => {
    value.from &&
      value.to &&
      setDateFilterValue({
        from: formatISO(parse(value.from, DEFAULT_DATE_FORMAT, new Date())),
        to: formatISO(parse(value.to, DEFAULT_DATE_FORMAT, new Date())),
      });
  };

  const handleDateFilterClear = () => {
    setDateFilterValue({ from: '', to: '' });
  };

  const parsedDateValue = useMemo(() => {
    if (dateFilterValue.from && dateFilterValue.to) {
      return {
        startDate: format(parseISO(dateFilterValue.from), DEFAULT_DATE_FORMAT),
        endDate: format(parseISO(dateFilterValue.to), DEFAULT_DATE_FORMAT),
      };
    } else {
      return { startDate: '', endDate: '' };
    }
  }, [dateFilterValue.from, dateFilterValue.to]);

  return (
    <>
      <Box>
        <Toolbar>
          <Box display="flex" columnGap="8px">
            <SearchInput
              placeholder={t('common.employee_search_placeholder')}
              onChange={(value) => {
                setSearch(value);
              }}
            />
            <FilterListDropdown
              fieldname="assignType"
              options={assignTypeFilterOptions}
              onItemClick={handleAssignTypeClick}
              onClearClick={handleAssignTypeClear}
              activeItem={assignType}
            />
            <DateRangeDropdownFilter
              value={parsedDateValue}
              onChange={({ startDate, endDate }) =>
                handleDateFilterChange({ from: startDate, to: endDate })
              }
              onClearClick={handleDateFilterClear}
            />
            <FilterButton
              variant="outlined"
              onClick={() => setOpenFilter(true)}
            >
              <FilterIcon />
              <StyledButtonText>{t('filter.filter_button')}</StyledButtonText>
              {activeFilters > 0 && (
                <Box display="flex" alignItems="center">
                  <StyledButtonText>{`(${activeFilters})`}</StyledButtonText>
                  <StyledCloseIcon onClick={handleResetFilters} />
                </Box>
              )}
            </FilterButton>
          </Box>
        </Toolbar>
        {isFetching || directoryLoading ? (
          <EmptyContainer>
            <CircularProgress size={50} />
          </EmptyContainer>
        ) : employeesList?.length > 0 ? (
          <div>
            <Box padding="12px 20px" width="100%">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableHeaderRow>
                      <HeaderTableCell>
                        <StyledSortLabel
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.NAME)
                          }
                          IconComponent={SortIcon}
                          active={sortValues.field === EmployeeSortFields.NAME}
                        >
                          {t('employee_statistics.employee_name')}
                        </StyledSortLabel>
                      </HeaderTableCell>
                      <HeaderTableCell>
                        <StyledSortLabel
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.DEPARTMENT)
                          }
                          IconComponent={SortIcon}
                          active={
                            sortValues.field === EmployeeSortFields.DEPARTMENT
                          }
                        >
                          {t('employee_statistics.department')}
                        </StyledSortLabel>
                      </HeaderTableCell>
                      <HeaderTableCell>
                        <StyledSortLabel
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.POSITION)
                          }
                          IconComponent={SortIcon}
                          active={
                            sortValues.field === EmployeeSortFields.POSITION
                          }
                        >
                          {t('employee_statistics.position')}
                        </StyledSortLabel>
                      </HeaderTableCell>
                      <HeaderTableCell>
                        <StyledSortLabel
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.PROGRESS)
                          }
                          IconComponent={SortIcon}
                          active={
                            sortValues.field === EmployeeSortFields.PROGRESS
                          }
                        >
                          <OverflowTooltip maxWidth={80}>
                            {t('employee_statistics.progress')}
                          </OverflowTooltip>
                        </StyledSortLabel>
                      </HeaderTableCell>
                      <HeaderTableCell align="right">
                        <StyledSortLabel
                          IconComponent={SortIcon}
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.ALL_COURSES)
                          }
                          active={
                            sortValues.field === EmployeeSortFields.ALL_COURSES
                          }
                        >
                          <OverflowTooltip maxWidth={80}>
                            {t('employee_statistics.assigned_to_courses')}
                          </OverflowTooltip>
                        </StyledSortLabel>
                      </HeaderTableCell>
                      <HeaderTableCell align="right">
                        <StyledSortLabel
                          IconComponent={SortIcon}
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.IN_PROGRESS)
                          }
                          active={
                            sortValues.field === EmployeeSortFields.IN_PROGRESS
                          }
                        >
                          <OverflowTooltip maxWidth={90}>
                            {t('employee_statistics.in_progress')}
                          </OverflowTooltip>
                        </StyledSortLabel>
                      </HeaderTableCell>
                      <HeaderTableCell align="right">
                        <StyledSortLabel
                          IconComponent={SortIcon}
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.COMPLETED)
                          }
                          active={
                            sortValues.field === EmployeeSortFields.COMPLETED
                          }
                        >
                          <OverflowTooltip maxWidth={80}>
                            {t('employee_statistics.completed')}
                          </OverflowTooltip>
                        </StyledSortLabel>
                      </HeaderTableCell>
                      <HeaderTableCell align="right">
                        <StyledSortLabel
                          IconComponent={SortIcon}
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.NOT_STARTED)
                          }
                          active={
                            sortValues.field === EmployeeSortFields.NOT_STARTED
                          }
                        >
                          <OverflowTooltip maxWidth={70}>
                            {t('employee_statistics.not_started')}
                          </OverflowTooltip>
                        </StyledSortLabel>
                      </HeaderTableCell>
                      <HeaderTableCell align="right">
                        <StyledSortLabel
                          IconComponent={SortIcon}
                          onClick={() =>
                            handleSortClick(EmployeeSortFields.OVERDUE)
                          }
                          active={
                            sortValues.field === EmployeeSortFields.OVERDUE
                          }
                        >
                          <OverflowTooltip maxWidth={80}>
                            {t('employee_statistics.overdue')}
                          </OverflowTooltip>
                        </StyledSortLabel>
                      </HeaderTableCell>
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    {employeesList.map((employee) => (
                      <TableRow key={employee.employeeId} hover>
                        <TableCell width={200}>
                          <Link
                            sx={{
                              color: theme.palette.main.permanentNeutral,
                              cursor: 'pointer',
                            }}
                            onClick={() => onEmployeeClick(employee)}
                          >
                            <Box display="flex" alignItems="center">
                              <UserAvatar
                                alt={employee.employeeFullName}
                                src={employee.photo}
                              />
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="4px"
                              >
                                <OverflowTooltip maxWidth={180}>
                                  {employee.employeeFullName}
                                </OverflowTooltip>
                              </Box>
                            </Box>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <OverflowTooltip maxWidth={80}>
                            {employee.department}
                          </OverflowTooltip>
                        </TableCell>
                        <TableCell>
                          <OverflowTooltip maxWidth={80}>
                            {employee.position}
                          </OverflowTooltip>
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            columnGap="8px"
                            alignItems="center"
                          >
                            {employee?.progress === 100 ? (
                              <ProgressIconContainer>
                                <DoneIcon />
                              </ProgressIconContainer>
                            ) : (
                              <FilledCircularProgress
                                value={
                                  employee.progress ? employee.progress : 0
                                }
                              />
                            )}
                            <Typography variant="body1">
                              {`${
                                employee.progress
                                  ? Math.round(employee.progress)
                                  : 0
                              }%`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1">
                            {employee.all_courses ? employee.all_courses : 0}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1">
                            {employee.in_progress ? employee.in_progress : 0}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1">
                            {employee.course_completed
                              ? employee.course_completed
                              : 0}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1">
                            {employee.course_not_started
                              ? employee.course_not_started
                              : 0}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1">
                            {employee.course_overdue
                              ? employee.course_overdue
                              : 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePaginationWOTotal
                rowsPerPage={rowsPerPage}
                paginationData={paginationData}
                nextPageClickDisabled={nextPageClickDisabled}
                elementsOnPage={employeesList.length}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                nextPageClick={nextPageClick}
                prevPageClick={prevPageClick}
              />
            </Box>
          </div>
        ) : (
          <EmptyContainer>
            <NoEmployeeIcon />
            <Typography
              variant="h5"
              sx={{ color: '#6D6E85', marginTop: '16px' }}
            >
              {t('external_table.no_employee', { ns: 'hcms' })}
            </Typography>
            <Typography variant="body1" sx={{ color: '#6D6E85' }}>
              {t('external_table.try_search', { ns: 'hcms' })}
            </Typography>
          </EmptyContainer>
        )}
      </Box>
      {openFilter && (
        <EmployeeFilter
          open={openFilter}
          filterValues={filterValues}
          onClose={() => setOpenFilter(false)}
          setFilterValues={setFilterValues}
        />
      )}
    </>
  );
};
