import { Box, styled } from '@mui/material';

export const FilterItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '11px 12px',
  backgroundColor: active ? '#E6F5FD' : '#FFF',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F0F0F7',
  },
}));
