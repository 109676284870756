import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { BreadCrumbs, LmsTabTypes } from '@dar/api-interfaces';
import { useTranslation } from 'react-i18next';
import { ROUTER_PREFIX } from '../constants';

export type BreadcrumbContextValues = {
  breadcrumbs: BreadCrumbs[];
  setCrumbName: Dispatch<SetStateAction<string>>;
};

const BreadcrumbContext = createContext<BreadcrumbContextValues>({
  breadcrumbs: [],
  setCrumbName: () => {
    return;
  },
});

export const useBreadcrumbsContext = () => useContext(BreadcrumbContext);

export const BreadcrumbContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [crumbName, setCrumbName] = useState('');

  const breadcrumbs = useMemo(() => {
    if (pathname.match(/\/lms$/)) {
      return [
        {
          text: t('nav.education', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}`,
        },
      ];
    }
    if (pathname.match(/\/lms\/analytics$/)) {
      return [];
    }
    if (pathname.match(/\/lms\/analytics\/progress\/course\/\d*\/[a-z]*/)) {
      return [
        {
          text: t('nav.education', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}`,
        },
        {
          text: t('tabs.analytics', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}/${LmsTabTypes.ANALYTICS}`,
        },
        {
          text: t('tabs.progress', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}/${LmsTabTypes.ANALYTICS}/${LmsTabTypes.PROGRESS}`,
        },
        {
          text: crumbName,
          to: '',
        },
      ];
    }
    if (pathname.match(/\/lms\/my_courses/)) {
      return [
        {
          text: t('nav.education', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}`,
        },
        {
          text: t('tabs.my_courses', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}/${LmsTabTypes.MY_COURSES}`,
        },
      ];
    }
    if (pathname.match(/\/lms\/showcase/)) {
      return [
        {
          text: t('nav.education', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}`,
        },
        {
          text: t('tabs.showcase', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}/${LmsTabTypes.SHOWCASE}`,
        },
      ];
    }

    if (pathname.match(/\/lms\/manage/)) {
      return [
        {
          text: t('nav.education', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}`,
        },
        {
          text: t('tabs.manage', { ns: 'lms' }),
          to: `/${ROUTER_PREFIX}/${LmsTabTypes.MANAGE}`,
        },
      ];
    }
    if (pathname.match(/\/lms\/analytics\/[a-z]*/)) {
      return [];
    }
  }, [crumbName, pathname, t]);

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbs,
        setCrumbName,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};
