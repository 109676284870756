import { FC, SyntheticEvent, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Popover,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClearIcon } from '../../assets/icons/small_close_icon.svg';
import {
  ClearButtonContainer,
  EllipsisTypography,
  FilterButton,
  MenuContainer,
} from './DropdownMenuButton.style';

type Props = {
  fieldname: string;
  buttonText: string;
  onClearClick: () => void;
  isFilterActive: boolean;
  activeValueTitle?: string;
};

export const DropdownMenuButton: FC<Props> = ({
  buttonText,
  activeValueTitle,
  fieldname,
  children,
  isFilterActive,
  onClearClick,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClear = (e: SyntheticEvent) => {
    e.stopPropagation();
    onClearClick();
  };

  const id = open ? fieldname : undefined;

  return (
    <Box>
      <FilterButton
        variant="outlined"
        onClick={handleClick}
        active={isFilterActive}
        pressed={open}
        aria-describedby={id}
      >
        <Typography variant="textMedium_2">{buttonText}</Typography>
        {activeValueTitle ? (
          <EllipsisTypography variant="textMedium_2">
            {':'}
            &nbsp;
            {activeValueTitle}
          </EllipsisTypography>
        ) : null}
        {isFilterActive ? <ClearIcon onClick={handleClear} /> : null}
      </FilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '10px',
            marginTop: '4px',
          },
          '& .MuiModal-backdrop': {
            background: 'transparent !important',
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuContainer>
            {children}
            {isFilterActive ? (
              <ClearButtonContainer>
                <Button variant="outlined" fullWidth onClick={handleClear}>
                  {t('filter.clear')}
                </Button>
              </ClearButtonContainer>
            ) : null}
          </MenuContainer>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
};
