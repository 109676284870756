import { Button, styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const Main = styled('div')(() => ({
  padding: '24px',
  overflowY: 'auto',
  height: 'calc(100vh - 138px)',
}));

export const Footer = styled('div')(() => ({
  borderTop: '1px solid #26284214',
  padding: '12px 24px',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  '& > button': {
    marginLeft: '8px',
  },
}));

export const StyledButton = styled(Button)(() => ({
  padding: '10px 12px',
}));
