import { createContext, useCallback, useContext, useState } from 'react';
import { Toast } from '@dar/components/toast/toast.component';

interface IToastContext {
  showToast: (message: string, type: ToastTypes) => void;
}

const ToastContext = createContext<IToastContext>({
  showToast: () => {
    //
  },
});

type ToastTypes = 'success' | 'error' | 'neutral';

export const ToastContextProvider = ({ children }) => {
  const [toasterState, setToasterState] = useState<{
    open: boolean;
    message: string;
    type: ToastTypes;
  }>({ open: false, message: '', type: 'neutral' });

  const showToast = useCallback(
    (message: string, type: 'success' | 'error' | 'neutral') => {
      setToasterState({
        open: true,
        message,
        type,
      });
    },
    []
  );

  const handleCloseToast = () => {
    setToasterState({ open: false, message: '', type: 'neutral' });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toasterState.open ? (
        <Toast
          open={toasterState.open}
          onClose={handleCloseToast}
          textMessage={toasterState.message}
          type={toasterState.type}
        />
      ) : null}
    </ToastContext.Provider>
  );
};

export const useToaster = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw Error('No toast context');
  }
  return context;
};
