import { Box, styled, Typography } from '@mui/material';

export const Toolbar = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0 16px',
  padding: '12px 32px',
  backgroundColor: '#fff',
}));

export const ContentWrapper = styled(Box)(() => ({
  width: '100%',
  height: 'calc(100% - 64px)',
  maxHeight: 'calc(100vh - 64px - 115px - 49px)',
  padding: '16px 14px',
  backgroundColor: '#F7F8FC',
  overflowY: 'auto',
}));

export const Grid = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
}));

export const ModalWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '500px',
  backgroundColor: '#fff',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}));

export const ModalContent = styled(Box)(() => ({
  padding: '24px',
}));

export const ModalTitleWrap = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0 12px',
  marginBottom: '12px',
  color: '#262842',
}));

export const ModalTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '24px',
}));

export const ModalDescription = styled(Typography)(() => ({
  display: 'inline-block',
  marginRight: '36px',
  fontSize: '16px',
  lineHeight: '20px',
  color: '#6D6E85',
}));

export const ModalFooter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 10px',
  borderTop: '1px solid rgba(38, 40, 66, 0.12)',
}));

export const LoaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80%',
  margin: '0 auto',
  minHeight: '50px',
}));
