import { FC } from 'react';
import { SideModalContainer } from '@dartech/dms-ui';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import { Header, StyledIconButton } from './Filter.style';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const Filter: FC<Props> = ({ open, onClose, children }) => {
  const { t } = useTranslation();
  return (
    <SideModalContainer
      openDrawer={open}
      onClose={onClose}
      width="460px"
      isModal={false}
    >
      <Header>
        <Typography variant="h3" sx={{ fontWeight: 400 }}>
          {t('filter.header')}
        </Typography>
        <StyledIconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </StyledIconButton>
      </Header>
      {children}
    </SideModalContainer>
  );
};
