import { FC } from 'react';
import { ButtonTab } from '@dar/api-interfaces';
import { CounterBadge, StatusButton, Wrapper } from './ButtonTabs.style';

type Props = {
  buttons: ButtonTab[];
  handleClick: (v: string) => void;
  activeButton: string;
};

export const ButtonTabs: FC<Props> = ({
  buttons,
  handleClick,
  activeButton,
}) => {
  return (
    <Wrapper>
      {buttons.map((button, index) => (
        <StatusButton
          key={index}
          variant={activeButton === button.value ? 'contained' : 'text'}
          onClick={() => handleClick(button.value)}
        >
          {button.text}
          {button.badgeContent && (
            <CounterBadge
              max={99}
              isActive={activeButton === button.value}
              badgeContent={button.badgeContent}
              overlap="rectangular"
              color="primary"
            />
          )}
        </StatusButton>
      ))}
    </Wrapper>
  );
};
