import { FC } from 'react';
import { Box } from '@mui/material';
import { SubmissionsList } from './components/submissionsList/SubmissionsList';

type Props = {
  courseId: number;
};

export const SubmissionsPage: FC<Props> = ({ courseId }) => {
  return (
    <Box>
      <SubmissionsList courseId={courseId} />
    </Box>
  );
};
