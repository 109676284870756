export const checkPermissions = (
  token: DmsJwtPayload,
  permission: DmsLmsPermissions,
  action: AclAction
) => {
  return token.permissions[permission]?.includes(action);
};

export enum DmsLmsPermissions {
  LMS_USER_PROGRESS = 'LMS_USER_PROGRESS',
  LMS_COURSE_ASSIGN = 'LMS_COURSE_ASSIGN',
  LMS_COURSE_STATS = 'LMS_COURSE_STATS',
  LMS_COURSE = 'LMS_COURSE',
  LMS_COURSE_PROGRAM = 'LMS_COURSE_PROGRAM',
  LMS_COURSE_STATUS = 'LMS_COURSE_STATUS',
  LMS_COURSE_HOMEWORK = 'LMS_COURSE_HOMEWORK',
}

export enum AclAction {
  DELETE = 'DELETE',
  READ = 'READ',
  WRITE = 'WRITE',
  UPDATE = 'UPDATE',
}

export interface DmsJwtPayload {
  sub: string; // Workspace ID
  role: string;
  griffonId: string;
  employeeId: string;
  holdingId: string; // Holding ID
  permissions: Record<DmsLmsPermissions, AclAction[]>;
}
