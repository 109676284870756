import {
  TableBody as MuiTableBody,
  Chip as MuiChip,
  TableRow,
  styled,
  IconButton,
} from '@mui/material';

export const StyledTableRow = styled(TableRow)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const TableBody = styled(MuiTableBody)(() => ({
  background: '#fff',
}));

export const Chip = styled(MuiChip)(({ theme }) => ({
  display: 'block',
  width: 'auto',
  height: '24px',
  margin: '12px 16px 8px',
  padding: '7px 8px',
  borderRadius: '10px',
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 400,
  border: 0,

  color: '#262842',

  '& .MuiChip-label': {
    padding: '0',
  },
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({
  height: '32px',
  width: '32px',
  padding: '6px',
  '& svg': {
    width: '24px',
    height: '24px',
    '& path': {
      fill: theme.palette.main.permanentNeutral,
    },
  },
}));
