import { Employee, User } from '@dar/api-interfaces';

export const employeesConverter = (employees: Employee[]): User[] => {
  return employees.map((user) => {
    return employeeConverter(user);
  });
};

const employeeConverter = (user: Employee): User => {
  const firstName = user.employeeInfo.name.first || '';
  const lastName = user.employeeInfo.name.last || '';
  return {
    id: user.employeeId,
    displayName: firstName + ' ' + lastName,
    firstName: firstName,
    lastName: lastName,
    jobTitle: user.employeeInfo.position,
    workEmail: user.employeeInfo.email || '',
    phoneMobile: user.employeeInfo.phoneMobile,
    photoUrl: `${user.employeeInfo.photo}` || null,
    role: user.employeeInfo.role,
    status: user.status || user.employeeInfo?.status,
  };
};
