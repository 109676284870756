import { FC, useCallback } from 'react';
import { AssignEndAction, CourseInviteStatus } from '@dar/api-interfaces';
import { useLmsRedirect } from '@dar/hooks/useLmsRedirect';
import { getCoursesByQuickSearch, getCoursesProgress } from '@dar/services/5q';
import { addMatomoEvent, MatomoKeys } from '@dar/utils/metrics';
import { Skeleton, Typography } from '@mui/material';
import { compareAsc, format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as FlagIcon } from '../../assets/icons/icon-flag.svg';
import { ReactComponent as CourseCardImg } from '../../assets/img/course-card-img.svg';
import {
  Button,
  ButtonsWrap,
  Img,
  ImgWrap,
  LessonsModules,
  ModulesLessonsWrap,
  Name,
  ProgressWrap,
  StatusChip,
  StyledProgress,
  Wrapper,
} from './CourseCard.styles';

type Props = {
  id: string;
  img: string;
  name: string;
  inviteStatus: CourseInviteStatus;
  endDate?: string;
  endAction?: AssignEndAction;
  onGetAccess?: () => void;
};

export const CourseCard: FC<Props> = ({
  id,
  img,
  name,
  inviteStatus,
  endDate,
  endAction,
  onGetAccess,
}) => {
  const { t } = useTranslation();

  const { openCourse } = useLmsRedirect();

  const handleCourseOpen = useCallback(() => {
    openCourse(+id);
  }, [openCourse, id]);

  const {
    data: {
      totalSections,
      totalActivities,
      completedActivities,
      progress,
      courseImage,
    },
    isFetching: isProgressLoading,
  } = useQuery(['course_stat', id], {
    queryFn: async () => {
      const response = await getCoursesProgress({ courses_ids: [id] }).catch(
        (e) => {
          console.error(e);
          return null;
        }
      );

      const { courses: coursesByQuickSearch } = await getCoursesByQuickSearch({
        // TODO: убрать когда добавят img в getMyInvitedCourses
        filter_by: { course_ids: [`course_${id}`] },
      }).catch((e) => {
        console.error(e);
        return null;
      });

      const courseImage =
        coursesByQuickSearch?.[0].course_info.customfields['course_card_image']
          .value || '';

      const course =
        response && response.courses.length > 0 ? response.courses[0] : null;
      const progress = course
        ? Math.round(
            (course.completed_activities / course.total_activities) * 100
          )
        : 0;
      return {
        progress: progress || course?.progress || 0,
        completedActivities: course?.completed_activities || 0,
        totalActivities: course?.total_activities || 0,
        totalSections: course?.total_sections || 0,
        courseImage,
      };
    },
    placeholderData: {
      progress: 0,
      completedActivities: 0,
      totalActivities: 0,
      totalSections: 0,
      courseImage: '',
    },
  });

  const isOverdue = endDate && compareAsc(parseISO(endDate), new Date()) === -1;

  return (
    <Wrapper>
      <ImgWrap>
        {img || courseImage ? (
          <Img src={img || courseImage} alt="" />
        ) : (
          <CourseCardImg />
        )}
      </ImgWrap>

      {inviteStatus === 'invited' && endDate && (
        <StatusChip isOverdue={isOverdue}>
          <FlagIcon />
          {t('course_card.status_end')} {format(parseISO(endDate), 'dd.MM.yy')}
        </StatusChip>
      )}

      <Name>{name}</Name>

      {inviteStatus !== 'invited' && ( // TODO нужно добавить условие: когда есть доступ, и студент сам его получил
        <LessonsModules>
          {!isProgressLoading ? (
            `${t('course_card.modules', {
              ns: 'lms',
              count: totalSections,
            })} ${t('course_card.lessons', {
              ns: 'lms',
              count: totalActivities,
            })}`
          ) : (
            <Skeleton
              variant="rectangular"
              width={'100%'}
              sx={{ borderRadius: '8px' }}
            />
          )}
        </LessonsModules>
      )}

      {completedActivities === 0 &&
        progress === 0 &&
        inviteStatus === 'invited' && (
          <ModulesLessonsWrap>
            <Typography variant="textSmall_1">
              {t('course_card.modules_other', { count: totalSections })}{' '}
              {t('course_card.lessons_other', { count: totalActivities })}
            </Typography>
          </ModulesLessonsWrap>
        )}

      {/*{!isProgressLoading &&*/}
      {/*  completedActivities === 0 &&*/}
      {/*  inviteStatus === 'invited' && (*/}
      {/*    <div>*/}
      {/*      <Chip label={t('common.assigned_badge', { ns: 'lms' })} />*/}
      {/*    </div>*/}
      {/*  )}*/}

      <ButtonsWrap>
        {completedActivities > 0 &&
          progress > 0 &&
          inviteStatus === 'invited' && (
            <ProgressWrap>
              <StyledProgress
                sx={{ width: '100%' }}
                value={Math.ceil(progress)}
                isComplete={Math.ceil(progress) === 100}
              />
              <Typography>{Math.ceil(progress)}%</Typography>
            </ProgressWrap>
          )}

        {isOverdue && endAction === AssignEndAction.CLOSE_ACCESS ? (
          <Button
            variant="primary"
            size="large"
            disabled
            title={t('course_card.access_closed', { ns: 'lms' })}
            onClick={() => {
              console.log('access closed');
            }}
          />
        ) : (
          <>
            {inviteStatus === 'not_invited' && (
              <Button
                variant="secondary"
                size="large"
                title={t('common.get_assign_btn', { ns: 'lms' })}
                onClick={() => {
                  addMatomoEvent(MatomoKeys.CLICK_BTN_COURSE_GET_ACCESS, {
                    courseId: id,
                  });
                  onGetAccess();
                }}
              />
            )}

            {completedActivities > 0 && inviteStatus === 'invited' && (
              <Button
                variant="secondary"
                size="large"
                title={t('common.continue_btn', { ns: 'lms' })}
                onClick={() => {
                  addMatomoEvent(MatomoKeys.CLICK_BTN_COURSE_CONTINIUE, {
                    courseId: id,
                  });
                  handleCourseOpen();
                }}
              />
            )}

            {completedActivities === 0 && inviteStatus === 'invited' && (
              <Button
                variant="primary"
                size="large"
                title={t('common.start_course_btn', { ns: 'lms' })}
                onClick={() => {
                  addMatomoEvent(MatomoKeys.CLICK_BTN_COURSE_START, {
                    courseId: id,
                  });
                  handleCourseOpen();
                }}
              />
            )}
          </>
        )}
      </ButtonsWrap>
    </Wrapper>
  );
};
