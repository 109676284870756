import { FC, useEffect, useState } from 'react';
import {
  AssignCourseForm,
  AssignEditInfo,
  AssignEndAction,
  AssignFrom,
  FeedEntityTypes,
} from '@dar/api-interfaces';
import { DateTimePicker } from '@dar/components/assignCourseModal/components/dateTimePicker/DateTimePicker';
import { replaceTime } from '@dar/components/assignCourseModal/utils/replaceTime';
import {
  courseAssign,
  courseAssignUpdate,
  getCoursesByQuickSearch,
} from '@dar/services/5q';
import { getEmployeesList } from '@dar/services/users';
import { addMatomoEvent, MatomoKeys } from '@dar/utils/metrics';
import { employeesConverter } from '@dar/utils/users';
import { Button } from '@dartech/dms-ui/dist/v2/components';
import { Box, CircularProgress } from '@material-ui/core';
import { FormControl, Modal, RadioGroup, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useChannelInfo } from '../../contexts/channel.context';
import {
  Body,
  Footer,
  FormControlLabel,
  FormWrapper,
  Header,
  Label,
  ModalTitle,
  RadioButton,
  DatesWrapper,
  StyledCloseIcon,
  Wrapper,
} from './AssignCourseModal.styles';
import { CourseSelect } from './components/courseSelect/CourseSelect';
import { EmployeeSelect } from './components/employeeSelect/EmployeeSelect';

type Props = {
  openType: 'edit-single' | 'edit-multi' | 'add' | null;
  courseId?: number;
  editInfo?: AssignEditInfo;
  onClose: () => void;
  setNotification: ({ isSuccessfull }: { isSuccessfull: boolean }) => void;
};

export const AssignCourseModal: FC<Props> = ({
  openType,
  courseId,
  editInfo,
  onClose,
  setNotification,
}) => {
  const [datePickerIsOpen, setDatePickerIsOpen] = useState<
    'from' | 'to' | null
  >(null);

  const { control, watch, handleSubmit, reset, setValue } =
    useForm<AssignCourseForm>({
      mode: 'onSubmit',
    });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { channelId } = useChannelInfo();

  useEffect(() => {
    if (courseId && editInfo) {
      reset({
        course_id: `course_${courseId}`,
        employee_ids: editInfo.id,
        startDate: editInfo.startDate
          ? parseISO(editInfo.startDate)
          : undefined,
        startTime: editInfo.startDate
          ? toZonedTime(editInfo.startDate, 'Asia/Almaty')
          : undefined,
        endDate: editInfo.endDate ? parseISO(editInfo.endDate) : undefined,
        endTime: editInfo.endDate
          ? toZonedTime(editInfo.endDate, 'Asia/Almaty')
          : undefined,
        endAction:
          editInfo.endAction === AssignEndAction.CLOSE_ACCESS ||
          editInfo.endAction === AssignEndAction.CONTINUE
            ? editInfo.endAction
            : AssignEndAction.CONTINUE,
      });
    }
  }, [courseId, editInfo, reset]);

  const { data: coursesList, isLoading: coursesLoading } = useQuery({
    queryKey: ['all-courses', channelId, openType],
    queryFn: async () => {
      const { courses } = await getCoursesByQuickSearch({
        filter_by: {
          channel_ids: [`${channelId}`],
          types: [FeedEntityTypes.COURSE],
          course_statuses: ['PUBLISHED', 'ACTIVATED', 'HIDDEN'],
        },
        page_size: 30,
        sort_by: [
          {
            field: 'publication_date',
            sort_criteria: 'desc',
          },
        ],
      });

      return courses;
    },
    enabled: Boolean(channelId) && openType === 'add',
  });

  const { data: employeesList, isLoading: employeesLoading } = useQuery({
    queryKey: ['all-employees', openType],
    queryFn: async () => {
      const employees = await getEmployeesList();

      const e =
        openType === 'add' && !editInfo
          ? employees
          : employees.filter((item) => editInfo.id.includes(item.employeeId));

      const convertedEmployees = employeesConverter(e);

      if (editInfo && openType === 'edit-multi') {
        setValue('employees', convertedEmployees);
      }

      return convertedEmployees;
    },
    enabled: openType === 'edit-multi' || openType === 'add',
  });

  const locale = {
    search: t('assign_course.select_search'),
    apply: t('assign_course.select_apply'),
    clear: t('assign_course.select_clear'),
  };

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const onSubmit = (values: AssignCourseForm) => {
    addMatomoEvent(MatomoKeys.CLICK_SUBMIT_ASSIGN);
    setLoading(true);

    openType === 'add'
      ? courseAssign({
          channelId: `${channelId}`,
          course_ids: values.courses.map((course) => course.id),
          users: values.employees.map((employee) => ({
            griffon_id: employee.id,
            email: employee.workEmail,
          })),
          start_date: values.startDate
            ? replaceTime(values.startDate, values.startTime)
            : undefined,
          end_date: values.endDate
            ? replaceTime(values.endDate, values.endTime)
            : undefined,
          assigned_from: AssignFrom.MANUAL,
          end_action:
            values.endDate && !values.endAction
              ? AssignEndAction.CONTINUE
              : values.endAction,
        })
          .then((res) => {
            setNotification({ isSuccessfull: true });
            onClose();
          })
          .catch((e) => {
            setNotification({ isSuccessfull: false });
            onClose();
          })
          .finally(() => setLoading(false))
      : courseAssignUpdate({
          channel_id: `${channelId}`,
          course_id: values.course_id,
          employee_ids: values.employee_ids,
          start_date: values.startDate
            ? replaceTime(values.startDate, values.startTime)
            : undefined,
          end_date: values.endDate
            ? replaceTime(values.endDate, values.endTime)
            : undefined,
          end_action:
            values.endDate && !values.endAction
              ? AssignEndAction.CONTINUE
              : values.endAction,
        })
          .then((res) => {
            setNotification({ isSuccessfull: true });
            onClose();
          })
          .catch((e) => {
            setNotification({ isSuccessfull: false });
            onClose();
          })
          .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={!!openType}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <Wrapper>
        <Header>
          <ModalTitle variant="headlineSmall_2">
            {openType !== 'add'
              ? t('assign_course.edit_modal_title')
              : t('assign_course.modal_title')}
          </ModalTitle>
          <StyledCloseIcon onClick={onClose} />
        </Header>
        <Body>
          {loading || coursesLoading || employeesLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="426px"
            >
              <CircularProgress size={50} />
            </Box>
          ) : (
            <FormWrapper>
              {coursesList && openType === 'add' && (
                <CourseSelect
                  control={control}
                  coursesList={coursesList}
                  locale={locale}
                  loading={coursesLoading}
                />
              )}
              {employeesList &&
                (openType === 'add' || openType === 'edit-multi') && (
                  <EmployeeSelect
                    control={control}
                    employeesList={employeesList}
                    locale={locale}
                    loading={employeesLoading}
                  />
                )}
              <DatesWrapper>
                <Label
                  sx={{ color: datePickerIsOpen === 'from' ? '#039BE6' : '' }}
                >
                  <Typography variant="textMedium_2">
                    {t('assign_course.start_date')}
                  </Typography>{' '}
                  <Typography variant="textMedium_1">
                    ({t('assign_course.not_required')})
                  </Typography>
                </Label>
                <DateTimePicker
                  control={control}
                  dateName="startDate"
                  timeName="startTime"
                  disabledTime={!startDate}
                  disablePast
                  datePlaceholder={t('common.date_format')}
                  onOpen={() => setDatePickerIsOpen('from')}
                  onClose={() => setDatePickerIsOpen(null)}
                />
              </DatesWrapper>

              <DatesWrapper>
                <Label
                  sx={{ color: datePickerIsOpen === 'to' ? '#039BE6' : '' }}
                >
                  <Typography variant="textMedium_2">
                    {t('assign_course.end_date')}
                  </Typography>{' '}
                  <Typography variant="textMedium_1">
                    ({t('assign_course.not_required')})
                  </Typography>
                </Label>
                <DateTimePicker
                  control={control}
                  dateName="endDate"
                  timeName="endTime"
                  minDate={startDate}
                  disabledTime={!endDate}
                  datePlaceholder={t('common.date_format')}
                  onOpen={() => setDatePickerIsOpen('to')}
                  onClose={() => setDatePickerIsOpen(null)}
                />
              </DatesWrapper>
              <FormControl>
                <Label>{t('assign_course.end_action')}</Label>
                <Controller
                  name="endAction"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      defaultValue={field.value || 'continue'}
                      onChange={field.onChange}
                    >
                      <FormControlLabel
                        value={AssignEndAction.CONTINUE}
                        control={<RadioButton />}
                        label={t('assign_course.end_action_continue')}
                        disabled={!endDate}
                      />
                      <FormControlLabel
                        value="close_access"
                        control={<RadioButton />}
                        label={t('assign_course.end_action_close_access')}
                        disabled={!endDate}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </FormWrapper>
          )}
        </Body>
        <Footer>
          <Button
            variant="secondary"
            size="large"
            title={t('assign_course.cancel_button')}
            onClick={onClose}
          />
          <Button
            variant="primary"
            size="large"
            title={t('assign_course.submit_button')}
            onClick={handleSubmit(onSubmit)}
          ></Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};
