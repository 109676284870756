import { FC } from 'react';
import { Box } from '@mui/material';
import { StyledCircularProgress } from './FilledCirculerProgress.style';

type Props = {
  value: number;
  mainColor?: string;
  bgColor?: string;
};

export const FilledCircularProgress: FC<Props> = ({
  value,
  mainColor,
  bgColor,
}) => {
  return (
    <Box position="relative" display="inline-flex">
      <StyledCircularProgress
        variant="determinate"
        color="primary"
        thickness={6}
        sx={{ position: 'absolute', zIndex: 1 }}
        value={value}
        mainColor={mainColor}
      />
      <StyledCircularProgress
        variant="determinate"
        color="secondary"
        thickness={6}
        value={100}
        bgColor={bgColor}
      />
    </Box>
  );
};
