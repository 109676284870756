import { FC } from 'react';
import { GetSubmissionsTableSubmission } from '@dar/api-interfaces';
import { OverflowTooltip } from '@dar/components/overflowTooltip/OverflowTooltip';
import { useLmsRedirect } from '@dar/hooks/useLmsRedirect';
import { getEmployeesByEmailsOrIds } from '@dar/services/users';
import { Box, TableCell, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import {
  StyledTableBody,
  StyledTableRow,
  UserAvatar,
} from './SubmissionTableBody.style';

type Props = {
  courseId: number;
  submissions: GetSubmissionsTableSubmission[];
};

export const SubmissionTableBody: FC<Props> = ({ courseId, submissions }) => {
  const { openAssignmentSubmission } = useLmsRedirect('mentor');

  const { data } = useQuery(['hcms-employees-submission'], () => {
    const userEmails = submissions.map((submission) => submission.user.email);
    return getEmployeesByEmailsOrIds({ emails: userEmails });
  });

  return (
    <StyledTableBody>
      {submissions.map((submission) => {
        const enrollDateFormatted = format(
          new Date(Number(submission.submission_date) * 1000),
          'dd.MM.yyyy, HH:mm'
        );

        const userInfo = data?.find(
          (user) =>
            user.employeeInfo.email?.toLowerCase() ===
            submission.user.email?.toLowerCase()
        );

        return (
          <StyledTableRow
            key={submission.id}
            hover
            onClick={() =>
              openAssignmentSubmission(
                courseId,
                submission.user.id,
                +submission.mod.id
              )
            }
          >
            <TableCell>
              <Box display="flex" alignItems="center">
                <UserAvatar
                  alt={`${submission.user.name} ${submission.user.surname}`}
                  src={userInfo?.employeeInfo.photo}
                />
                <Typography variant="h6">{`${submission.user.name} ${submission.user.surname}`}</Typography>
              </Box>
            </TableCell>
            <TableCell>
              <Typography variant="body1">{enrollDateFormatted}</Typography>
            </TableCell>
            <TableCell>
              <OverflowTooltip>
                <Typography variant="body1">
                  {submission.section.title}
                </Typography>
              </OverflowTooltip>
            </TableCell>
            <TableCell>
              <OverflowTooltip>
                <Typography variant="body1">{submission.mod.title}</Typography>
              </OverflowTooltip>
            </TableCell>
          </StyledTableRow>
        );
      })}
    </StyledTableBody>
  );
};
