import i18n from '../i18n';

// TODO: remove here and use from utils when everything's tested and works
export function parseError(error: { data: Record<string, string> }): string {
  const key = error?.data?.localizationKey;

  if (key) {
    return translateFromLocalizationKey(key);
  }

  return error?.data?.message ?? JSON.stringify(error);
}

export function translateFromLocalizationKey(key: string): string {
  const firstDotIndex = key.indexOf('.');
  const module = key.slice(0, firstDotIndex);
  const translation = key.slice(firstDotIndex + 1);

  return i18n.t(translation, { ns: module });
}
