import {
  Box,
  Radio,
  styled,
  Typography,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';

export const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 500px;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 12px;
`;

export const DatesWrapper = styled(Box)``;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid rgba(38, 40, 66, 0.12);
`;

export const ModalTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(38, 40, 66, 1);
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  margin: 0;
  cursor: pointer;

  & path {
    fill: #8b8c9e;
  }
`;

export const Body = styled(Box)`
  padding: 16px 20px;
`;

export const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

export const Label = styled(Typography)`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #6d6e85;

  span {
    font-weight: 400;
  }
`;

export const Footer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 0 8px;
  padding: 12px 20px;
  border-top: 1px solid rgba(38, 40, 66, 0.12);
  border-radius: 0 0 12px 12px;
  background-color: #f7f8fc;
  overflow: hidden;
`;

export const RadioButton = styled(Radio)`
  padding: 4px;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin-left: -6px;
  margin-right: 0;
`;
