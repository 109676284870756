import { Avatar, TableBody, TableRow, styled } from '@mui/material';

export const UserAvatar = styled(Avatar)(() => ({
  height: '32px',
  width: '32px',
  margin: '0 8px',
}));

export const StyledTableBody = styled(TableBody)(() => ({
  background: '#fff',
}));

export const StyledTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
}));
