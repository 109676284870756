import { FC, useCallback, useEffect, useState } from 'react';
import {
  AssignType,
  CourseAssignInfo,
  EmployeeAnalyticsDto,
} from '@dar/api-interfaces';
import { useDepartmentPositionDirectory } from '@dar/hooks/useDepartmentPoistionDirectory';
import { getEmployeeAnalyticsById } from '@dar/services/5q';
import { SideModalContainer, theme } from '@dartech/dms-ui';
import EmptySearchIcon from '@dartech/dms-ui/dist/v2/icons/action/DocumentSearch';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { useChannelInfo } from 'apps/dar-front/src/contexts/channel.context';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close_icon.svg';
import { StatsCourseCard } from './components/courseCard/CourseCard';
import {
  CardsContainer,
  CoursesHeaderContainer,
  CoursesStatisticsContainer,
  FieldText,
  FilterButton,
  Header,
  PersonalInfoContainer,
  StatCard,
  StatContainer,
  StyledIconButton,
  TitleText,
  UserPhoto,
} from './EmployeeStatictics.style';

type Props = {
  open: boolean;
  onClose: () => void;
  employee: EmployeeAnalyticsDto;
};

export const EmployeeStatictics: FC<Props> = ({ open, onClose, employee }) => {
  const { t } = useTranslation();
  const { channelId } = useChannelInfo();
  const [filterValue, setFilterValue] = useState<AssignType | null>(null);
  const [employeeInfo, setEmployeeInfo] = useState<EmployeeAnalyticsDto | null>(
    null
  );
  const [assigns, setAssigns] = useState<CourseAssignInfo[] | null>([]);

  const { positionsList } = useDepartmentPositionDirectory();

  const { data: employeeStats, isFetching } = useQuery(
    ['employee-statistic', employee.employeeId, channelId, filterValue],
    async () => {
      return getEmployeeAnalyticsById({
        id: employee.employeeId,
        channel_id: `${channelId}`,
        ...(filterValue ? { assigned_from: filterValue } : {}),
      })
        .then((res) => res)
        .catch((err) => {
          setAssigns([]);
          setEmployeeInfo(employee);
        });
    },
    {
      enabled: Boolean(employee?.employeeId) && Boolean(channelId),
    }
  );

  useEffect(() => {
    if (employeeStats) {
      setAssigns(employeeStats.Assigns);
      setEmployeeInfo(employeeStats.Employee);
      return;
    }
    setAssigns([]);
    setEmployeeInfo(employee);
  }, [employee, employeeStats]);

  const handleFilterClick = useCallback(
    (value: AssignType | null) => {
      if (filterValue === value) {
        setFilterValue(null);
        return;
      }
      setFilterValue(value);
    },
    [filterValue]
  );

  return (
    <SideModalContainer openDrawer={open} onClose={onClose} width="764px">
      {isFetching ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <CircularProgress size={50} />
        </Box>
      ) : employeeInfo ? (
        <>
          <Header>
            <Typography variant="h3" sx={{ fontWeight: 400 }}>
              {t('employee_statistics.header')}
            </Typography>
            <StyledIconButton onClick={onClose} aria-label="close">
              <CloseIcon />
            </StyledIconButton>
          </Header>
          <Box display="flex">
            <PersonalInfoContainer>
              <Box display="flex" marginBottom="50px">
                <UserPhoto
                  alt={employeeInfo.employeeFullName}
                  src={employeeInfo.photo}
                />
                <Box marginLeft="12px">
                  <Typography
                    variant="headlineSmall_2"
                    sx={{ marginBottom: '8px' }}
                  >
                    {employeeInfo.employeeFullName}
                  </Typography>
                  <FieldText variant="textMedium_1">
                    {employeeInfo.position
                      ? positionsList?.find(
                          (p) => p.localization['en'] === employeeInfo.position
                        )?.value || employeeInfo.position
                      : ''}
                  </FieldText>
                  <Box>
                    <Link href={`mailto:${employeeInfo.email}`}>
                      {employeeInfo.email}
                    </Link>
                  </Box>
                </Box>
              </Box>
              <StatContainer>
                <TitleText variant="textXLarge_2">
                  {t('employee_statistics.courses')}:&nbsp;
                  {employeeInfo.all_courses ? employeeInfo.all_courses : 0}
                </TitleText>
                {employeeInfo?.all_courses ? (
                  <Box display="flex" gap="8px" flexWrap="wrap">
                    <StatCard>
                      <Typography variant="textLarge_2">
                        {employeeInfo.in_progress
                          ? employeeInfo.in_progress
                          : 0}
                      </Typography>
                      <FieldText variant="textMedium_1">
                        {t('employee_statistics.in_progress')}
                      </FieldText>
                    </StatCard>
                    <StatCard>
                      <Typography variant="textLarge_2">
                        {employeeInfo.course_completed
                          ? employeeInfo.course_completed
                          : 0}
                      </Typography>
                      <FieldText variant="textMedium_1">
                        {t('employee_statistics.completed')}
                      </FieldText>
                    </StatCard>
                    <StatCard>
                      <Typography variant="textLarge_2">
                        {employeeInfo.course_not_started
                          ? employeeInfo.course_not_started
                          : 0}
                      </Typography>
                      <FieldText variant="textMedium_1">
                        {t('employee_statistics.not_started')}
                      </FieldText>
                    </StatCard>
                    {employeeInfo.course_overdue ? (
                      <StatCard>
                        <Typography variant="textLarge_2">
                          {employeeInfo.course_overdue}
                        </Typography>
                        <FieldText variant="textMedium_1">
                          {t('employee_statistics.overdue')}
                        </FieldText>
                      </StatCard>
                    ) : null}
                  </Box>
                ) : null}
              </StatContainer>
            </PersonalInfoContainer>
            <CoursesStatisticsContainer>
              <CoursesHeaderContainer>
                <Typography variant="textXLarge_2">
                  {t('employee_statistics.courses')}
                </Typography>
              </CoursesHeaderContainer>
              <CardsContainer>
                {assigns.length > 0 || !!filterValue ? (
                  <>
                    <Box display="flex" columnGap="8px">
                      <FilterButton
                        onClick={() => handleFilterClick(AssignType.SHOWCASE)}
                        active={filterValue === AssignType.SHOWCASE}
                      >
                        <Typography variant="textMedium_2">
                          {t('employee_statistics.button_showcase_assign')}
                        </Typography>
                      </FilterButton>
                      <FilterButton
                        onClick={() => handleFilterClick(AssignType.MANUAL)}
                        active={filterValue === AssignType.MANUAL}
                      >
                        <Typography variant="textMedium_2">
                          {t('employee_statistics.button_manual_assign')}
                        </Typography>
                      </FilterButton>
                    </Box>
                    {assigns?.map((course, index) => (
                      <StatsCourseCard key={index} courseInfo={course} />
                    ))}
                  </>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    width="100%"
                    rowGap="16px"
                  >
                    <Box>
                      <EmptySearchIcon
                        width="75px"
                        height="75px"
                        color={theme.palette.main.permanentOnNeutralContainer}
                      />
                    </Box>
                    <Typography variant="textLarge_2">
                      {t('course_not_found.title')}
                    </Typography>
                  </Box>
                )}
              </CardsContainer>
            </CoursesStatisticsContainer>
          </Box>
        </>
      ) : null}
    </SideModalContainer>
  );
};
