import { Box, styled, Typography } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px 0',
  marginTop: '188px',
  color: '#6D6E85',
}));

export const Title = styled(Typography)(() => ({
  margin: '16px 0 4px',
}));
