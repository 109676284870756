import { Button as DmsButton } from '@dartech/dms-ui/dist/v2/components';
import { Progress } from '@mantine/core';
import { styled, Typography, Chip as MuiChip, Box, css } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '272px',
  minHeight: '314px',
  borderRadius: '12px',
  backgroundColor: '#fff',
  overflow: 'hidden',
  border: '1px solid rgba(38, 40, 66, 0.08)',
}));

export const ImgWrap = styled(Box)(() => ({
  width: '100%',
  height: '160px',
}));

export const Img = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

export const Name = styled(Typography)(() => ({
  margin: '8px 0',
  padding: '0 16px',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 600,
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
}));

export const LessonsModules = styled(Typography)(() => ({
  marginTop: '4px',
  padding: '0 16px',
  color: '#6D6E85',
}));

export const ButtonsWrap = styled(Box)(() => ({
  width: '100%',
  marginTop: 'auto',
  padding: '0 16px 16px',
}));

export const Button = styled(DmsButton)(() => ({
  width: '100%',
}));

export const ProgressWrap = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0 12px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 16px;
  padding: 0;
`;

export const StyledProgress = styled(Progress, {
  shouldForwardProp: (prop) => prop !== 'isComplete',
})<{ isComplete: boolean }>`
  width: 100%;

  .mantine-Progress-bar {
    background-color: ${({ theme, isComplete }) =>
      !isComplete
        ? theme.palette.main.permanentPrimary
        : theme.palette.main.permanentSuccess};
  }
`;

export const Chip = styled(MuiChip)(() => ({
  display: 'inline-block',
  width: 'auto',
  height: 'auto',
  margin: '8px 16px 0',
  padding: '4px 8px',
  borderRadius: '10px',
  fontSize: '12px',
  lineHeight: '16px',
  backgroundColor: '#262842',
  color: '#fff',

  '& .MuiChip-label': {
    padding: '0',
  },
}));

export const StatusChip = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCompleted' && prop !== 'isOverdue',
})<{ isCompleted?: boolean; isOverdue?: boolean }>`
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  gap: 0 4px;
  padding: 4px 6px;
  margin: 12px 16px 0;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  background-color: ${({ theme }) =>
    theme.palette.main.permanentPrimaryContainer};
  color: ${({ theme }) => theme.palette.main.permanentPrimaryVariant};

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      background-color: rgba(219, 252, 238, 1);
      color: rgba(11, 68, 43, 1);
    `}

  ${({ theme, isOverdue }) =>
    isOverdue &&
    css`
      background-color: ${theme.palette.main.permanentErrorContainer};
      color: ${theme.palette.main.permanentError};
    `}
`;

export const ModulesLessonsWrap = styled(Box)`
  margin-bottom: 16px;
  padding: 0 16px;
  color: ${({ theme }) => theme.palette.main.permanentNeutralVariant};
`;
