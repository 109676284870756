import { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg';
import { DropdownMenuButton } from '../dropdownMenuButton/DropdownMenuButton';
import { FilterItem } from './FilterListDropdown.styles';

type Props = {
  fieldname: string;
  options: { title: string; value: string }[];
  onItemClick: (value: string) => void;
  onClearClick: () => void;
  activeItem?: string;
};

export const FilterListDropdown: FC<Props> = ({
  fieldname,
  options,
  onItemClick,
  onClearClick,
  activeItem,
}) => {
  const { t } = useTranslation();
  return (
    <DropdownMenuButton
      fieldname={fieldname}
      buttonText={t('progress.filter_assign_type')}
      activeValueTitle={
        activeItem
          ? options.find((item) => item.value === activeItem).title
          : ''
      }
      onClearClick={onClearClick}
      isFilterActive={!!activeItem}
    >
      {options.map((filterItem, index) => (
        <FilterItem
          key={index}
          onClick={() => {
            onItemClick(filterItem.value);
          }}
          active={activeItem === filterItem.value}
        >
          <Typography>{filterItem.title}</Typography>
          {activeItem === filterItem.value ? <CheckmarkIcon /> : null}
        </FilterItem>
      ))}
    </DropdownMenuButton>
  );
};
