import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AclAction,
  CourseTabTypes,
  DmsLmsPermissions,
  Workspace,
} from '@dar/api-interfaces';
import { NavTabs } from '@dar/components/navTabs/NavTabs';
import { usePermissions } from '@dar/hooks/usePermissions';
import { getCoursesByQuickSearch } from '@dar/services/5q';
import { courseAdapter } from '@dar/utils/courses';
import { Page } from '@dartech/dms-ui';
import { Box, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AssignsPage } from '../../app/pages/assignsPage/AssignsPage';
import { CourseDetailPage } from '../../app/pages/courseDetail/CourseDetailPage';
import { SubmissionsPage } from '../../app/pages/submissionsPage/SubmissionsPage';
import { useBreadcrumbsContext } from '../../contexts/breadcrumb.context';
import { HeaderWrapper } from './CourseDetailsLayout.style';

export const CourseDetailsLayout = () => {
  const { t } = useTranslation();
  const { checkPermissions } = usePermissions();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: CourseTabTypes }>();
  const { courseId } = useParams<{ courseId: string }>();
  const { setCrumbName } = useBreadcrumbsContext();
  const handleTabChange = useCallback(
    (v: CourseTabTypes) => {
      navigate(`/lms/analytics/progress/course/${courseId}/${v}`);
    },
    [courseId, navigate]
  );

  const hasAccessToCourses = useMemo(() => {
    return checkPermissions(DmsLmsPermissions.LMS_COURSE_STATS, AclAction.READ);
  }, [checkPermissions]);

  const hasAccessToHomework = useMemo(() => {
    return checkPermissions(
      DmsLmsPermissions.LMS_COURSE_HOMEWORK,
      AclAction.WRITE
    );
  }, [checkPermissions]);

  const tabs = useMemo(() => {
    return [
      {
        label: t('progress.assign_tab'),
        value: CourseTabTypes.ASSIGNS,
        hasAccess: hasAccessToCourses,
      },
      {
        label: t('progress.detailtable_progress'),
        value: CourseTabTypes.DASHBOARD,
        hasAccess: hasAccessToCourses,
      },
      {
        label: t('progress.homework_tab'),
        value: CourseTabTypes.HOMEWORK,
        hasAccess: hasAccessToHomework,
      },
    ];
  }, [hasAccessToCourses, hasAccessToHomework, t]);

  const { data, isFetching } = useQuery(['course-info', courseId], {
    queryFn: async () => {
      try {
        const res = await getCoursesByQuickSearch({
          filter_by: {
            course_ids: [`course_${courseId}`],
          },
        });
        return res.courses.length > 0 ? courseAdapter(res.courses[0]) : null;
      } catch (e) {
        console.error(e);
      }
    },
  });

  useEffect(() => {
    data && setCrumbName(data.fullName);
  }, [data, setCrumbName]);

  useEffect(() => {
    const workspace = JSON.parse(
      localStorage.getItem('workspace')
    ) as Workspace;
    if (data && data?.workspace_id !== workspace.id) {
      navigate(`/lms`);
    }
  }, [data, navigate]);

  return (
    <Page.Grid hasBreadcrumbs>
      <Page.HeaderArea>
        <HeaderWrapper>
          {isFetching ? (
            <Skeleton />
          ) : (
            <Typography variant="h1">{data?.fullName}</Typography>
          )}
        </HeaderWrapper>
        <Box>
          <NavTabs
            activeTab={tab || CourseTabTypes.ASSIGNS}
            tabs={tabs}
            onTabChange={handleTabChange}
          />
        </Box>
      </Page.HeaderArea>
      <Page.ContentArea>
        {tab === CourseTabTypes.ASSIGNS && <AssignsPage courseId={+courseId} />}
        {tab === CourseTabTypes.DASHBOARD && (
          <CourseDetailPage courseId={+courseId} />
        )}
        {tab === CourseTabTypes.HOMEWORK && (
          <SubmissionsPage courseId={+courseId} />
        )}
      </Page.ContentArea>
    </Page.Grid>
  );
};
