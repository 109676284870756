import { Navigate, createBrowserRouter } from 'react-router-dom';
import { ROUTER_PREFIX } from '../constants';
import { BreadcrumbContextProvider } from '../contexts/breadcrumb.context';
import { ToastContextProvider } from '../contexts/toast.context';
import { CourseDetailsLayout } from '../layout/courseDetails/CourseDetailsLayout';
import { LmsLayout } from '../layout/lms/Lms';
import MainLayout from '../layout/main/Main';
import { LmsAuth } from './pages/auth/LmsAuthPage';
import { EmployeesListPage } from './pages/employeesList/EmployeesListPage';
import { ProgressPage } from './pages/progress/ProgressPage';

export const router = createBrowserRouter([
  {
    id: 'root',
    path: `/${ROUTER_PREFIX}`,
    element: (
      <BreadcrumbContextProvider>
        <ToastContextProvider>
          <MainLayout />
        </ToastContextProvider>
      </BreadcrumbContextProvider>
    ),
    children: [
      {
        path: 'auth',
        element: <LmsAuth />,
      },
      {
        path: ':tab?',
        element: <LmsLayout />,
        children: [],
      },
      {
        path: 'analytics/progress',
        element: <ProgressPage />,
      },
      {
        path: 'analytics/employees',
        element: <EmployeesListPage />,
      },
      {
        path: 'analytics/progress/course/:courseId/:tab?',
        element: <CourseDetailsLayout />,
        children: [],
      },
      {
        path: 'analytics/progress/course/:courseId',
        index: true,
        element: <Navigate to="assigns" replace />,
      },
    ],
  },
]);
