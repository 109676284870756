import { useCallback, useEffect, useState } from 'react';
import { environment } from '@dar/environments/environment';
import { authLmsService } from '@dar/services/authLms';
import { getEmployeeItem } from '@dar/services/users';

export const useLmsRedirect = (mode?: 'mentor' | 'student') => {
  const [windowRef, setWindowRef] = useState<Window>();
  const [name, setName] = useState<{ first: string; last: string }>();

  useEffect(() => {
    if (!windowRef || !name) {
      return;
    }
    const callback = (event: { origin: string; data: string }) => {
      if (event.origin === environment.lmsFront && event.data) {
        try {
          const payload = JSON.parse(event.data);
          if (payload.type === 'tokenExchangeReady') {
            const refreshToken = authLmsService.getTokens().refresh_token;
            windowRef?.postMessage(
              JSON.stringify({
                type: 'token',
                token: refreshToken,
                firstName: name.first,
                lastName: name.last,
                workspaceToken:
                  mode === 'mentor'
                    ? localStorage.getItem('workspace_token')
                    : null,
              }),
              environment.lmsFront
            );
            setTimeout(() => {
              setWindowRef(undefined);
            }, 300);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
    window.addEventListener('message', callback, false);
    return () => {
      window.removeEventListener('message', callback);
    };
  }, [windowRef, name, mode]);

  const openCourse = useCallback(
    async (id: number) => {
      const profile = JSON.parse(window.localStorage.getItem('profile'));
      const workspace = JSON.parse(window.localStorage.getItem('workspace'));
      const name = await getEmployeeItem(profile.id, workspace.id);

      const windowRef = window.open(
        `${environment.lmsFront}/lms-redirect/darlean${
          mode === 'mentor' ? '-hr' : ''
        }?courseId=${id}`,
        '_blank'
      );
      setName(name);
      setWindowRef(windowRef);
    },
    [mode]
  );

  const openAssignmentSubmission = useCallback(
    async (courseId: number, studentId: number, modId: number) => {
      const profile = JSON.parse(window.localStorage.getItem('profile'));
      const workspace = JSON.parse(window.localStorage.getItem('workspace'));
      const name = await getEmployeeItem(profile.id, workspace.id);

      const windowRef = window.open(
        `${environment.lmsFront}/lms-redirect/darlean-hr?courseId=${courseId}&studentId=${studentId}&modId=${modId}`,
        '_blank'
      );
      setName(name);
      setWindowRef(windowRef);
    },
    []
  );

  return { openCourse, openAssignmentSubmission };
};
