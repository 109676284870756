import { Link as RouterLink } from 'react-router-dom';
import {
  TableCell as MuiTableCell,
  TableSortLabel,
  styled,
  TableContainer as MuiTableContainer,
  TableBody as MuiTableBody,
  Button,
} from '@mui/material';

export const TableSortCell = styled(TableSortLabel)(() => ({
  '& .MuiTableSortLabel-icon': {
    opacity: 1,
  },
}));

export const HeaderTableCell = styled(MuiTableCell)(() => ({
  background: 'none',
  padding: '7px 16px',
}));

export const TableContainer = styled(MuiTableContainer)(() => ({
  borderRadius: '12px',
}));

export const TableBody = styled(MuiTableBody)(() => ({
  background: '#fff',
}));

export const EmptyContainer = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 223px)',
  width: 'calc(100vw - 346px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  columnGap: '16px',
  color: theme.palette.secondary['50'],
}));

export const Toolbar = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid #26284214`,
  padding: '12px 24px',
  backgroundColor: 'white',
}));

export const TableWrapper = styled('div')(() => ({
  padding: '16px 24px',
}));

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.palette.info.dark};
  text-decoration: none;
`;

export const TableCell = styled(MuiTableCell)`
  &:hover > a {
    color: #007994;
  }
`;

export const ReportButton = styled(Button)(() => ({
  borderRadius: '10px',
  '& .MuiButton-label': {
    color: '#262842',
  },
  height: '40px',
  marginLeft: '18px',
  minWidth: '185px',
}));
