import { FC, useEffect, useState } from 'react';
import { CourseAccessForm } from '@dar/api-interfaces';
import { changeAccess, getCoursesByQuickSearch } from '@dar/services/5q';
import { getWorkspaces } from '@dar/services/users';
import { courseAdapter } from '@dar/utils/courses';
import { MatomoKeys, addMatomoEvent } from '@dar/utils/metrics';
import { theme, toast } from '@dartech/dms-ui';
import { Button, RadioButton } from '@dartech/dms-ui/dist/v2';
import {
  Box,
  CircularProgress,
  Modal,
  RadioGroup,
  Typography,
} from '@mui/material';
import { isAxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as CloseIcon } from '../../assets/icons/dismiss-regular.svg';
import { WorkspaceSelect } from './components/workspaceSelect/WorkspaceSelect';
import {
  Description,
  classes,
  SelectWrapper,
  Wrapper,
  Header,
  CloseButton,
  Body,
  Footer,
  VisibilityCard,
  Radios,
  RadioWrapper,
} from './CourseAccessModal.style';

type Props = {
  courseId: number;
  open: boolean;
  onClose: () => void;
  onSuccess: (visibility: 'open' | 'hidden') => void;
};

export const CourseAccessModal: FC<Props> = ({
  courseId,
  open,
  onClose,
  onSuccess,
}) => {
  const { control, handleSubmit } = useForm<CourseAccessForm>({
    mode: 'onSubmit',
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState<'open' | 'hidden'>();
  const [mode, setMode] = useState<'workspace' | 'holding'>();
  const locale = {
    search: t('assign_course.select_search'),
    apply: t('assign_course.select_apply'),
    clear: t('assign_course.select_clear'),
  };

  const { data: course, isLoading: courseLoading } = useQuery(
    ['course', courseId],
    {
      queryFn: async () => {
        const courses = await getCoursesByQuickSearch({
          filter_by: {
            course_ids: [`course_${courseId}`],
          },
        }).then((r) => r.courses);
        return courseAdapter(courses[0]);
      },
      staleTime: 0,
      cacheTime: 0,
    }
  );
  useEffect(() => {
    if (!course) {
      return;
    }
    setVisibility(course.status === 'HIDDEN' ? 'hidden' : 'open');
    const visible_workspace_ids = course.visible_workspace_ids;
    if (!visible_workspace_ids?.length) {
      setMode('holding');
      return;
    }
    if (visible_workspace_ids[0]?.startsWith('h_')) {
      setMode('holding');
      return;
    }
    setMode('workspace');
  }, [course]);

  const { data: workspaces, isLoading: workspacesLoading } = useQuery(
    ['workspaces'],
    {
      queryFn: async () => {
        return getWorkspaces();
      },
    }
  );

  const onSubmit = async (values: CourseAccessForm) => {
    //
    setLoading(true);
    const workspace = JSON.parse(localStorage.getItem('workspace'));
    const visible_workspace_ids =
      visibility === 'hidden'
        ? `w_${workspace.id}`
        : mode === 'holding'
        ? `h_${workspace.holdingId}`
        : values.workspaces.map((w) => w.id).join(';');
    addMatomoEvent(MatomoKeys.CLICK_ON_SUBMIT_COURSE_PUBLISH, {
      visible_workspace_ids,
    });
    try {
      const res = await changeAccess({
        courseId,
        visible_workspace_ids,
        visibility,
      });
      onSuccess(visibility);
    } catch (e) {
      if (isAxiosError(e)) {
        toast.error(e.message);
      }
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Wrapper>
        <Header>
          <Typography variant="headlineSmall_2">
            {t('course_access.modal_title')}
          </Typography>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Body>
          <Description variant="textMedium_2">
            {t('course_access.panel_title')}
          </Description>
          {loading || courseLoading || !mode ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={50} />
            </Box>
          ) : (
            <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
              <VisibilityCard
                selected={visibility === 'open'}
                onClick={() => setVisibility('open')}
              >
                <Typography variant="textMedium_1">
                  {t('course_access.visibility_open')}
                </Typography>
                <Typography
                  variant="textSmall_1"
                  color={theme.palette.main.permanentOnNeutralVariant}
                >
                  {t('course_access.visibility_open_desc')}
                </Typography>
              </VisibilityCard>
              {visibility === 'open' && (
                <Radios>
                  <RadioGroup
                    value={mode}
                    onChange={(e) => {
                      setMode(e.target.value as any);
                    }}
                  >
                    <RadioWrapper>
                      <RadioButton
                        value="holding"
                        label={t('course_access.access_holding')}
                      />
                    </RadioWrapper>
                    <RadioWrapper>
                      <RadioButton
                        value="workspace"
                        label={t('course_access.access_workspace')}
                      />
                    </RadioWrapper>
                  </RadioGroup>
                  <SelectWrapper>
                    {workspaces && course && (
                      <WorkspaceSelect
                        control={control}
                        workspacesList={workspaces}
                        locale={locale}
                        loading={workspacesLoading}
                        styles={classes}
                        disabled={mode === 'holding'}
                        defaultSelectedWorkspaces={course.visible_workspace_ids}
                      />
                    )}
                  </SelectWrapper>
                </Radios>
              )}

              <VisibilityCard
                selected={visibility === 'hidden'}
                onClick={() => setVisibility('hidden')}
              >
                <Typography variant="textMedium_1">
                  {t('course_access.visibility_hidden')}
                </Typography>
                <Typography
                  variant="textSmall_1"
                  color={theme.palette.main.permanentOnNeutralVariant}
                >
                  {t('course_access.visibility_hidden_desc')}
                </Typography>
              </VisibilityCard>
            </Box>
          )}
        </Body>

        <Footer>
          <Button
            variant="secondary"
            size="large"
            onClick={onClose}
            title={t('course_access.cancel_button')}
          />
          <Button
            loading={loading}
            variant="primary"
            size="large"
            onClick={handleSubmit(onSubmit)}
            title={t('course_access.submit_button')}
          />
        </Footer>
      </Wrapper>
    </Modal>
  );
};
