import { Box, styled } from '@mui/material';

export const DatepickerWrapper = styled(Box)`
  .styledDatePicker .MuiInputBase-root {
    width: 280px;
    height: 40px;
    background-color: #f6f8ff;
    border-radius: 10px;

    &.Mui-focused {
      fieldset {
        border-color: #039be6;
      }
    }

    .MuiButtonBase-root {
      padding: 4px;
    }

    input {
      padding: 0;
      color: #262842;
    }

    fieldset {
      border: 2px solid transparent;
    }
  }
`;
