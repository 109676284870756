import { Box, IconButton, styled } from '@mui/material';

export const Header = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #26284214',
  height: '72px',
  padding: '24px',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  '& svg': {
    '& path': {
      fill: '#262842',
    },
  },
}));
