import { FC } from 'react';
import { Button } from '@dartech/dms-ui/dist/v2/components';
import AddIcon from '@dartech/dms-ui/dist/v2/icons/content/Add';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddCourseIcon } from '../../assets/icons/add-course.svg';
import { ReactComponent as Icon } from '../../assets/icons/course-empty.svg';
import { Wrapper, Title } from './CoursesNotFound.styles';

type Props = {
  withoutDescription?: boolean;
  isManage?: boolean;
  onClick?: () => void;
};

export const CoursesNotFound: FC<Props> = ({
  withoutDescription,
  isManage,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {isManage ? (
        <>
          <AddCourseIcon />
          <Typography sx={{ fontWeight: 500 }}>
            {t('course.create_description')}
          </Typography>
          <Button
            variant="primary"
            size="medium"
            title={t('course.create')}
            onClick={onClick}
            IconLeft={<AddIcon />}
          />
        </>
      ) : (
        <>
          <Icon />
          <Title>{t('course_not_found.title', { ns: 'lms' })}</Title>
          {!withoutDescription && (
            <Typography>
              {t('course_not_found.description', { ns: 'lms' })}
            </Typography>
          )}
        </>
      )}
    </Wrapper>
  );
};
