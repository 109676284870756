import React, { ChangeEvent, FC, useState } from 'react';
import { AssignEditInfo, Assigns, StatusEnum } from '@dar/api-interfaces';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  ClickAwayListener,
  TableBody as MuiTableBody,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/icons/delete-outline.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit-outlined.svg';
import { getStatusText } from '../../../utils';
import {
  Checkbox,
  Dot,
  Menu,
  MenuButton,
  MenuItem,
  Status,
  TableCell,
  TableRow,
  UserAvatar,
} from '../AssignTable.styles';

type Props = {
  items: Assigns[];
  selected: string[];
  onEditClick: (info: AssignEditInfo) => void;
  onDeleteClick: (info: AssignEditInfo) => void;
  onSelectClick: (event: ChangeEvent<HTMLInputElement>, id: string) => void;
};

export const TableBody: FC<Props> = ({
  items,
  selected,
  onDeleteClick,
  onEditClick,
  onSelectClick,
}) => {
  const { t } = useTranslation();
  const [employeeInfo, setEmployeeInfo] = useState<AssignEditInfo>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    info: AssignEditInfo
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setEmployeeInfo(info);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const parseDate = (date?: string) => {
    if (date) {
      const dateObject = parseISO(date);
      return format(dateObject, 'dd.MM.yy');
    }

    return '-';
  };

  const getStatusLabel = (status: StatusEnum) => {
    switch (status) {
      case StatusEnum.COMPLETED:
        return (
          <Status sx={{ backgroundColor: '#DBFCEE' }}>
            <Dot sx={{ backgroundColor: '#2DB77B' }} />
            {getStatusText(status, t)}
          </Status>
        );
      case StatusEnum.NOT_STARTED:
        return (
          <Status sx={{ backgroundColor: '#F0F0F7' }}>
            <Dot sx={{ backgroundColor: '#8B8C9E' }} />
            {getStatusText(status, t)}
          </Status>
        );
      case StatusEnum.OVERDUE:
        return (
          <Status sx={{ backgroundColor: '#FFE2DE' }}>
            <Dot sx={{ backgroundColor: '#D6331F' }} />
            {getStatusText(status, t)}
          </Status>
        );
      case StatusEnum.PROGRESS:
        return (
          <Status sx={{ backgroundColor: '#E6F5FD' }}>
            <Dot sx={{ backgroundColor: '#039BE6' }} />
            {getStatusText(status, t)}
          </Status>
        );
      default:
        return null;
    }
  };

  const isSelected = (id: string) => selected.find((select) => select === id);

  return (
    <>
      <MuiTableBody>
        {items.map((item, index) => (
          <TableRow
            key={item.Employee.employeeId}
            isLastRow={index === items.length - 1}
          >
            <TableCell isFirstCol>
              <Checkbox
                checked={!!isSelected(item.Employee.employeeId)}
                onChange={(e) => {
                  onSelectClick(e, item.Employee.employeeId);
                }}
              />
            </TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                <UserAvatar
                  alt={item.Employee.employeeFullName}
                  src={item.Employee.photo}
                />
                {item.Employee.employeeFullName}
              </Box>
            </TableCell>
            <TableCell>{item.Employee.department}</TableCell>
            <TableCell align="right">{parseDate(item.CreatedAt)}</TableCell>
            <TableCell align="right">{parseDate(item.StartDate)}</TableCell>
            <TableCell align="right">{parseDate(item.EndDate)}</TableCell>
            <TableCell align="right">{parseDate(item.FinishDate)}</TableCell>
            <TableCell align="left">{getStatusLabel(item.Status)}</TableCell>
            <TableCell>
              <Box display="flex" alignItems="center" justifyContent="center">
                <MenuButton
                  onClick={(e) => {
                    handleClick(e, {
                      id: [item.Employee.employeeId],
                      startDate: item.StartDate,
                      endDate: item.EndDate,
                      endAction: item.EndAction,
                    });
                  }}
                >
                  <MoreVertIcon />
                </MenuButton>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </MuiTableBody>

      <Menu
        anchorEl={anchorEl}
        open={open}
        hideBackdrop
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <MenuItem
              onClick={() => {
                handleClose();
                onEditClick(employeeInfo);
              }}
            >
              <EditIcon />
              {t('assign_menu.edit')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                onDeleteClick(employeeInfo);
              }}
            >
              <DeleteIcon />
              {t('assign_menu.delete')}
            </MenuItem>
          </Box>
        </ClickAwayListener>
      </Menu>
    </>
  );
};
