import { FC, useEffect, useState } from 'react';
import { CourseStatuses, CreateCourseRequest } from '@dar/api-interfaces';
import { useLmsRedirect } from '@dar/hooks/useLmsRedirect';
import { createCourse, updateCourse } from '@dar/services/5q';
import { Button } from '@dartech/dms-ui/dist/v2';
import { FormControl, FormLabel, Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as CloseIcon } from '../../assets/icons/dismiss-regular.svg';
import { useChannelInfo } from '../../contexts/channel.context';
import {
  Body,
  CloseButton,
  Footer,
  Header,
  Input,
  Title,
  Wrapper,
} from './createCourseModal.styles';

type Props = {
  isOpen: boolean;
  courseInfo?: { id: number; name: string };
  onClose: () => void;
  onNameEdit?: (id: number, newName: string) => void;
};

type FormProps = {
  courseName: string;
};

export const CreateCourseModal: FC<Props> = ({
  isOpen,
  courseInfo,
  onClose,
  onNameEdit,
}) => {
  const { t } = useTranslation();

  const [isSending, setIsSending] = useState(false);
  const [shortname] = useState(uuidv4());

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<FormProps>({
    mode: 'onSubmit',
    defaultValues: {
      courseName: '',
    },
  });
  const { channelId } = useChannelInfo();
  const { openCourse } = useLmsRedirect('mentor');

  const watchedFields = watch('courseName');

  useEffect(() => {
    if (courseInfo) {
      setValue('courseName', courseInfo.name);
    }
  }, [courseInfo, setValue]);

  const handleClose = () => {
    onClose();
    reset({ courseName: '' });
  };

  const onSubmit = handleSubmit(async ({ courseName }) => {
    if (!courseInfo) {
      const profile = JSON.parse(window.localStorage.getItem('profile'));

      const commonCustomFields = [
        {
          type: 'course_language',
          value: 1,
        },
        {
          type: 'creator_id',
          value: profile.id,
        },
        {
          type: 'channel_id',
          value: channelId,
        },
      ];

      const data: CreateCourseRequest = {
        courses: [
          {
            fullname: courseName,
            shortname,
            lang: 'ru',
            categoryid: 1,
            visible: 1,
            customfields: commonCustomFields,
          },
        ],
      };

      setIsSending(true);

      const response = await createCourse({
        courses: [
          {
            ...data.courses[0],
            customfields: [
              ...(data.courses[0].customfields || []),
              {
                type: 'course_status',
                value: `${CourseStatuses.DRAFT}`,
              },
            ],
          },
        ],
      }).finally(() => {
        setIsSending(false);
      });

      if (response) {
        handleClose();
        openCourse(response.id);
      }
    } else {
      setIsSending(true);

      const response = await updateCourse({
        courses: [
          {
            id: courseInfo.id,
            fullname: courseName,
          },
        ],
      }).finally(() => {
        setIsSending(false);
      });

      if (response) {
        onNameEdit ? onNameEdit(+courseInfo.id, courseName) : handleClose();
        onNameEdit ? onNameEdit(courseInfo.id, courseName) : onClose();
      }
    }
  });

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Wrapper>
        <Header>
          <Title>{t('course_create_modal.title')}</Title>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Body>
          <FormControl fullWidth>
            <FormLabel
              error={Boolean(errors.courseName?.message)}
              sx={{ color: '#6D6E85' }}
            >
              {t('course_create_modal.label')}
            </FormLabel>
            <Input
              {...register('courseName')}
              name="courseName"
              type="text"
              placeholder={t('course_create_modal.placeholder')}
              error={Boolean(errors.courseName?.message)}
            />
          </FormControl>
        </Body>

        <Footer>
          <Button
            variant="secondary"
            size="large"
            title={t('course_create_modal.cancel_btn')}
            onClick={handleClose}
          />

          <Button
            variant="primary"
            size="large"
            disabled={!watchedFields || isSending}
            onClick={onSubmit}
            title={t('course_create_modal.submit_btn')}
            loading={isSending}
          />
        </Footer>
      </Wrapper>
    </Modal>
  );
};
