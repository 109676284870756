import {
  FeedCourseItem,
  GetStudentProgressRequestParams,
  GetStudentProgressesResponse,
  PostQuickSearchCourseRequest,
  GetCoursesProgressRequestParams,
  AssignCourseToSelfRequest,
  AssignResponse,
  GetMyInvitedCoursesRequest,
  OneCourseResponseDto,
  AssignCourseToEmployeesRequest,
  GetEmployeesResponse,
  GetEmployeesRequest,
  GetOneEmployeeResponse,
  StrapiListResponse,
  Channel,
  ChangeAccessRequest,
  CreateCourseRequest,
  UpdateCourseRequest,
  GetAssignmentSubmissionsParams,
  GetAssignmentSubmissionsResponse,
  GetEmployeesProgressRequest,
  GetEmployeesProgressResponse,
  AcceptedLanguages,
  AssignCourseToEmployeesUpdateRequest,
  GetAssignRequest,
  GetAssignResponseDTO,
  GetEmployeesAnalyticsResponse,
  DeleteAssignsRequest,
  AssignType,
  GetOneEmployeeAnalyticsResponse,
  MyAssignedCoursesResponse,
} from '@dar/api-interfaces';
import { environment } from '@dar/environments/environment';
import { AxiosInstance } from 'axios';
import { bffClient, lmsClient } from './clients';

export const getCoursesByQuickSearch = async (
  body?: PostQuickSearchCourseRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const res = await (axiosInstance || lmsClient).post<{
    courses: FeedCourseItem[] | null;
    total: number;
  }>(`${environment.api5qRoot}/core/course/quick-search`, body, {
    signal: controller?.signal,
  });
  return res.data;
};

export const getB2BCoursesByQuickSearch = (
  body?: PostQuickSearchCourseRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return (axiosInstance || lmsClient)
    .post<{ courses: FeedCourseItem[]; total: number }>(
      `${environment.api5qRoot}/core/b2b/course/quick-search`,
      body,
      {
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const getUsersProgressTable = async (
  body: GetStudentProgressRequestParams,
  signal?: AbortSignal,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post<GetStudentProgressesResponse>(
      `${environment.bffRoot}/api/lms/report-progress-list`,
      body,
      {
        signal,
      }
    )
    .then((res) => res.data);
};

export const downloadUsersProgress = async (
  body: GetStudentProgressRequestParams,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post<Blob>(
      `${environment.bffRoot}/api/lms/report-progress-download`,
      body,
      {
        signal: controller?.signal,
        responseType: 'blob',
      }
    )
    .then((res) => res.data);
};

export const getCoursesProgress = async (
  body: GetCoursesProgressRequestParams,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const res = await (axiosInstance || bffClient).post<{
    courses: {
      progress: number;
      completed_activities: number;
      total_activities: number;
      total_sections: number;
    }[];
  }>(`${environment.bffRoot}/api/lms/courses-progress`, body, {
    signal: controller?.signal,
  });

  return res.data;
};

export const getAssignmentSubmissions = async (
  body: GetAssignmentSubmissionsParams,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const res = await (
    axiosInstance || bffClient
  ).post<GetAssignmentSubmissionsResponse>(
    `${environment.bffRoot}/api/lms/assignment-submissions`,
    body,
    {
      signal: controller?.signal,
    }
  );

  return res.data;
};

export const assignCourseToSelf = async (
  body: AssignCourseToSelfRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const res = await (axiosInstance || bffClient).post<AssignResponse>(
    `${environment.bffRoot}/api/lms/course-assign-self`,
    body,
    {
      signal: controller?.signal,
    }
  );

  return res.data;
};

export const getMyInvitedCourses = async (
  data: GetMyInvitedCoursesRequest,
  axiosInstance?: AxiosInstance,
  controller?: AbortController
) => {
  const { data: response } = await (axiosInstance || bffClient).post<{
    assigns: MyAssignedCoursesResponse[];
  }>(`${environment.bffRoot}/api/lms/get-my-courses`, data, {
    signal: controller?.signal,
  });

  return response;
};

export const getCourseById = async (
  id: string,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const res = await (axiosInstance || lmsClient).get<OneCourseResponseDto>(
    `${environment.api5qRoot}/core/course/${id}`,
    {
      signal: controller?.signal,
    }
  );
  return res.data;
};

export const courseAssign = async (
  body: AssignCourseToEmployeesRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post(`${environment.bffRoot}/api/lms/course-assign`, body, {
      signal: controller?.signal,
    })
    .then((res) => res.data);
};

export const courseAssignUpdate = async (
  body: AssignCourseToEmployeesUpdateRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post(`${environment.bffRoot}/api/lms/course-assign-update`, body, {
      signal: controller?.signal,
    })
    .then((res) => res.data);
};

export const getEmployees = async (
  body: Omit<GetEmployeesRequest, 'workspace_id'>,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return await (axiosInstance || bffClient)
    .post<GetEmployeesResponse>(
      `${environment.bffRoot}/api/lms/employees`,
      {
        ...body,
        workspace_id: workspace.id,
      },
      {
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const getEmployeeById = async (
  body: { id: string },
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return await (axiosInstance || bffClient)
    .post<GetOneEmployeeResponse>(
      `${environment.bffRoot}/api/lms/employees/${body.id}`,
      {
        workspace_id: workspace.id,
      },
      {
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const getEmployeesProgress = async (
  body: Omit<GetEmployeesProgressRequest, 'workspace_id'>,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post<GetEmployeesProgressResponse>(
      `${environment.bffRoot}/api/lms/employees-progress`,
      body,
      {
        signal: controller?.signal,
      }
    )
    .then((res) => res.data.content);
};

export const downloadEmployeesProgress = async (
  body: { language: AcceptedLanguages },
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post<Blob>(
      `${environment.bffRoot}/api/lms/export-employees-progress`,
      body,
      {
        signal: controller?.signal,
        responseType: 'blob',
      }
    )
    .then((res) => res.data);
};

export const setNotificationsCounterRead = async () => {
  const res = await bffClient.post<null>(
    `${environment.bffRoot}/api/notifications/set-read`,
    null
  );

  return res.data;
};

export const getChannelByWorkspace = () => {
  return bffClient
    .get<StrapiListResponse<Channel>>(
      `${environment.bffRoot}/api/lms/channel-by-workspace`
    )
    .then((r) => r.data);
};

export const changeAccess = (payload: ChangeAccessRequest) => {
  return bffClient
    .post<StrapiListResponse<Channel>>(
      `${environment.bffRoot}/api/lms/change-access`,
      payload
    )
    .then((r) => r.data);
};

export const createCourse = async (
  data: CreateCourseRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post<
      | {
          id: number;
          shortname: string;
        }
      | undefined
    >(`${environment.bffRoot}/api/lms/create-course`, data, {
      signal: controller?.signal,
    })
    .then((res) => res.data);
};

export const updateCourse = async (
  data: UpdateCourseRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post<{ warnings: any[] }>(
      `${environment.bffRoot}/api/lms/update-course`,
      data,
      {
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const getAssigns = async (
  data: GetAssignRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post<GetAssignResponseDTO>(
      `${environment.bffRoot}/api/lms/assigns`,
      data,
      {
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const getEmployeesAnalytics = async (
  body: Omit<GetEmployeesRequest, 'workspace_id'>,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return await (axiosInstance || bffClient)
    .post<GetEmployeesAnalyticsResponse>(
      `${environment.bffRoot}/api/lms/employees-analytics`,
      {
        ...body,
        workspace_id: workspace.id,
      },
      {
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const getEmployeeAnalyticsById = async (
  body: { id: string; assigned_from?: AssignType },
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  const workspace = JSON.parse(localStorage.getItem('workspace'));
  return await (axiosInstance || bffClient)
    .post<GetOneEmployeeAnalyticsResponse>(
      `${environment.bffRoot}/api/lms/employees-analytics/${body.id}`,
      {
        workspace_id: workspace.id,
        assigned_from: body.assigned_from,
      },
      {
        signal: controller?.signal,
      }
    )
    .then((res) => res.data);
};

export const deleteAssigns = async (
  data: DeleteAssignsRequest,
  controller?: AbortController,
  axiosInstance?: AxiosInstance
) => {
  return await (axiosInstance || bffClient)
    .post<{
      deleted: boolean;
    }>(`${environment.bffRoot}/api/lms/delete-assigns`, data, {
      signal: controller?.signal,
    })
    .then((res) => res.data);
};
