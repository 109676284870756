import { FC } from 'react';
import {
  MoodleOrderKind,
  StudentProgress,
  StudentProgressFieldOrderKind,
} from '@dar/api-interfaces';
import { TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultSortIcon } from '../../../../../../../assets/icons/sort_icon_default.svg';
import { ModTitle, StyledSortLabel } from './TableHeader.style';

export interface ProgressColumn {
  id: keyof StudentProgress;
  label: string;
  hidden?: boolean;
  disableSort?: boolean;
}

const getMoodileOrderFieldName = (field: keyof StudentProgress) => {
  const mapping = {
    fullname: StudentProgressFieldOrderKind.LAST_NAME,
    lastaccess: StudentProgressFieldOrderKind.LAST_ACCESS,
    enrolldate: StudentProgressFieldOrderKind.ENROLL_DATE,
    progress: StudentProgressFieldOrderKind.PROGRESS,
    id: null,
    mods: null,
    email: null,
  };
  return mapping[field];
};

type Props = {
  onRequestSort: (
    property: StudentProgressFieldOrderKind,
    orderdDirection: MoodleOrderKind
  ) => void;
  sortOrder: MoodleOrderKind;
  sortField: StudentProgressFieldOrderKind;
  modNames: string[];
};

export const TableHeader: FC<Props> = ({
  sortField,
  sortOrder,
  onRequestSort,
  modNames,
}) => {
  const { t } = useTranslation();

  const columns: readonly ProgressColumn[] = [
    {
      id: 'fullname',
      label: t('progress.detailtable_name'),
    },
    {
      id: 'enrolldate',
      label: t('progress.detailtable_assigndate'),
    },
    {
      id: 'lastaccess',
      label: t('progress.detailtable_lastseen'),
    },
    {
      id: 'progress',
      label: t('progress.detailtable_progress'),
    },
  ];

  const handleSort = (property: keyof StudentProgress) => {
    const newSortField = getMoodileOrderFieldName(property);
    if (!newSortField) {
      return;
    }
    if (newSortField === sortField) {
      onRequestSort(
        newSortField,
        sortOrder === MoodleOrderKind.DESC
          ? MoodleOrderKind.ASC
          : MoodleOrderKind.DESC
      );
    } else {
      onRequestSort(newSortField, MoodleOrderKind.DESC);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, idx) => {
          const isSortable = !column.disableSort;
          const isSorting =
            isSortable && sortField === getMoodileOrderFieldName(column.id);
          const isFirstColumn = idx === 0;

          return (
            <TableCell
              sx={{
                position: isFirstColumn ? 'sticky' : 'static',
                left: 0,
                zIndex: isFirstColumn ? 100 : 1,
                backgroundColor: '#F7F8FC',
                width: isFirstColumn ? '220px' : '130px',
                color: '#6D6E85',
                flexDirection: 'row',
              }}
              key={column.id}
              sortDirection={isSorting ? sortOrder : false}
            >
              <StyledSortLabel
                onClick={() => (isSortable ? handleSort(column.id) : undefined)}
                IconComponent={DefaultSortIcon}
                active={column.id === sortField}
              >
                {column.label}
              </StyledSortLabel>
            </TableCell>
          );
        })}
        {modNames.map((mod) => (
          <Tooltip key={mod} arrow placement="top" title={mod}>
            <TableCell
              sx={{
                backgroundColor: '#F7F8FC',
              }}
            >
              <ModTitle variant="h6">{mod}</ModTitle>
            </TableCell>
          </Tooltip>
        ))}
      </TableRow>
    </TableHead>
  );
};
