import React, { FC } from 'react';
import { AssignCourseForm } from '@dar/api-interfaces';
import { Datepicker } from '@dar/components/datePicker';
import { TimePicker } from '@dar/components/timePicker';
import { parseISO, format } from 'date-fns';
import { Control, Controller } from 'react-hook-form';
import { Wrapper } from './DateTimePicker.styles';

type Props = {
  dateFormat?: string;
  timeFormatFormat?: string;
  hasError?: boolean;
  datePlaceholder?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabledTime?: boolean;
  minDate?: Date;
  control: Control<AssignCourseForm, any>;
  dateName: 'startDate' | 'endDate';
  timeName: 'startTime' | 'endTime';
  onOpen?: () => void;
  onClose?: () => void;
};

export const DateTimePicker: FC<Props> = ({
  minDate,
  disableFuture,
  disablePast,
  dateFormat = 'dd.MM.yy',
  hasError,
  datePlaceholder,
  control,
  dateName,
  timeFormatFormat = 'HH:mm',
  disabledTime,
  timeName,
  onOpen,
  onClose,
}) => {
  return (
    <Wrapper>
      <Controller
        name={dateName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Datepicker
            format={dateFormat}
            value={value ? value : null}
            minDate={minDate}
            disableFuture={disableFuture}
            disablePast={disablePast}
            placeholder={datePlaceholder}
            hasError={hasError}
            onChange={onChange}
            onOpen={onOpen}
            onClose={onClose}
          />
        )}
      />
      <Controller
        name={timeName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TimePicker
            format={timeFormatFormat}
            disabled={disabledTime}
            onChange={onChange}
            value={value}
          />
        )}
      />
    </Wrapper>
  );
};
