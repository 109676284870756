import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import {
  Wrapper,
  Button,
  CounterText,
  Divider,
  IconButton,
} from './ActionToolbar.styles';

type Props = {
  selectCount: number;
  buttons: ReactNode;
  onSelectAllClick: () => void;
  onClearClick: () => void;
};

export const ActionToolbar: FC<Props> = ({
  selectCount,
  buttons,
  onSelectAllClick,
  onClearClick,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Box display="flex" alignItems="center" gap="0 8px">
        <Button onClick={onSelectAllClick}>
          {t('common.table_actions_select_all')}
        </Button>
        {selectCount > 0 && (
          <CounterText>
            {t('common.table_actions_selected', { count: selectCount })}
          </CounterText>
        )}
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" gap="0 8px">
        {buttons}
      </Box>
      <IconButton onClick={onClearClick}>
        <CloseIcon />
      </IconButton>
    </Wrapper>
  );
};
