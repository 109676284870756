import { FC } from 'react';
import { theme } from '@dartech/dms-ui';
import { Typography } from '@mui/material';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert_icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close_icon.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success_icon.svg';
import { StyledToaster, ToastWrapper } from './toast.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  textMessage: string;
  type: 'success' | 'error' | 'neutral';
};

export const Toast: FC<Props> = ({ open, onClose, textMessage, type }) => {
  return (
    <StyledToaster
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor:
          type === 'success'
            ? theme.palette.success.dark
            : type === 'error'
            ? theme.palette.error.dark
            : theme.palette.info.dark,
      }}
      autoHideDuration={5000}
    >
      <ToastWrapper>
        {type === 'success' ? <SuccessIcon /> : <AlertIcon />}
        <Typography sx={{ color: 'white' }}>{textMessage}</Typography>
        <CloseIcon onClick={onClose} />
      </ToastWrapper>
    </StyledToaster>
  );
};
