import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { counter$ } from '@dar-dms/topbar';
import { AclAction, DmsLmsPermissions, LmsTabTypes } from '@dar/api-interfaces';
import { AssignCourseModal } from '@dar/components/assignCourseModal';
import { CreateCourseModal } from '@dar/components/createCourseModal/createCourseModal';
import { NavTabs } from '@dar/components/navTabs/NavTabs';
import { usePermissions } from '@dar/hooks/usePermissions';
import { MatomoKeys, addMatomoEvent } from '@dar/utils/metrics';
import { Page } from '@dartech/dms-ui';
import { Button } from '@dartech/dms-ui/dist/v2/components';
import AddIcon from '@dartech/dms-ui/dist/v2/icons/content/Add';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { distinctUntilChanged } from 'rxjs/operators';
import { AnalyticsPage } from '../../app/pages/analytics/AnalyticsPage';
import { ManagePage } from '../../app/pages/manage';
import { MyCoursesPage } from '../../app/pages/myCourses';
import { ShowcasePage } from '../../app/pages/showcase';
import { useChannelInfo } from '../../contexts/channel.context';
import { useToaster } from '../../contexts/toast.context';
import { ButtonsWrapper, HeaderWrapper } from './style';

export const LmsLayout = () => {
  const { t } = useTranslation();
  const { checkPermissions, permissonsLoaded } = usePermissions();
  const { tab } = useParams<{ tab: LmsTabTypes }>();
  const navigate = useNavigate();
  const [openAssignModal, setOpenAssignModal] = useState<'add' | null>(null);
  const [tabCounters, setTabCounters] = useState<
    Partial<Record<LmsTabTypes, number>>
  >({});
  const { showToast } = useToaster();
  const { channelId, isChannelLoading } = useChannelInfo();
  const [createCourseModal, setCreateCourseModal] = useState(false);

  useEffect(() => {
    const subscription = counter$
      .pipe(distinctUntilChanged())
      .subscribe((v) => {
        setTabCounters({
          [LmsTabTypes.MY_COURSES]: v?.newCourseAssigned || 0,
        });
      });
    return () => {
      subscription?.unsubscribe?.();
    };
  }, []);

  const hasAccessToAssign = useMemo(() => {
    return (
      Boolean(channelId) &&
      checkPermissions(DmsLmsPermissions.LMS_COURSE_ASSIGN, AclAction.WRITE)
    );
  }, [checkPermissions, channelId]);

  const hasAccessToAnalytics = useMemo(() => {
    return (
      (Boolean(channelId) &&
        checkPermissions(DmsLmsPermissions.LMS_COURSE_STATS, AclAction.READ)) ||
      checkPermissions(DmsLmsPermissions.LMS_USER_PROGRESS, AclAction.READ)
    );
  }, [checkPermissions, channelId]);

  const hasAccessToCourseCreate = useMemo(() => {
    return (
      Boolean(channelId) &&
      checkPermissions(DmsLmsPermissions.LMS_COURSE, AclAction.WRITE)
    );
  }, [checkPermissions, channelId]);

  const tabs = useMemo(() => {
    return [
      {
        label: t('tabs.analytics'),
        value: LmsTabTypes.ANALYTICS,
        matomo: MatomoKeys.CLICK_TAB_EMPLOYEE_LIST,
        hasAccess:
          Boolean(channelId) &&
          (checkPermissions(
            DmsLmsPermissions.LMS_USER_PROGRESS,
            AclAction.READ
          ) ||
            checkPermissions(
              DmsLmsPermissions.LMS_COURSE_STATS,
              AclAction.READ
            )),
      },
      {
        label: t('tabs.manage'),
        value: LmsTabTypes.MANAGE,
        matomo: MatomoKeys.CLICK_TAB_PROGRESS,
        hasAccess:
          Boolean(channelId) &&
          (checkPermissions(
            DmsLmsPermissions.LMS_COURSE_PROGRAM,
            AclAction.WRITE
          ) ||
            checkPermissions(DmsLmsPermissions.LMS_COURSE, AclAction.WRITE) ||
            checkPermissions(
              DmsLmsPermissions.LMS_COURSE_STATUS,
              AclAction.WRITE
            )),
      },
      {
        label: t('tabs.my_courses'),
        value: LmsTabTypes.MY_COURSES,
        matomo: MatomoKeys.CLICK_TAB_MY_COURSES,
        hasAccess: true,
        badgeContent: tabCounters.my_courses || 0,
      },
      {
        label: t('tabs.showcase'),
        value: LmsTabTypes.SHOWCASE,
        matomo: MatomoKeys.CLICK_TAB_ALL_COURSES,
        hasAccess: true,
      },
    ];
  }, [checkPermissions, t, tabCounters, channelId]);

  const handleTabChange = useCallback(
    (v: LmsTabTypes) => {
      navigate(`/lms/${v}`);
      const matomoTab = tabs.find((t) => t.value === v && t.matomo);
      if (matomoTab) {
        addMatomoEvent(matomoTab.matomo);
      }
    },
    [navigate, tabs]
  );

  useEffect(() => {
    if (!permissonsLoaded || isChannelLoading) {
      return;
    }

    if (!tab) {
      handleTabChange(
        hasAccessToAnalytics && Boolean(channelId)
          ? LmsTabTypes.ANALYTICS
          : LmsTabTypes.MY_COURSES
      );

      return;
    }
    const selectedTab = tabs.find((t) => t.value === tab);

    if (!selectedTab.hasAccess) {
      navigate(`/lms`);
    }
  }, [
    channelId,
    tabs,
    tab,
    permissonsLoaded,
    isChannelLoading,
    hasAccessToAnalytics,
    handleTabChange,
    navigate,
  ]);

  const handleToast = ({ isSuccessfull }: { isSuccessfull: boolean }) => {
    showToast(
      isSuccessfull
        ? t('assign_course.toast_success')
        : t('assign_course.toast_error'),
      isSuccessfull ? 'success' : 'error'
    );
  };

  return (
    <>
      <Page.Grid hasBreadcrumbs>
        <Page.HeaderArea>
          <HeaderWrapper>
            <Typography variant="h1">{t('main.header')}</Typography>
            <ButtonsWrapper>
              {hasAccessToCourseCreate && (
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => {
                    setCreateCourseModal(true);
                    addMatomoEvent(MatomoKeys.CLICK_BTN_CREATE_COURSE);
                  }}
                  title={t('course.create')}
                  IconLeft={<AddIcon />}
                />
              )}
              {hasAccessToAssign && (
                <Button
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    setOpenAssignModal('add');
                    addMatomoEvent(MatomoKeys.CLICK_BTN_ASSING_TRAINING);
                  }}
                  title={t('main.assign_button')}
                />
              )}
            </ButtonsWrapper>
          </HeaderWrapper>
          <Box>
            {permissonsLoaded && !isChannelLoading && (
              <NavTabs
                activeTab={tab || LmsTabTypes.MY_COURSES}
                tabs={tabs}
                onTabChange={handleTabChange}
              />
            )}
          </Box>
        </Page.HeaderArea>
        <Page.ContentArea>
          {tab === LmsTabTypes.ANALYTICS && <AnalyticsPage />}
          {tab === LmsTabTypes.SHOWCASE && <ShowcasePage />}
          {tab === LmsTabTypes.MY_COURSES && <MyCoursesPage />}
          {tab === LmsTabTypes.MANAGE && <ManagePage />}
        </Page.ContentArea>
      </Page.Grid>

      {openAssignModal && (
        <AssignCourseModal
          openType={openAssignModal}
          onClose={() => setOpenAssignModal(null)}
          setNotification={handleToast}
        />
      )}

      <CreateCourseModal
        isOpen={createCourseModal}
        onClose={() => setCreateCourseModal(false)}
      />
    </>
  );
};
