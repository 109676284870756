import { FC } from 'react';
import {
  AssignmentSubmissionOrderField,
  MoodleOrderKind,
} from '@dar/api-interfaces';
import { TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultSortIcon } from '../../../../../../../assets/icons/sort_icon_default.svg';
import {
  StyledSortLabel,
  StyledTableCell,
} from './SubmissionTableHeader.style';

type Props = {
  onRequestSort: (
    property: AssignmentSubmissionOrderField,
    orderdDirection: MoodleOrderKind
  ) => void;
  sortOrder: MoodleOrderKind;
  sortField: AssignmentSubmissionOrderField;
};

export interface SubmissionColumn {
  id: AssignmentSubmissionOrderField;
  label: string;
  disableSort?: boolean;
}

export const SubmissionTableHeader: FC<Props> = ({
  sortField,
  sortOrder,
  onRequestSort,
}) => {
  const { t } = useTranslation();

  const columns: SubmissionColumn[] = [
    {
      id: AssignmentSubmissionOrderField.NAME,
      label: t('progress.detailtable_name'),
    },
    {
      id: AssignmentSubmissionOrderField.DATE,
      label: t('progress.submission_date'),
    },
    {
      id: AssignmentSubmissionOrderField.SECTION_TITLE,
      label: t('progress.detailtable_section'),
    },
    {
      id: AssignmentSubmissionOrderField.MOD_TITLE,
      label: t('progress.submission_title'),
      disableSort: true,
    },
  ];

  const handleSort = (property: AssignmentSubmissionOrderField) => {
    if (property === sortField) {
      onRequestSort(
        property,
        sortOrder === MoodleOrderKind.DESC
          ? MoodleOrderKind.ASC
          : MoodleOrderKind.DESC
      );
    } else {
      onRequestSort(property, MoodleOrderKind.DESC);
    }
  };

  return (
    <TableHead sx={{ background: '#F7F8FC' }}>
      <TableRow>
        {columns.map((column, idx) => {
          const isSortable = !column.disableSort;
          const isSorting = isSortable && sortField === column.id;

          return (
            <StyledTableCell
              key={column.id}
              sortDirection={isSorting ? sortOrder : false}
            >
              <StyledSortLabel
                onClick={() => (isSortable ? handleSort(column.id) : undefined)}
                active={column.id === sortField}
                IconComponent={DefaultSortIcon}
                hideSortIcon={!isSortable}
                disabled={!isSortable}
              >
                {column.label}
              </StyledSortLabel>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
