export interface StrapiListResponse<T> {
  data: T[];
  meta: {
    pagination: {
      page: number;
      pageCount: number;
      pageSize: number;
      total: number;
    };
  };
}

export interface StrapiResponse<T> {
  data: T;
}

export enum ImageFormatsTypes {
  xlarge = 'xlarge',
  large = 'large',
  medium = 'medium',
  small = 'small',
  xsmall = 'xsmall',
  thumbnail = 'thumbnail',
}

export interface CmsFile {
  id?: number;
  name: string;
  hash: string;
  ext: string;
  mime: string;
  provider: string;
  size: number;
  url: string;
  createdAt: string;
  updatedAt: string;
}

export interface ImageFormats {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
}

export interface Image extends CmsFile {
  alternativeText: string | null;
  caption: string | null;
  width: number;
  height: number;
  formats: Partial<Record<ImageFormatsTypes, ImageFormats>>;
  previewUrl: string | null;
  provider_metadata: unknown;
}

export interface ImageContent {
  id: number;
  attributes: Image;
}

export interface Audio extends CmsFile {
  alternativeText: null;
  caption: null;
  formats: null;
  height: null;
  previewUrl: null;
  provider_metadata: null;
  width: null;
}

export interface AudioContent {
  id: number;
  attributes: Audio;
}

export interface FolderParams {
  filters: {
    channel: string;
  };
  pagination?: {
    page: number;
    pageSize: number;
    withCount: boolean;
  };
  sort: string[];
}

export interface Channel {
  id: number;
  attributes: {
    name: string;
    description: string;
    ownerId: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    locale: string;
    logo: { data: ImageContent };
    cover: { data: ImageContent };
    link_facebook: string | null;
    link_instagram: string | null;
    link_telegram: string | null;
    link_twitter: string | null;
    link_vk: string | null;
    link_website: string | null;
    link_youtube: string | null;
    phone: string | null;
  };
}
