import { useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledPagination } from './TablePagination.style';

interface Props {
  rowsPerPageOptions: number[];
  count: number;
  rowsPerPage: number;
  currentPage: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  handleRowPerPageChange: (event: SelectChangeEvent<number>) => void;
  totalItems: number;
}

export const TablePagination = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  currentPage,
  totalItems,
  handlePageChange,
  handleRowPerPageChange,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const currentPageResultStart = useMemo(() => {
    return currentPage === 1 ? 1 : (currentPage - 1) * rowsPerPage + 1;
  }, [currentPage, rowsPerPage]);

  const currentPageResultsEnd = useMemo(() => {
    return currentPage * rowsPerPage >= totalItems
      ? totalItems
      : currentPage * rowsPerPage;
  }, [currentPage, rowsPerPage, totalItems]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      paddingTop="16px"
      paddingBottom="12px"
    >
      <Typography sx={{ marginRight: '24px', color: '#6D6E85' }}>
        {t('progress.table_pagination_results', {
          ns: 'lms',
          start: currentPageResultStart,
          end: currentPageResultsEnd,
          total: totalItems,
        })}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography sx={{ color: '#6D6E85', marginRight: '8px' }}>
          {t('progress.table_pagination_show')}
        </Typography>
        <Select
          onChange={handleRowPerPageChange}
          value={rowsPerPage || 10}
          sx={{ marginRight: '16px', borderRadius: '10px' }}
          IconComponent={ExpandMoreIcon}
        >
          {rowsPerPageOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
        {count > 1 && (
          <StyledPagination
            color="secondary"
            size="large"
            count={count}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        )}
      </Box>
    </Box>
  );
};
export default TablePagination;
