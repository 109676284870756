import { FC } from 'react';
import { Box } from '@mui/material';
import { CoursesProgressReportTable } from './components/courseProgressTable/CourseProgressTable';

type Props = {
  courseId: number;
};

export const CourseDetailPage: FC<Props> = ({ courseId }) => {
  return (
    <Box display="flex">
      <CoursesProgressReportTable courseId={courseId} />
    </Box>
  );
};
