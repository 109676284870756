import { IconButton, styled, TextField } from '@mui/material';

export const Input = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? '280px' : '32px')};
  border: none;
  transition: all .3s;

  & .MuiInput-root {
    height: 32px;
    padding: ${({ isOpen }) => (isOpen ? '0 8px' : '0 4px')};
    border-radius: 8px;
    border: ${({ theme, isOpen }) =>
      isOpen
        ? `2px solid ${theme.palette.primary.main}`
        : '1px solid rgba(38, 40, 66, 0.12)'};
    transition: all .3s;

    &:before {
      display: none
    },

    &:after {
      display: none
    },
  }

,`;

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>`
  padding: 0;
  color: ${({ theme, isOpen }) =>
    isOpen ? '#8B8C9E' : theme.palette.info.dark};
`;
