import { alpha, Box, Button, styled, Typography } from '@mui/material';

export const FilterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'pressed',
})<{ active?: boolean; pressed?: boolean }>(({ theme, active, pressed }) => ({
  padding: '7px 12px',
  height: '32px',
  backgroundColor:
    active && !pressed
      ? theme.palette.main.permanentPrimaryContainer
      : active && pressed
      ? `color-mix(in srgb, ${theme.palette.main.permanentNeutral} 8%, ${theme.palette.main.permanentPrimaryContainer})`
      : theme.palette.main.permanentNeutralContainer,
  '& span': {
    color: active ? theme.palette.main.permanentPrimaryVariant : 'inherit',
  },
  border: 'none',
  '&:hover': {
    border: 'none',
    backgroundColor: active
      ? theme.palette.main.permanentPrimaryContainer
      : theme.palette.main.permanentNeutralContainer,
  },
  '& path': {
    fill: theme.palette.main.permanentPrimaryVariant,
  },
}));

export const MenuContainer = styled(Box)(() => ({
  width: '300px',
  marginTop: '8px',
  borderRadius: '10px',
}));

export const ClearButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '8px',
  backgroundColor: theme.palette.main.background2,
  borderTop: `1px solid ${alpha(theme.palette.main.permanentNeutral, 0.12)}`,
}));

export const EllipsisTypography = styled(Typography)(() => ({
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '160px',
}));
