import React, { ChangeEvent, FC, useState } from 'react';
import { AssignEditInfo, Assigns, AssignSort } from '@dar/api-interfaces';
import { ActionToolbar } from '@dar/components/ActionToolbar';
import { AssignCourseModal } from '@dar/components/assignCourseModal';
import { TablePaginationWOTotal } from '@dar/components/tablePaginationWOTotal/TablePaginationWOTotal';
import { Table, TableContainer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete-outline.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit-outlined.svg';
import { useToaster } from '../../../../../contexts/toast.context';
import { DeleteModal } from '../DeleteModal/DeleteModal';
import { ActionButton, TableWrapper } from './AssignTable.styles';
import { TableHeader, TableBody } from './components';

type Props = {
  assigns: Assigns[];
  sort: AssignSort;
  nextPageClickDisabled: boolean;
  paginationData: {
    page: number;
    size: number;
  };
  rowsPerPage: number;
  courseId: number;
  onSort: (s: AssignSort) => void;
  onNextPageClick: () => void;
  onPrevPageClick: () => void;
  onChangeRowsPerPage: (e: ChangeEvent<HTMLInputElement>) => void;
  onRefetch: () => void;
};

export const AssignTable: FC<Props> = ({
  assigns,
  sort,
  nextPageClickDisabled,
  paginationData,
  rowsPerPage,
  courseId,
  onSort,
  onNextPageClick,
  onPrevPageClick,
  onChangeRowsPerPage,
  onRefetch,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>([]);
  const [openAssignModal, setOpenAssignModal] = useState<
    'edit-single' | 'edit-multi' | 'add' | null
  >(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState<AssignEditInfo>();

  const { showToast } = useToaster();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleSelect();
      return;
    }
    setSelected([]);
  };

  const handleSelect = () => {
    const newSelected = assigns.map((item) => item.Employee.employeeId);
    setSelected(newSelected);
    setEmployeeInfo({ id: newSelected });
  };

  const handleSelectOne = (
    event: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      const newSelected = assigns
        .filter((assign) => assign.Employee.employeeId === id)
        .map((item) => item.Employee.employeeId);

      setSelected((prev) => {
        const arr = [...prev, ...newSelected];
        setEmployeeInfo({ id: arr });
        return arr;
      });
      return;
    }

    setSelected((prev) => {
      const arr = prev.filter((p) => p !== id);
      setEmployeeInfo({ id: arr });
      return arr;
    });
  };

  const handleToast = ({ isSuccessfull }: { isSuccessfull: boolean }) => {
    showToast(
      isSuccessfull
        ? t('assign_course.edit_toast_success')
        : t('assign_course.toast_error'),
      isSuccessfull ? 'success' : 'error'
    );
  };

  return (
    <TableWrapper>
      {selected.length > 0 && (
        <ActionToolbar
          selectCount={selected.length}
          buttons={
            <>
              <ActionButton
                onClick={() => {
                  setOpenAssignModal('edit-multi');
                }}
              >
                <EditIcon />
                {t('assign_menu.edit')}
              </ActionButton>
              <ActionButton onClick={() => setDeleteModal(true)}>
                <DeleteIcon />
                {t('assign_menu.delete')}
              </ActionButton>
            </>
          }
          onSelectAllClick={handleSelect}
          onClearClick={() => setSelected([])}
        />
      )}
      <TableContainer sx={{ marginTop: '8px' }}>
        <Table>
          <TableHeader
            selectedLength={selected.length}
            rowCount={assigns.length}
            sort={sort}
            onSortClick={onSort}
            onSelectAll={handleSelectAllClick}
          />
          <TableBody
            selected={selected}
            items={assigns}
            onEditClick={(data: AssignEditInfo) => {
              setEmployeeInfo(data);
              setOpenAssignModal('edit-single');
            }}
            onDeleteClick={(data: AssignEditInfo) => {
              setEmployeeInfo(data);
              setDeleteModal(true);
            }}
            onSelectClick={handleSelectOne}
          />
        </Table>
      </TableContainer>
      <TablePaginationWOTotal
        rowsPerPage={rowsPerPage}
        paginationData={paginationData}
        nextPageClickDisabled={nextPageClickDisabled}
        elementsOnPage={assigns.length}
        handleChangeRowsPerPage={onChangeRowsPerPage}
        nextPageClick={onNextPageClick}
        prevPageClick={onPrevPageClick}
      />

      {openAssignModal && (
        <AssignCourseModal
          courseId={courseId}
          editInfo={employeeInfo}
          openType={openAssignModal}
          onClose={() => {
            setOpenAssignModal(null);
            setSelected([]);
          }}
          setNotification={(v) => {
            handleToast(v);
            onRefetch();
          }}
        />
      )}

      {deleteModal && (
        <DeleteModal
          courseId={courseId}
          open={deleteModal}
          employeeInfo={employeeInfo}
          onClose={() => {
            setDeleteModal(false);
            setSelected([]);
          }}
          onRefetch={onRefetch}
        />
      )}
    </TableWrapper>
  );
};
