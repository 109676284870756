import { FC, useCallback } from 'react';
import { CourseTabTypes, LmsTabTypes, NavTab } from '@dar/api-interfaces';
import { Badge, Box, styled, Tab, Tabs, Typography } from '@mui/material';

type Props = {
  activeTab: LmsTabTypes | CourseTabTypes;
  onTabChange: (v: LmsTabTypes | CourseTabTypes) => void;
  tabs: NavTab[];
};

export const NavTabs: FC<Props> = ({ activeTab, tabs, onTabChange }) => {
  const handleChange = useCallback(
    (_: React.ChangeEvent<unknown>, tab: LmsTabTypes | CourseTabTypes) => {
      onTabChange(tab);
    },
    [onTabChange]
  );

  return (
    <Wrapper>
      <Tabs indicatorColor="primary" value={activeTab} onChange={handleChange}>
        {tabs
          .filter((i) => i.hasAccess)
          .map((tabItem) => (
            <Tab
              key={tabItem.value}
              value={tabItem.value}
              label={
                <Box display="flex" alignItems="center">
                  <Typography variant="textMedium_2" sx={{ color: 'inherit' }}>
                    {tabItem.label}
                  </Typography>
                  {tabItem.badgeContent > 0 && (
                    <CounterBadge
                      max={99}
                      badgeContent={tabItem.badgeContent}
                      color="primary"
                    />
                  )}
                </Box>
              }
            />
          ))}
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  padding: '0 24px',
  borderBottom: '1px solid #26284214',
});

const CounterBadge = styled(Badge)`
  margin-left: 18px;
  & span {
    background-color: #0072b8;
  }
`;
