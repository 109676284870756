import { Typography, styled, Box, IconButton } from '@mui/material';
import theme from '../../theme';

export const classes = {
  select: 'lms-select',
  autocompleteError: 'lms-autocompleteError',
};

export const SelectWrapper = styled(Box)(() => ({
  marginLeft: '38px',

  [`& .${classes.select}`]: {
    height: '48px',
    borderRadius: '12px',
  },
  [`& .${classes.autocompleteError}`]: {
    color: theme.palette.common.white,
    fontSize: 16,
    lineHeight: 1.7,
  },
}));

export const Title = styled(Typography)(() => ({}));

export const Description = styled(Typography)(() => ({
  marginBottom: '12px',
  display: 'block',
}));

export const Wrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '500px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  border: '1px solid rgba(38,40,66, 0.08)',
  borderRadius: '12px',
  overflow: 'hidden',
}));

export const RadioWrapper = styled(Box)(() => ({
  marginBottom: '12px',
  '&:last-child': {
    marginBottom: '8px',
  },
}));

export const Header = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 20px',
  borderBottom: '1px solid rgba(38,40,66, 0.08)',
}));

export const CloseButton = styled(IconButton)(() => ({
  padding: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const Body = styled(Box)(() => ({
  minHeight: '160px',
  padding: '20px 16px',
}));

export const Footer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '0 8px',
  padding: '12px 16px',
  backgroundColor: '#F7F8FC',
}));

export const VisibilityCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 12px',
  borderRadius: '12px',
  border: selected
    ? `2px solid ${theme.palette.main.permanentPrimary}`
    : `1px solid ${theme.palette.main.outline2}`,
}));

export const Radios = styled(Box)(() => ({
  padding: '12px 16px',
}));
