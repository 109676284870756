import { TableCell, TableSortLabel, styled } from '@mui/material';
import { ReactComponent as Employees } from '../../../../../../../assets/icons/employees.svg';

export const StyledSortLabel = styled(TableSortLabel)(() => ({
  position: 'relative',
  flexDirection: 'row',
  color: '#6D6E85',
  '& svg': {
    opacity: 1,
    overflow: 'initial',
    '&$active': {
      '&svg': {
        '&path': {
          fill: '#262842 !important',
        },
      },
    },
  },
  '&.MuiTableSortLabel-active > svg > path': {
    fill: '#262842',
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  background: '#F7F8FC',
}));

export const EmployeesIcon = styled(Employees)(() => ({
  marginRight: '8px',
}));
