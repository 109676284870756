import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { isNavMini$ } from '@dar-dms/topbar';
import {
  ButtonTab,
  GetStudentProgressRequestParams,
  MoodleOrderKind,
  MoodlePagination,
  StudentProgress,
  StudentProgressFieldOrderKind,
  StudentProgressStatusKind,
} from '@dar/api-interfaces';
import { ButtonTabs } from '@dar/components/buttonTabs/ButtonsTabs';
import { SearchInput } from '@dar/components/searchInput';
import { TablePagination } from '@dar/components/tablePagination';
import { downloadUsersProgress, getUsersProgressTable } from '@dar/services/5q';
import { useQuery as useUtilsQuery } from '@dar/utils/courses';
import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';
import { ROWS_PER_PAGE_OPTIONS } from 'apps/dar-front/src/constants';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { distinctUntilChanged } from 'rxjs';
import { ReactComponent as NoStudentsIcon } from '../../../../../assets/icons/course-empty.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/donwload_icon.svg';
import { CourseSectionSelector } from './components/courseSectionSelector/CourseSectionSelector';
import { JournalTableBody } from './components/journalTableBody/JournalTableBody';
import { TableHeader } from './components/tableHeader/TableHeader';
import {
  ContentWrapper,
  EmptyContainer,
  ReportButton,
  Toolbar,
} from './CourseProgressTable.style';

type Props = {
  courseId: number;
};

export const CoursesProgressReportTable: FC<Props> = ({ courseId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useUtilsQuery();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activities, setActivities] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isNavBarMinified, setIsNavBarMinified] = useState(false);

  const [courseSections, setCourseSections] = useState([]);
  const [sortOrder, setSortOrder] = useState<MoodleOrderKind>(
    MoodleOrderKind.DESC
  );
  const [sortField, setSortField] = useState<StudentProgressFieldOrderKind>(
    StudentProgressFieldOrderKind.PROGRESS
  );
  const [studentProgresses, setStudentProgressess] = useState<
    StudentProgress[]
  >([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState<MoodlePagination>({
    per_page: 10,
    page: 1,
    total: 0,
  });
  const [search, setSearch] = useState('');
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(1);
  const [isFetching, setIsFetching] = useState(false);
  const [activeStatus, setActiveStatus] = useState<StudentProgressStatusKind>(
    StudentProgressStatusKind.ALL
  );

  const pageCount = useMemo(
    () => Math.ceil(paginationData.total / rowsPerPage),
    [paginationData.total, rowsPerPage]
  );
  const currentPage = useMemo(
    () => Number(paginationData.page),
    [paginationData.page]
  );

  const open = Boolean(anchorEl);

  const handleOpenMenu = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchAssignmentSubmissions = async (
    body: GetStudentProgressRequestParams,
    signal?: AbortSignal
  ) => {
    setIsFetching(true);
    try {
      const data = await getUsersProgressTable(body, signal);
      setPaginationData(data.pagination);
      setStudentProgressess(data.users);
      setActivities(data.activities.map((activityItem) => activityItem.name));
      const filtered = data.courseContent.filter(
        (section) => section.visible && section.section !== 0
      );
      setCourseSections(filtered);
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  };

  const requestBodyWithoutPage = useMemo(
    () => ({
      course_id: Number(courseId),
      sort: {
        field: sortField,
        order: sortOrder,
      },
      status: activeStatus,
      search: search,
      download: 0,
      section_id: selectedSectionIndex,
      lang:
        (localStorage.getItem('i18nextLng') === 'kz'
          ? 'kk'
          : localStorage.getItem('i18nextLng')) || 'en',
    }),
    [courseId, sortField, sortOrder, activeStatus, search, selectedSectionIndex]
  );

  const handleRequestSort = (
    sortField: StudentProgressFieldOrderKind,
    sortOrder: MoodleOrderKind
  ) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const handleChangePage = useCallback(
    async (event: unknown, page: number) => {
      if (!selectedSectionIndex) {
        return;
      }
      await fetchAssignmentSubmissions({
        ...requestBodyWithoutPage,
        page: page,
        per_page: rowsPerPage,
      });
    },
    [requestBodyWithoutPage, rowsPerPage, selectedSectionIndex]
  );

  const handleChangeRowsPerPage = useCallback(
    async (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      await fetchAssignmentSubmissions({
        ...requestBodyWithoutPage,
        page: 1,
        per_page: parseInt(event.target.value, 10),
      });
    },
    [requestBodyWithoutPage]
  );

  const handleSectionChange = useCallback(async (section_id: number) => {
    setSelectedSectionIndex(section_id);
  }, []);

  useQuery(['assignment-submissions', requestBodyWithoutPage], {
    queryFn: async ({ signal }) =>
      fetchAssignmentSubmissions(
        {
          ...requestBodyWithoutPage,
          page: 1,
          per_page: 10,
        },
        signal
      ),
  });

  useEffect(() => {
    const statusQuery = query.get('status')?.toLowerCase();
    if (statusQuery) {
      statusQuery === 'all'
        ? setActiveStatus(StudentProgressStatusKind.ALL)
        : statusQuery === 'active'
        ? setActiveStatus(StudentProgressStatusKind.ACTIVE)
        : setActiveStatus(StudentProgressStatusKind.COMPLETED);
    }
  }, [query]);

  const handleDownloadReport = async (type: 'current' | 'all') => {
    setIsDownloading(true);

    try {
      const data = await downloadUsersProgress({
        ...requestBodyWithoutPage,
        page: 1,
        per_page: rowsPerPage,
        section_id: type === 'all' ? 0 : selectedSectionIndex,
      });
      const sectionName = courseSections?.find(
        (item) => item.section === selectedSectionIndex
      )?.name;
      saveAs(data, `report_${sectionName}_progress.xlsx`);
    } catch (err) {
      console.log(err);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleStatusChange = useCallback(
    (v: StudentProgressStatusKind) => {
      const queryString = Object.entries(StudentProgressStatusKind)
        .find((entry) => entry[1] === v)[0]
        .toLowerCase();
      navigate(
        `/lms/analytics/progress/course/${courseId}/course-dashboard?status=${queryString}`
      );
      setActiveStatus(v);
    },
    [courseId, navigate]
  );

  const buttonTabs: ButtonTab[] = [
    {
      value: StudentProgressStatusKind.ALL,
      text: t('progress.detailnav_all'),
    },
    {
      value: StudentProgressStatusKind.ACTIVE,
      text: t('progress.tableheader_active'),
    },
    {
      value: StudentProgressStatusKind.COMPLETED,
      text: t('progress.tableheader_completed'),
    },
  ];

  useEffect(() => {
    let subscription;
    if (isNavMini$) {
      subscription = isNavMini$
        .pipe(distinctUntilChanged())
        .subscribe((v) => setIsNavBarMinified(v));
    }
    return () => {
      subscription?.unsubscribe?.();
    };
  }, []);

  return (
    <div>
      <Toolbar>
        <ButtonTabs
          activeButton={activeStatus}
          handleClick={handleStatusChange}
          buttons={buttonTabs}
        />
        <Box>
          <SearchInput
            placeholder={t('common.employee_search_placeholder')}
            onChange={(value) => setSearch(value)}
          />
          <ReportButton
            variant="outlined"
            onClick={handleOpenMenu}
            color="secondary"
            disabled={isDownloading}
          >
            <DownloadIcon />
            <Typography variant="body2" sx={{ marginLeft: '8px' }}>
              {t('progress.download_report')}
            </Typography>
          </ReportButton>
        </Box>
      </Toolbar>
      <Box display="flex" sx={{ minHeight: 'calc(100vh - 228px)' }}>
        <CourseSectionSelector
          activeSection={selectedSectionIndex}
          sectionIndexes={courseSections?.map((item) => item.section) || []}
          onChangeSection={handleSectionChange}
        />
        {isFetching ? (
          <EmptyContainer>
            <CircularProgress size={50} />
          </EmptyContainer>
        ) : studentProgresses.length > 0 ? (
          <ContentWrapper>
            <TableContainer
              sx={{
                width: `calc(100vw - ${isNavBarMinified ? '154px' : '346px'})`,
                borderRadius: '12px',
              }}
            >
              <Table>
                <TableHeader
                  modNames={activities}
                  onRequestSort={handleRequestSort}
                  sortOrder={sortOrder}
                  sortField={sortField}
                />
                <JournalTableBody studentProgresses={studentProgresses} />
              </Table>
            </TableContainer>
            <TablePagination
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalItems={paginationData.total}
              count={pageCount}
              handlePageChange={handleChangePage}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              handleRowPerPageChange={handleChangeRowsPerPage}
            />
          </ContentWrapper>
        ) : (
          <EmptyContainer>
            <NoStudentsIcon />
            <Typography
              variant="h5"
              sx={{ color: '#6D6E85', marginTop: '16px' }}
            >
              {t('progress.deatiltable_empty')}
            </Typography>
          </EmptyContainer>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ marginTop: '56px' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          style: { width: '100%', minWidth: '156px' },
        }}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleDownloadReport('current');
            handleMenuClose();
          }}
        >
          {t('progress.download_report_current')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDownloadReport('all');
            handleMenuClose();
          }}
        >
          {t('progress.download_report_all')}
        </MenuItem>
      </Menu>
    </div>
  );
};
