import 'dayjs/locale/ru';
import 'dayjs/locale/kk';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/id';
import { FC, useCallback, useState } from 'react';
import { theme } from '@dartech/dms-ui';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { format, isValid, parse, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/small_close_icon.svg';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import {
  IconsContainer,
  InputField,
  StyledCalendar,
} from './DateRangePicker.styles';

type Props = {
  filterValue: {
    startDate: string;
    endDate: string;
  };
  onChange: (value: { startDate: string; endDate: string }) => void;
  handleClear: () => void;
  dateFormat?: string;
};

export const DateRangePicker: FC<Props> = ({
  filterValue,
  onChange,
  handleClear,
  dateFormat,
}) => {
  const { t } = useTranslation();
  const locale =
    localStorage.getItem('i18nextLng') === 'kz'
      ? 'kk'
      : localStorage.getItem('i18nextLng') || 'en';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatStr = dateFormat ? dateFormat : DEFAULT_DATE_FORMAT;

  const parseInputValue = (date: string) => {
    const parsedIso = parseISO(date);
    if (isValid(parsedIso)) {
      return parsedIso;
    }
    if (date?.includes('.')) {
      return parse(date, DEFAULT_DATE_FORMAT, new Date());
    }
    return parse(date, formatStr, new Date());
  };

  const handleChange = useCallback(
    (value: [Date | null, Date | null]) => {
      onChange({
        startDate: value[0] ? format(new Date(value[0]), formatStr) : '',
        endDate: value[1] ? format(new Date(value[1]), formatStr) : '',
      });
    },
    [formatStr, onChange]
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      borderBottom="1px solid rgba(38, 40, 66, 0.08)"
      padding={`${theme.spacing(2)} ${theme.spacing(4)}`}
    >
      <InputField onClick={handleClick}>
        {filterValue.startDate && filterValue.endDate ? (
          <Typography>
            {filterValue.startDate + '-' + filterValue.endDate}
          </Typography>
        ) : (
          <Typography color="InactiveCaptionText">{`${t(
            'common.date_format'
          )} - ${t('common.date_format')}`}</Typography>
        )}
        <IconsContainer>
          {filterValue.startDate && filterValue.endDate ? (
            <IconButton
              sx={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                handleClear();
              }}
            >
              <ClearIcon />
            </IconButton>
          ) : null}
          <IconButton sx={{ padding: 0 }} onClick={handleClick}>
            <CalendarIcon />
          </IconButton>
        </IconsContainer>
      </InputField>
      <Popover
        id={open ? 'range-calendar' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '10px',
            marginTop: '4px',
            padding: '12px 16px',
          },
          '& .MuiModal-backdrop': {
            background: 'transparent !important',
          },
        }}
      >
        <StyledCalendar
          locale={locale}
          placeholder="Pick dates range"
          value={
            filterValue.endDate &&
            filterValue.startDate && [
              parseInputValue(filterValue.startDate),
              parseInputValue(filterValue.endDate),
            ]
          }
          styles={() => ({
            day: { borderRadius: '8px' },
          })}
          dayStyle={(date) =>
            format(date, DEFAULT_DATE_FORMAT) ===
            format(new Date(), DEFAULT_DATE_FORMAT)
              ? {
                  border: '2px solid #039BE6',
                  backgroundColor: '#E6F5FD',
                  borderRadius: '8px',
                }
              : null
          }
          onChange={handleChange}
        />
      </Popover>
    </Box>
  );
};
