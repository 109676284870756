import { createContext, useContext } from 'react';
import { getChannelByWorkspace } from '@dar/services/5q';
import { useQuery } from 'react-query';

interface ChannelContextType {
  channelId: number;
  isChannelLoading: boolean;
  refetchChannel: () => void;
}

const ChannelContext = createContext<ChannelContextType>({
  channelId: null,
  isChannelLoading: false,
  refetchChannel: () => {
    //
  },
});

export const useChannelInfo = () => {
  const context = useContext(ChannelContext);
  return context;
};

export const ChannelContextProvider = ({ children }) => {
  const {
    data: channel,
    refetch: refetchChannel,
    isLoading: isChannelLoading,
  } = useQuery(['channel'], {
    queryFn: async () => {
      const r = await getChannelByWorkspace();
      return r;
    },
  });

  return (
    <ChannelContext.Provider
      value={{
        channelId: channel?.id,
        refetchChannel,
        isChannelLoading,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};
