import { Box, styled, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close_icon.svg';

export const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 500px;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 12px;
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
`;

export const ModalTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(38, 40, 66, 1);
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  margin: 0;
  cursor: pointer;

  & path {
    fill: #8b8c9e;
  }
`;

export const Body = styled(Box)`
  padding: 4px 20px 32px;
`;

export const Description = styled(Typography)`
  font-size: 16px;
  line-height: 20px;
  color: rgba(109, 110, 133, 1);
`;

export const Footer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 0 8px;
  padding: 12px 20px;
  border-top: 1px solid rgba(38, 40, 66, 0.12);
  border-radius: 0 0 12px 12px;
  background-color: #f7f8fc;
  overflow: hidden;
`;
