import { useCallback, useEffect, useMemo, useState } from 'react';
import { authService } from '@dar-dms/topbar';
import {
  AclAction,
  DmsJwtPayload,
  DmsLmsPermissions,
  checkPermissions as commonCheckPermissions,
} from '@dar/api-interfaces';
import { Buffer } from 'buffer';

export const usePermissions = () => {
  const [tokenPayload, setTokenPayload] = useState<DmsJwtPayload>();

  useEffect(() => {
    if (authService) {
      const sub = authService.loggedIn.subscribe((auth) => {
        if (auth) {
          const token = localStorage.getItem('workspace_token');
          const payload = token?.split('.')?.[1];
          if (payload) {
            const decoded = Buffer.from(payload, 'base64').toString();
            const parsed = JSON.parse(decoded);
            setTokenPayload(parsed);
          }
        }
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, []);

  const permissonsLoaded = useMemo(() => Boolean(tokenPayload), [tokenPayload]);

  const checkPermissions = useCallback(
    (permission: DmsLmsPermissions, action: AclAction) => {
      if (!tokenPayload) {
        return false;
      }
      return commonCheckPermissions(tokenPayload, permission, action);
    },
    [tokenPayload]
  );

  return { checkPermissions, permissonsLoaded };
};
