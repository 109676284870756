import { useState } from 'react';
import { AcceptedLanguages } from '@dar/api-interfaces';
import { downloadEmployeesProgress } from '@dar/services/5q';
import { AnalyticsLayout } from 'apps/dar-front/src/layout/analytics/AnalyticsLayout';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { AllCoursesTable } from './components/allCoursesTable/AllCoursesTable';
import { ContentWrapper } from './ProgressPage.style';

export const ProgressPage = () => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadReport = async () => {
    setIsDownloading(true);
    const locale = localStorage.getItem('i18nextLng');
    const language =
      locale === 'id'
        ? AcceptedLanguages.ID
        : locale === 'de'
        ? AcceptedLanguages.DE
        : locale === 'kz'
        ? AcceptedLanguages.KZ
        : locale === 'ru'
        ? AcceptedLanguages.RU
        : AcceptedLanguages.EN;
    try {
      const data = await downloadEmployeesProgress({ language });
      const today = format(new Date(), 'dd.MM.yyyy');
      saveAs(data, `all_courses_progress_${today}_${language}.xlsx`);
    } catch (err) {
      console.log(err);
    } finally {
      setIsDownloading(false);
    }
  };
  return (
    <AnalyticsLayout
      breadcrumbs={t('analytics.courses_analytics')}
      handleDownload={handleDownloadReport}
      isDownloading={isDownloading}
    >
      <ContentWrapper>
        <AllCoursesTable />
      </ContentWrapper>
    </AnalyticsLayout>
  );
};
