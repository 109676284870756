import { FC, useMemo } from 'react';
import { CourseAccessForm, WorkspaceByHolding } from '@dar/api-interfaces';
import { AutoCompleteSelect } from '@dartech/dms-ui';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<CourseAccessForm, any>;
  workspacesList: WorkspaceByHolding[];
  locale: { search: string; apply: string; clear: string };
  loading: boolean;
  styles: Record<string, any>;
  disabled?: boolean;
  defaultSelectedWorkspaces: string[];
};

export const WorkspaceSelect: FC<Props> = ({
  control,
  workspacesList,
  locale,
  loading,
  styles,
  disabled,
  defaultSelectedWorkspaces,
}) => {
  const { t } = useTranslation();
  const currentWorkspace = useMemo(
    () => JSON.parse(window.localStorage.getItem('workspace')),
    []
  );

  const filteredDefaultSelectedWorkspaces = useMemo(
    () => defaultSelectedWorkspaces.filter((id) => !id.startsWith('h_')),
    [defaultSelectedWorkspaces]
  );

  const workspacesForSelect = useMemo(() => {
    return workspacesList.map((ws) => ({
      id: `w_${ws.id}`,
      name: `${ws.shortName}${
        currentWorkspace.id === ws.id
          ? ` (${t('course_access.select_current')})`
          : ''
      }`,
    }));
  }, [workspacesList, currentWorkspace, t]);
  console.log(
    defaultSelectedWorkspaces,
    workspacesForSelect.filter((w) =>
      filteredDefaultSelectedWorkspaces?.length
        ? filteredDefaultSelectedWorkspaces.includes(w.id)
        : w.id === `w_${currentWorkspace.id}`
    )
  );
  return (
    <Controller
      name="workspaces"
      control={control}
      defaultValue={
        workspacesForSelect.filter((w) =>
          filteredDefaultSelectedWorkspaces?.length
            ? filteredDefaultSelectedWorkspaces.includes(w.id)
            : w.id === `w_${currentWorkspace.id}`
        ) as { id: string; name: string }[]
      }
      rules={{
        validate: (val) => {
          if (!val || (val && val.length === 0)) {
            return t('course_access.workspace_empty_error');
          }
        },
      }}
      render={({ field, fieldState }) => (
        <AutoCompleteSelect
          {...field}
          locale={locale}
          loading={loading}
          error={fieldState?.error?.message}
          label={t('course_access.workspace_label')}
          onSelect={(value: { id: string; name: string }) =>
            field.onChange(value)
          }
          popperWidth={412}
          prompt={''}
          valueId="id"
          valueName="name"
          variant="filter"
          disabled={disabled}
          options={workspacesForSelect}
          styleClasses={{
            outlinedButton: styles.select,
            error: styles.autocompleteError,
          }}
          value={field.value as unknown as { id: string; name: string }}
        />
      )}
    />
  );
};
