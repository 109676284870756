import { Box, LinearProgress, styled, Typography } from '@mui/material';

export const CardContainer = styled(Box)(({ theme }) => ({
  width: '366px',
  borderRadius: '12px',
  border: `1px solid ${theme.palette.main.surface2}`,
  backgroundColor: theme.palette.main.background1,
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
}));

export const StyledProgressBar = styled(LinearProgress)(({ theme, value }) => ({
  width: '90%',
  height: '6px',
  marginRight: '8px',
  borderRadius: '20px',
  '&.MuiLinearProgress-root': {
    backgroundColor:
      value < 100
        ? theme.palette.main.background3
        : theme.palette.main.permanentSuccess,
  },
  '&.MuiLinearProgress-bar': {
    borderRadius: '20px',
  },
  '& .MuiLinearProgress-barColorSecondary': {
    backgroundColor: '#2DB77B!important',
  },
}));

export const StatusContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'statusColor',
})<{ statusColor: 'primary' | 'error' }>(({ theme, statusColor }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '2px',
  columnGap: '8px',
  padding: '2px 6px',
  borderRadius: '6px',
  width: 'fit-content',
  backgroundColor:
    statusColor === 'primary'
      ? theme.palette.main.permanentPrimaryContainer
      : theme.palette.main.permanentErrorContainer,
  color:
    statusColor === 'primary'
      ? theme.palette.main.permanentPrimaryVariant
      : theme.palette.main.permanentError,
  span: {
    paddingTop: '2px',
    color:
      statusColor === 'primary'
        ? theme.palette.main.permanentPrimaryVariant
        : theme.palette.main.permanentError,
  },
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.main.permanentOnNeutralVariant,
}));
