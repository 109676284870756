import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { authService } from '@dar-dms/topbar';
import { Header } from '@dar-dms/utils';
import { environment } from '@dar/environments/environment';
import {
  AuthStatus,
  LMS_TOKEN_STORAGE_KEY,
  authLmsService,
} from '@dar/services/authLms';
import '../../i18n';
import { Box, CircularProgress } from '@mui/material';
import { useBreadcrumbsContext } from '../../contexts/breadcrumb.context';
import { ChannelContextProvider } from '../../contexts/channel.context';
import { Container, Root } from './style';

const MainLayout = () => {
  const { breadcrumbs } = useBreadcrumbsContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (!localStorage.getItem(LMS_TOKEN_STORAGE_KEY)) {
      if (environment.griffonBucketId !== environment.griffonLmsBucketId) {
        navigate('/lms/auth');
        setLoading(false);
        return;
      }
      authLmsService.copyTokensDmsLms();
    }
    authLmsService
      .getProfile()
      .then((p) => {
        console.log(p);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  useEffect(() => {
    const handler = () => {
      authLmsService.setLoggedInValue(null);
      authLmsService.logout();
      if (environment.griffonBucketId !== environment.griffonLmsBucketId) {
        navigate('/lms/auth');
      } else {
        window.location.reload();
      }
    };
    authLmsService.loginStatus.on(AuthStatus.UNAUTHORIZED, handler);
    return () => {
      authLmsService.loginStatus.off(AuthStatus.UNAUTHORIZED, handler);
    };
  }, [navigate]);

  useEffect(() => {
    if (authService) {
      const sub = authService.loggedIn.subscribe((auth) => {
        if (!auth) {
          authLmsService.loginStatus.emit(AuthStatus.UNAUTHORIZED, true);
        }
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, []);

  if (loading) {
    return (
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <CircularProgress size={32} />
      </Box>
    );
  }
  return (
    <Root>
      <Container>
        {breadcrumbs && <Header breadcrumbs={breadcrumbs} />}
        <ChannelContextProvider>
          <Outlet />
        </ChannelContextProvider>
      </Container>
    </Root>
  );
};

export default MainLayout;
