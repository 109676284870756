import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import { EmployeeFilterForm } from '@dar/api-interfaces';
import { Filter } from '@dar/components/filter/Filter';
import { useDepartmentPositionDirectory } from '@dar/hooks/useDepartmentPoistionDirectory';
import { Box, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DepartmentSelect } from '../department-select/DepartmentSelect';
import { PositionSelect } from '../position-select/PositionSelect';
import { Footer, Main, StyledButton, Wrapper } from './EmployeeFilter.style';

type Props = {
  open: boolean;
  onClose: () => void;
  setFilterValues: Dispatch<
    SetStateAction<{
      departments?: string[];
      positions?: string[];
    }>
  >;
  filterValues: {
    departments?: string[];
    positions?: string[];
  };
};

const defaultValues = {
  departments: null,
  positions: null,
};

export const EmployeeFilter: FC<Props> = ({
  open,
  onClose,
  filterValues,
  setFilterValues,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<EmployeeFilterForm>({
    mode: 'onSubmit',
    defaultValues,
  });

  const {
    departmentsList,
    positionsList,
    isFetching,
    isDepartmentsFetching,
    isPositionsFetching,
  } = useDepartmentPositionDirectory();

  const locale = {
    search: t('assign_course.select_search'),
    apply: t('assign_course.select_apply'),
    clear: t('assign_course.select_clear'),
  };
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = handleSubmit((values) => {
    const departmentNames = values?.departments?.map((department) => {
      const localized = department.localization['en'];
      return localized || department.value;
    });
    const positionsNames = values?.positions?.map((position) => {
      const localized = position.localization['en'];
      return localized || position.value;
    });
    setFilterValues({
      departments: departmentNames,
      positions: positionsNames,
    });
    onClose();
  });

  const handleClearAll = () => {
    reset(defaultValues);
  };
  useEffect(() => {
    if (departmentsList && positionsList) {
      const departments = departmentsList.filter((department) =>
        filterValues?.departments?.some(
          (val) =>
            val === department.localization['en'] || val === department.value
        )
      );
      const positions = positionsList.filter((position) =>
        filterValues?.positions?.some(
          (val) => val === position.localization['en'] || val === position.value
        )
      );
      reset({
        departments: departments?.length > 0 ? departments : null,
        positions: positions?.length > 0 ? positions : null,
      });
    }
  }, [
    departmentsList,
    positionsList,
    filterValues?.positions,
    filterValues?.departments,
    reset,
  ]);

  return (
    <Filter open={open} onClose={handleClose}>
      <Wrapper>
        <Main>
          {isFetching || isDepartmentsFetching || isPositionsFetching ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="calc(100vh - 64px)"
            >
              <CircularProgress size={50} />
            </Box>
          ) : departmentsList?.length > 0 && positionsList?.length > 0 ? (
            <>
              <DepartmentSelect
                control={control}
                locale={locale}
                departmentsList={departmentsList}
                loading={isFetching}
              />
              <PositionSelect
                control={control}
                locale={locale}
                positionsList={positionsList}
                loading={isFetching}
              />
            </>
          ) : null}
        </Main>
        <Footer>
          <StyledButton color="secondary" onClick={handleClearAll}>
            {t('filter.clear_button')}
          </StyledButton>
          <StyledButton onClick={onSubmit} color="primary">
            {t('filter.submit_button')}
          </StyledButton>
        </Footer>
      </Wrapper>
    </Filter>
  );
};
