import { Badge, Box, Button, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  columnGap: '12px',
}));

export const StatusButton = styled(Button)(() => ({
  height: '32px',
  borderRadius: '40px',
  '&.MuiButton-text': {
    backgroundColor: '#F0F0F7',
    color: '#6D6E85',
  },
  padding: '7px 12px',
}));

export const CounterBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'badgeContent' && prop !== 'isActive',
})<{ badgeContent: number; isActive: boolean }>`
  margin-left: ${({ badgeContent }) => (badgeContent > 0 ? '8px' : 0)};

  & span {
    background-color: ${({ isActive }) => (isActive ? '#FFF' : '#8B8C9E')};
    color: ${({ isActive }) => (isActive ? '#262842' : '#FFF')};
  }

  & .MuiBadge-anchorOriginTopRightRectangle {
    transform: none;
  }

  & .MuiBadge-badge {
    position: relative;
  }
`;
