import { StatusEnum } from '@dar/api-interfaces';
import { TFunction } from 'react-i18next';

export const getStatusText = (status: StatusEnum, t: TFunction) => {
  switch (status) {
    case StatusEnum.COMPLETED:
      return t('assign_status.completed');
    case StatusEnum.NOT_STARTED:
      return t('assign_status.not_started');
    case StatusEnum.OVERDUE:
      return t('assign_status.overdue');
    case StatusEnum.PROGRESS:
      return t('assign_status.in_progress');
    case StatusEnum.ACTIVE:
      return t('assign_status.active');
    case StatusEnum.FINISHED:
      return t('assign_status.finished');
    default:
      return;
  }
};
