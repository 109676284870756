import { format } from 'date-fns';

export const replaceTime = (date: Date, time: Date) => {
  const t = time ? format(new Date(time), 'HH:mm:ss') : '00:00:00';

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const [hours, minutes, seconds] = t.split(':').map(Number);
  const updatedDate = new Date(year, month, day, hours, minutes, seconds);

  return format(updatedDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
};
