import { FC } from 'react';
import { CoursesProgressOrderKind, MoodleOrderKind } from '@dar/api-interfaces';
import { TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultSortIcon } from '../../../../../../../assets/icons/sort_icon_default.svg';
import {
  StyledSortLabel,
  StyledTableCell,
} from './CoursesProgressTableHeader.style';

type Props = {
  onRequestSort: (
    property: CoursesProgressOrderKind,
    orderdDirection: MoodleOrderKind
  ) => void;
  sortOrder: MoodleOrderKind;
  sortField: CoursesProgressOrderKind;
  disableSort: boolean;
};

export interface CoursesProgressColumn {
  id: CoursesProgressOrderKind;
  label: string;
  disableSort?: boolean;
  align?: 'left' | 'right' | 'center';
}

export const CoursesProgressTableHeader: FC<Props> = ({
  sortField,
  sortOrder,
  onRequestSort,
  disableSort = false,
}) => {
  const { t } = useTranslation();

  const columns: CoursesProgressColumn[] = [
    {
      id: CoursesProgressOrderKind.TITLE,
      label: t('progress.tableheader_title'),
      disableSort: true,
    },
    {
      id: CoursesProgressOrderKind.ACTIVE,
      label: t('progress.tableheader_active'),
      align: 'right',
      disableSort,
    },
    {
      id: CoursesProgressOrderKind.COMPLETE,
      label: t('progress.tableheader_completed'),
      align: 'right',
      disableSort,
    },
  ];

  const handleSort = (property: CoursesProgressOrderKind) => {
    if (property === sortField) {
      onRequestSort(
        property,
        sortOrder === MoodleOrderKind.DESC
          ? MoodleOrderKind.ASC
          : MoodleOrderKind.DESC
      );
    } else {
      onRequestSort(property, MoodleOrderKind.DESC);
    }
  };

  return (
    <TableHead style={{ background: '#F7F8FC' }}>
      <TableRow>
        {columns.map((column, idx) => {
          const isSortable = !column.disableSort;
          const isSorting = isSortable && sortField === column.id;

          return (
            <StyledTableCell
              key={column.id}
              sortDirection={isSorting ? sortOrder : false}
              align={column.align ? column.align : undefined}
            >
              <StyledSortLabel
                onClick={() => (isSortable ? handleSort(column.id) : undefined)}
                IconComponent={DefaultSortIcon}
                active={column.id === sortField && isSortable}
                hideSortIcon={!isSortable}
                disabled={!isSortable}
              >
                {column.label}
              </StyledSortLabel>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
