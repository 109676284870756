import { useState } from 'react';
import { CourseInfoWithLMS, FeedEntityTypes } from '@dar/api-interfaces';
import { CourseCard } from '@dar/components/courseCard';
import { Button } from '@dar/components/courseCard/CourseCard.styles';
import { CoursesNotFound } from '@dar/components/coursesNotFound';
import { SearchInput } from '@dar/components/searchInput';
import { useLmsRedirect } from '@dar/hooks/useLmsRedirect';
import { assignCourseToSelf, getCoursesByQuickSearch } from '@dar/services/5q';
import { courseAdapter } from '@dar/utils/courses';
import { MatomoKeys, addMatomoEvent } from '@dar/utils/metrics';
import { CircularProgress, Modal, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery, useQueryClient, InfiniteData } from 'react-query';
import { useDebounce } from 'use-debounce';
import { ReactComponent as CloseIcon } from '../../../assets/icons/dismiss-regular.svg';
import {
  ContentWrapper,
  Grid,
  LoaderWrapper,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalTitle,
  ModalTitleWrap,
  ModalWrapper,
  Toolbar,
} from './ShowcasePage.styles';

const PER_PAGE_LIMIT = 12;

export const ShowcasePage = () => {
  const { t } = useTranslation();
  const [accessModalShow, setAccessModalShow] = useState(false);
  const [assignedCourseId, setAssignedCourseId] = useState<number>();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 400);

  const [pageCount, setPageCount] = useState(1);
  const { openCourse } = useLmsRedirect();
  const queryClient = useQueryClient();
  const { data, isLoading, fetchNextPage } = useInfiniteQuery<
    CourseInfoWithLMS[]
  >(
    ['courses-list', debouncedSearch],
    async ({ pageParam = 0 }) => {
      try {
        const workspace = JSON.parse(localStorage.getItem('workspace'));
        const { courses, total } = await getCoursesByQuickSearch({
          filter_by: {
            visible_workspace_ids: [
              `h_${workspace.holdingId}`,
              `w_${workspace.id}`,
            ],
            types: [FeedEntityTypes.COURSE],
            course_statuses: ['PUBLISHED', 'ACTIVATED'],
          },
          sort_by: [
            {
              field: 'publication_date',
              sort_criteria: 'desc',
            },
          ],
          page_index: pageParam,
          page_size: PER_PAGE_LIMIT,
          search,
        });

        setPageCount(Math.ceil(total / PER_PAGE_LIMIT));

        if (!courses) {
          return [];
        }

        return courses.map((course) => ({
          ...courseAdapter(course),
          progress: 0,
          completed_activities: 0,
          total_activities: 0,
          total_sections: 0,
        }));
      } catch (e) {
        console.log(e);
      }
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length;
      },
    }
  );

  const handleGetAccessClick = (courseId: string) => {
    assignCourseToSelf({ course_ids: [`course_${courseId}`] }).then((r) => {
      if (r.assigned) {
        setAccessModalShow(true);
        setAssignedCourseId(+courseId);
        queryClient.setQueryData<InfiniteData<CourseInfoWithLMS[]>>(
          ['courses-list', search],
          (oldData) => ({
            ...oldData,
            pages: oldData.pages.map((page) => {
              return page.map((course) => {
                if (course.id === courseId) {
                  console.log(course);
                  return {
                    ...course,
                    invite_status: 'invited',
                  };
                }
                return course;
              });
            }),
          })
        );
      }
    });
  };

  return (
    <>
      <Toolbar>
        <SearchInput
          placeholder={t('common.course_search_placeholder')}
          onChange={(value) => setSearch(value)}
        />
      </Toolbar>

      <ContentWrapper id="scrollableContent">
        {isLoading ? (
          <SkeletonWrapper />
        ) : (
          <InfiniteScroll
            next={fetchNextPage}
            hasMore={(data?.pages?.length || 1) < pageCount}
            loader={
              <LoaderWrapper>
                <CircularProgress size={24} />
              </LoaderWrapper>
            }
            dataLength={(data?.pages?.length || 1) * PER_PAGE_LIMIT}
            scrollableTarget="scrollableContent"
          >
            <Grid>
              {data?.pages.map((page) => {
                return page.map((course) => (
                  <CourseCard
                    key={course.id}
                    id={course.id}
                    img={course.course_card_image}
                    name={course.fullName}
                    inviteStatus={course.invite_status}
                    onGetAccess={() => handleGetAccessClick(course.id)}
                  />
                ));
              })}

              {!isLoading && pageCount === 0 && (
                <CoursesNotFound withoutDescription />
              )}
            </Grid>
          </InfiniteScroll>
        )}
      </ContentWrapper>

      <Modal open={accessModalShow} onClose={() => setAccessModalShow(false)}>
        <ModalWrapper>
          <ModalContent>
            <ModalTitleWrap>
              <ModalTitle>
                {t('assigned_modal.title', { ns: 'lms' })}
              </ModalTitle>
              <CloseIcon
                onClick={() => setAccessModalShow(false)}
                style={{ cursor: 'pointer' }}
              />
            </ModalTitleWrap>
            <ModalDescription>
              {t('assigned_modal.description', { ns: 'lms' })}
            </ModalDescription>
          </ModalContent>

          <ModalFooter>
            <Button
              variant="primary"
              size="large"
              title={t('common.start_course_btn', { ns: 'lms' })}
              onClick={() => {
                openCourse(assignedCourseId);
                setAccessModalShow(false);
                setAssignedCourseId(undefined);
                addMatomoEvent(MatomoKeys.CLICK_BTN_COURSE_START);
              }}
            />
          </ModalFooter>
        </ModalWrapper>
      </Modal>
    </>
  );
};

const SkeletonWrapper = () => {
  const skeletonArray = new Array(12).fill('_');

  return (
    <Grid>
      {skeletonArray.map((i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          width={272}
          height={314}
          sx={{ borderRadius: '12px' }}
        />
      ))}
    </Grid>
  );
};
