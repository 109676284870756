export interface BreadCrumbs {
  text: string;
  to: string;
}

export enum LmsTabTypes {
  ASSIGNED = 'assigned',
  EMPLOYEES = 'employees',
  PROGRESS = 'progress',
  MY_COURSES = 'my_courses',
  SHOWCASE = 'showcase',
  MANAGE = 'manage',
  ANALYTICS = 'analytics',
}

export enum CourseTabTypes {
  DASHBOARD = 'course-dashboard',
  HOMEWORK = 'homework',
  ASSIGNS = 'assigns',
}

export interface NavTab {
  label: string;
  value: string;
  hasAccess: boolean;
  badgeContent?: number;
}

export interface ButtonTab {
  text: string;
  value: string;
  badgeContent?: number;
}
