import { useState } from 'react';
import {
  CourseStatuses,
  FeedEntityTypes,
  CourseInfoWithLMS,
} from '@dar/api-interfaces';
import { ArchiveCourseModal } from '@dar/components/archiveCourseModal/ArchiveCourseModal';
import { CourseAccessModal } from '@dar/components/courseAccessModal/CourseAccessModal';
import { CoursesNotFound } from '@dar/components/coursesNotFound';
import { CreateCourseModal } from '@dar/components/createCourseModal/createCourseModal';
import { SearchInput } from '@dar/components/searchInput';
import { getCoursesByQuickSearch, updateCourse } from '@dar/services/5q';
import { courseAdapter } from '@dar/utils/courses';
import {
  CircularProgress,
  MenuItem,
  Skeleton,
  SortDirection,
} from '@mui/material';
import { useChannelInfo } from 'apps/dar-front/src/contexts/channel.context';
import { useToaster } from 'apps/dar-front/src/contexts/toast.context';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'use-debounce';
import { ReactComponent as NextIcon } from '../../../assets/icons/chevron-down-icon.svg';
import { CoursesTableBody } from './components/coursesTableBody/CoursesTableBody';
import { CoursesTableHeader } from './components/coursesTableHeader/CoursesTableHeader';
import {
  ContentWrapper,
  Grid,
  LoaderWrapper,
  StyledSelect,
  StyledTable,
  Toolbar,
} from './ManagePage.styles';

const PER_PAGE_LIMIT = 12;

const StatusLabels: Record<string, string> = {
  ALL: 'course.all',
  ACTIVATED: 'course.activate',
  DRAFT: 'course.draft',
  ARCHIVED: 'course.archive',
};

const SelectIcon = (props: any) => <NextIcon {...props} />;

export const ManagePage = () => {
  const { t } = useTranslation();
  const [assignedCourseId, setAssignedCourseId] = useState<number>();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 400);
  const { channelId } = useChannelInfo();
  const [statusFilter, setStatusFilter] = useState<string>('ALL');
  const [sortValue, setSortValue] = useState<'asc' | 'desc' | ''>('desc');
  const [openCourseAccessModal, setOpenCourseAccessModal] = useState(false);
  const { showToast } = useToaster();

  const [createCourseModal, setCreateCourseModal] = useState(false);
  const [courseInfo, setCourseInfo] = useState<{ id: number; name: string }>();
  const [courseArchive, setCourseArchive] = useState<null | number>(null);
  const [sortValues, setSortValues] = useState<{
    field: string;
    sort_criteria: SortDirection;
  }>({
    field: '',
    sort_criteria: false,
  });

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<CourseInfoWithLMS[]>(
    [
      'mentor-courses-list',
      channelId,
      debouncedSearch,
      statusFilter,
      sortValue,
    ],
    async () => {
      if (!channelId) {
        return [];
      }
      try {
        const { courses, total } = await getCoursesByQuickSearch({
          filter_by: {
            channel_ids: [`${channelId}`],
            types: [FeedEntityTypes.COURSE],
            course_statuses:
              statusFilter === 'ALL' || !statusFilter
                ? ['PUBLISHED', 'DRAFT', 'ACTIVATED', 'HIDDEN']
                : statusFilter === 'ACTIVATED'
                ? ['ACTIVATED', 'HIDDEN']
                : [statusFilter],
          },
          sort_by: [
            {
              field: 'timemodified',
              sort_criteria: sortValue || 'desc',
            },
          ],
          page_index: 0,
          page_size: PER_PAGE_LIMIT,
          search: debouncedSearch,
        });

        if (!courses) {
          return [];
        }
        return courses.map((course) => ({
          ...courseAdapter(course),
          progress: 0,
          completed_activities: 0,
          total_activities: 0,
          total_sections: 0,
        }));
      } catch (e) {
        console.error('Could not get course');
      }
    },
    {
      enabled: Boolean(channelId),
      refetchInterval: 10000,
    }
  );

  const isEmpty = channelId && !isLoading && !data?.length;

  const handleAccessEdit = (courseId: number) => {
    setAssignedCourseId(courseId);
    setOpenCourseAccessModal(true);
  };

  const mutateQuery = (
    courseId: number,
    props: { status?: string; fullName?: string }
  ) => {
    queryClient.setQueryData<CourseInfoWithLMS[]>(
      [
        'mentor-courses-list',
        channelId,
        debouncedSearch,
        statusFilter,
        sortValue,
      ],
      (oldData) =>
        oldData
          .map((course) => {
            console.log(oldData);
            if (course.id === `${courseId}`) {
              return {
                ...course,
                ...props,
              };
            }
            return course;
          })
          .filter((c) => {
            if (statusFilter === 'ALL') {
              return c.status !== 'ARCHIVED';
            }
            return c.status === statusFilter;
          })
    );
  };
  const handleSuccessAccess = (visibility: 'open' | 'hidden') => {
    setOpenCourseAccessModal(false);
    showToast(t('course.access_success'), 'success');
    mutateQuery(assignedCourseId, {
      status: visibility === 'open' ? 'ACTIVATED' : 'HIDDEN',
    });
  };

  const handleSuccessArchive = () => {
    showToast(t('course.archived_success'), 'success');
    mutateQuery(courseArchive, { status: 'ARCHIVED' });
    setCourseArchive(null);
  };

  const handleNameEdit = (id: number, newName: string) => {
    setCreateCourseModal(false);
    mutateQuery(id, { fullName: newName });
  };

  const handleUnarchiveCourse = async (id: number) => {
    const response = await updateCourse({
      courses: [
        {
          customfields: [
            {
              type: 'course_status',
              value: `${CourseStatuses.DRAFT}`,
            },
          ],
          id,
        },
      ],
    });

    if (response) {
      showToast(t('course.unarchived_success'), 'success');
      mutateQuery(id, { status: 'DRAFT' });
    }
  };

  const handleRequestSort = (sortField: string, sortOrder: SortDirection) => {
    setSortValues({ field: sortField, sort_criteria: sortOrder });
  };

  return (
    <>
      <Toolbar>
        <SearchInput onChange={(value) => setSearch(value)} />
        <StyledSelect
          sx={{ marginLeft: '12px' }}
          value={statusFilter}
          displayEmpty
          renderValue={(v: unknown) => t(StatusLabels[v as string])}
          IconComponent={SelectIcon}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          onChange={({ target: { value } }) => {
            setStatusFilter(value as string);
          }}
        >
          {Object.entries(StatusLabels).map((option) => (
            <MenuItem key={option[0]} value={option[0]}>
              {t(`${option[1]}`)}
            </MenuItem>
          ))}
        </StyledSelect>
        <StyledSelect
          variant="outlined"
          value={sortValue}
          displayEmpty
          renderValue={(v: unknown) => t(`course.sort_date_${v}`)}
          IconComponent={SelectIcon}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          onChange={({ target: { value } }) => {
            setSortValue(value as 'asc' | 'desc');
          }}
        >
          <MenuItem value={'desc'}>{t('course.sort_date_desc')}</MenuItem>
          <MenuItem value={'asc'}>{t('course.sort_date_asc')}</MenuItem>
        </StyledSelect>
      </Toolbar>

      <ContentWrapper id="scrollableContent">
        {isLoading ? (
          <LoaderWrapper>
            <CircularProgress size={24} />
          </LoaderWrapper>
        ) : (
          <Grid>
            {data && !isEmpty && (
              <StyledTable>
                <CoursesTableHeader
                  sortField={sortValues.field}
                  sortOrder={sortValues.sort_criteria}
                  onRequestSort={handleRequestSort}
                  disableSort={true}
                />
                <CoursesTableBody
                  courses={data}
                  onAccessEdit={handleAccessEdit}
                  onEditCourseName={(id: number, name: string) => {
                    setCourseInfo({ id, name });
                    setCreateCourseModal(true);
                  }}
                  onCourseArchive={(id: number) => setCourseArchive(id)}
                  onCourseUnarchive={(id: number) => handleUnarchiveCourse(id)}
                />
              </StyledTable>
            )}
            {isEmpty && (
              <CoursesNotFound
                isManage={!debouncedSearch}
                withoutDescription
                onClick={() => setCreateCourseModal(true)}
              />
            )}
          </Grid>
        )}
      </ContentWrapper>

      {openCourseAccessModal && (
        <CourseAccessModal
          courseId={assignedCourseId}
          open={openCourseAccessModal}
          onClose={() => setOpenCourseAccessModal(false)}
          onSuccess={handleSuccessAccess}
        />
      )}

      {createCourseModal && (
        <CreateCourseModal
          isOpen={createCourseModal}
          courseInfo={courseInfo}
          onNameEdit={handleNameEdit}
          onClose={() => {
            setCreateCourseModal(false);
          }}
        />
      )}

      {courseArchive && (
        <ArchiveCourseModal
          courseId={courseArchive}
          onClose={() => setCourseArchive(null)}
          onSuccess={handleSuccessArchive}
        />
      )}
    </>
  );
};

const SkeletonWrapper = () => {
  const skeletonArray = new Array(12).fill('_');

  return (
    <Grid>
      {skeletonArray.map((i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          width={272}
          height={314}
          sx={{ borderRadius: '12px' }}
        />
      ))}
    </Grid>
  );
};
