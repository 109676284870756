import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CourseProgressResponse } from '@dar/api-interfaces';
import { MatomoKeys, addMatomoEvent } from '@dar/utils/metrics';
import { TableCell } from '@mui/material';
import { StyledTableRow, TableBody } from './CoursesProgressTableBody.style';

type Props = {
  courses: CourseProgressResponse[];
};

export const CoursesProgressTableBody: FC<Props> = ({ courses }) => {
  const navigate = useNavigate();
  return (
    <TableBody>
      {courses.map((course) => {
        const courseId = course.id.replace('course_', '');
        return (
          <StyledTableRow
            key={course.id}
            hover
            onClick={() => {
              addMatomoEvent(MatomoKeys.CLICK_ON_TABLE_ROW_COURSE_PROGRESS, {
                courseId,
              });
              navigate(`/lms/analytics/progress/course/${courseId}`);
            }}
          >
            <TableCell>{course.title}</TableCell>
            <TableCell align="right">{course.active_sum}</TableCell>
            <TableCell align="right">{course.complete_sum}</TableCell>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};
