import React, { forwardRef } from 'react';
import { TimeUtil } from '@dar/utils/timeUtil';
import { createSvgIcon } from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  DayCalendarSkeleton,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatepickerWrapper } from './Datepicker.styles';

type Props = {
  value: Date | null;
  format: string;
  hasError?: boolean;
  placeholder?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: Date;
  onChange: (v: Date | null) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

export const Datepicker: React.FC<Props> = forwardRef(
  (
    {
      format,
      value,
      hasError,
      placeholder,
      disableFuture,
      disablePast,
      minDate,
      onChange,
      onOpen,
      onClose,
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const handleMonthChange = () => {
      setIsLoading(true);

      setTimeout(() => setIsLoading(false), 300);
    };

    return (
      <DatepickerWrapper>
        <LocalizationProvider
          adapterLocale={TimeUtil.getLocale()}
          dateAdapter={AdapterDateFns}
        >
          <MuiDatePicker
            className="styledDatePicker"
            format={format}
            value={value}
            disableFuture={disableFuture}
            disablePast={disablePast}
            minDate={minDate}
            showDaysOutsideCurrentMonth
            renderLoading={() => <DayCalendarSkeleton />}
            loading={isLoading}
            onChange={(newValue) => onChange(newValue)}
            onMonthChange={handleMonthChange}
            onOpen={onOpen}
            onClose={onClose}
            slots={{
              openPickerIcon: CalendarIcon,
            }}
            slotProps={{
              textField: {
                error: hasError,
                placeholder: placeholder,
              },
              popper: {
                sx: {
                  '.MuiPaper-root': {
                    marginTop: '5px',
                    borderRadius: '12px',
                    border: '1px solid rgba(38, 40, 66, 0.12)',
                  },
                  '.MuiDateCalendar-root': {
                    width: 'auto',
                    height: 'auto',
                    padding: '12px 14px',
                  },
                  '.MuiPickersCalendarHeader-root': {
                    minHeight: '30px',
                    margin: '0 0 8px',
                    padding: '0',
                    '.MuiButtonBase-root': {
                      height: '32px',
                      padding: '4px',
                      margin: '0',
                    },
                  },
                  '.MuiPickersDay-root, .MuiSkeleton-root': {
                    width: '32px!important',
                    height: '32px!important',
                    borderRadius: '8px',
                    color: '#262842',
                    fontSize: '14px',
                    fontWeight: '600!important',
                    lineHeight: '18px',
                  },
                  '.MuiPickersDay-today': {
                    border: '2px solid #039BE6!important',
                    backgroundColor: '#E6F5FD!important',
                    color: '#262842!important',
                  },
                  '.Mui-selected': {
                    backgroundColor: '#039BE6!important',
                    color: '#FFF!important',
                  },
                  '.MuiPickersSlideTransition-root': {
                    minHeight: 'auto',
                  },
                  '.MuiDayCalendar-weekDayLabel': {
                    width: '32px',
                    height: '16px',
                    color: '#6D6E85',
                  },
                  '.MuiDayCalendar-header': { marginBottom: '8px' },
                  '.MuiDayCalendar-monthContainer, .MuiDayCalendarSkeleton-root':
                    {
                      position: 'static',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px 0',
                    },
                  '.MuiPickersDay-dayOutsideMonth': {
                    color: '#C0C1D1',
                  },
                  '.MuiDayCalendar-weekContainer': {
                    margin: '0',
                  },
                },
              },
              inputAdornment: {
                position: 'start',
              },
            }}
          />
        </LocalizationProvider>
      </DatepickerWrapper>
    );
  }
);

const CalendarIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 18.3333C3.70833 18.3333 3.31597 18.1701 2.98958 17.8437C2.66319 17.5173 2.5 17.125 2.5 16.6666V4.99996C2.5 4.54163 2.66319 4.14927 2.98958 3.82288C3.31597 3.49649 3.70833 3.33329 4.16667 3.33329H5V2.49996C5 2.26385 5.07986 2.06593 5.23958 1.90621C5.39931 1.74649 5.59722 1.66663 5.83333 1.66663C6.06944 1.66663 6.26736 1.74649 6.42708 1.90621C6.58681 2.06593 6.66667 2.26385 6.66667 2.49996V3.33329H13.3333V2.49996C13.3333 2.26385 13.4132 2.06593 13.5729 1.90621C13.7326 1.74649 13.9306 1.66663 14.1667 1.66663C14.4028 1.66663 14.6007 1.74649 14.7604 1.90621C14.9201 2.06593 15 2.26385 15 2.49996V3.33329H15.8333C16.2917 3.33329 16.684 3.49649 17.0104 3.82288C17.3368 4.14927 17.5 4.54163 17.5 4.99996V16.6666C17.5 17.125 17.3368 17.5173 17.0104 17.8437C16.684 18.1701 16.2917 18.3333 15.8333 18.3333H4.16667ZM4.16667 16.6666H15.8333V8.33329H4.16667V16.6666ZM4.16667 6.66663H15.8333V4.99996H4.16667V6.66663Z"
      fill="#8B8C9E"
    />
  </svg>,
  'CalendarIcon'
);
