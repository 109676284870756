import { Box, styled } from '@mui/material';

export const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '18px 24px 12px',
}));

export const ButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0 8px',
}));
