import { styled, Box, IconButton, Typography, TextField } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '500px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  border: '1px solid rgba(38,40,66, 0.08)',
  borderRadius: '12px',
  overflow: 'hidden',
}));

export const Header = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px',
  borderBottom: '1px solid rgba(38,40,66, 0.08)',
}));

export const Title = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
}));

export const CloseButton = styled(IconButton)(() => ({
  padding: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const Body = styled(Box)(() => ({
  padding: '20px 16px',
}));

export const Input = styled(TextField)(() => ({
  '& .MuiInput-root': {
    borderRadius: '10px',
  },
}));

export const Footer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '0 8px',
  padding: '12px 16px',
  backgroundColor: '#F7F8FC',
}));
