import { RangeCalendar } from '@mantine/dates';
import { Box, styled } from '@mui/material';

export const InputField = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: theme.palette.main.permanentNeutralContainer,
  cursor: 'pointer',
  padding: theme.spacing(1.5, 2),
  borderRadius: theme.spacing(2.5),
}));

export const IconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
  '& path': {
    fill: theme.palette.main.permanentNeutralVariant,
  },
}));

export const StyledCalendar = styled(RangeCalendar)(({ theme }) => ({
  '& .mantine-RangeCalendar-calendarHeaderLevelIcon': {
    display: 'none',
  },
  '& .mantine-RangeCalendar-weekday': {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  '& .mantine-RangeCalendar-day': {
    width: '32px',
    height: '32px',
    fontWeight: 600,
    lineHeight: 'normal',
  },
  '& .mantine-RangeCalendar-day[data-first-in-range], .mantine-RangeCalendar-day[data-last-in-range]':
    {
      borderRadius: '8px',
    },
  '& .mantine-RangeCalendar-day[data-in-range]:before': {
    backgroundColor: theme.palette.main.permanentPrimaryContainer,
    display: 'block',
    content: '""',
    position: 'absolute',
    left: 0,
    right: '32px',
    height: '32px',
    top: 0,
    zIndex: '1',
  },
  '& .mantine-RangeCalendar-day[data-first-in-range]:before': {
    position: 'relative',
    height: 0,
  },
  '& .mantine-RangeCalendar-calendarHeaderControl': {
    svg: {
      width: '20px',
      height: '20px',
      path: {
        fill: theme.palette.main.permanentNeutral,
      },
    },
  },
  '& .mantine-RangeCalendar-calendarBase': {
    padding: '12px 16px',
  },
  '& .mantine-RangeCalendar-cell': {
    borderBottom: '8px solid transparent',
    backgroundClip: 'content-box',
  },
  '& td:has(.mantine-RangeCalendar-day[data-first-in-range])': {
    backgroundColor: theme.palette.main.permanentPrimaryContainer,
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  '& td:has(.mantine-RangeCalendar-day[data-last-in-range])': {
    backgroundColor: theme.palette.main.permanentPrimaryContainer,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}));
