import { useCallback, useEffect, useState } from 'react';
import {
  CourseProgressResponse,
  CoursesProgressOrderKind,
  EmployeeProgressSort,
  FeedCourseItem,
  MoodleOrderKind,
  FeedEntityTypes,
} from '@dar/api-interfaces';
import { SearchInput } from '@dar/components/searchInput';
import { TablePaginationWOTotal } from '@dar/components/tablePaginationWOTotal/TablePaginationWOTotal';
import {
  getCoursesByQuickSearch,
  getEmployeesProgress,
} from '@dar/services/5q';
import { Box, CircularProgress, Table, Typography } from '@mui/material';
import { useChannelInfo } from 'apps/dar-front/src/contexts/channel.context';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { ReactComponent as NoCoursesIcon } from '../../../../../assets/icons/course-empty.svg';
import {
  EmptyContainer,
  TableContainer,
  TableWrapper,
  Toolbar,
} from './AllCoursesTable.style';
import { CoursesProgressTableBody } from './components/coursesProgressTableBody/CoursesProgressTableBody';
import { CoursesProgressTableHeader } from './components/coursesProgressTableHeader/CoursesProgressTableHeader';

export const AllCoursesTable = () => {
  const { t } = useTranslation();
  const { channelId } = useChannelInfo();
  const [courses, setCourses] = useState<CourseProgressResponse[]>([]);
  const [coursesIds, setCoursesIds] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
  });
  const [sortValues, setSortValues] = useState<EmployeeProgressSort>({
    field: CoursesProgressOrderKind.ACTIVE,
    sort_criteria: MoodleOrderKind.DESC,
  });
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 400);

  const [nextPageClickDisabled, setNextPageClickDisabled] = useState(false);

  const { data: coursesList, isFetching: quickSearchFetching } = useQuery(
    ['qs-progress', debouncedSearch, channelId],
    {
      queryFn: async () => {
        return getCoursesByQuickSearch({
          filter_by: {
            channel_ids: [`${channelId}`],
            types: [FeedEntityTypes.COURSE],
            course_statuses: ['PUBLISHED', 'DRAFT', 'ACTIVATED'],
          },
          sort_by: [
            {
              field: 'publication_date',
              sort_criteria: 'desc',
            },
          ],
          search: debouncedSearch,
        })
          .then((res) => {
            if (res.courses === null) {
              setCoursesIds([]);
              return [];
            }
            setCoursesIds(res.courses.map((course) => course.id));
            return res.courses;
          })
          .catch((err) => {
            console.error(err);
            setCoursesIds([]);
          });
      },
    }
  );

  const { data: coursesProgress, isFetching } = useQuery(
    ['courses-progress', paginationData, sortValues, coursesIds],
    {
      enabled: coursesIds.length > 0,
      queryFn: async () => {
        return getEmployeesProgress({
          page: paginationData.page.toString(),
          size: paginationData.size.toString(),
          sort_by: sortValues,
          content_ids: coursesIds,
        }).then((res) => res || []);
      },
      onSuccess(data) {
        if (data === null) {
          return [];
        }
        if (data.length > rowsPerPage) {
          setNextPageClickDisabled(false);
          return data.splice(-1);
        } else {
          setNextPageClickDisabled(true);
          return data;
        }
      },
      onError(err) {
        console.error(err);
      },
    }
  );

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value as number;

    setRowsPerPage(value);
    setPaginationData({
      page: 0,
      size: parseInt(event.target.value, 10),
    });
  };

  const handleRequestSort = (
    sortField: CoursesProgressOrderKind,
    sortOrder: MoodleOrderKind
  ) => {
    setSortValues({ field: sortField, sort_criteria: sortOrder });
  };

  useEffect(() => {
    if ((coursesList as FeedCourseItem[])?.length > 0) {
      const courses = coursesProgress?.map((progress) => {
        const courseFromQS = (coursesList as FeedCourseItem[]).find(
          (course) => course.id === progress.id
        );

        return courseFromQS
          ? {
              ...progress,
              title: courseFromQS?.course_info.fullname,
            }
          : undefined;
      });
      setCourses(courses);
    } else {
      setCourses([]);
    }
  }, [coursesList, coursesProgress, debouncedSearch]);

  useEffect(() => {
    if (debouncedSearch === '') {
      setCoursesIds([]);
    }
  }, [debouncedSearch]);

  const nextPageClick = useCallback(() => {
    setPaginationData((prev) => ({ ...prev, page: prev.page + 1 }));
  }, [setPaginationData]);

  const prevPageClick = useCallback(() => {
    setPaginationData((prev) => ({ ...prev, page: prev.page - 1 }));
    if (nextPageClickDisabled) {
      setNextPageClickDisabled(false);
    }
  }, [nextPageClickDisabled, setNextPageClickDisabled, setPaginationData]);

  return (
    <Box>
      <Toolbar>
        <SearchInput
          placeholder={t('common.course_search_placeholder')}
          onChange={(value) => setSearch(value)}
        />
      </Toolbar>
      {isFetching || quickSearchFetching ? (
        <EmptyContainer>
          <CircularProgress size={50} />
        </EmptyContainer>
      ) : courses?.length > 0 ? (
        <div>
          <TableWrapper>
            <TableContainer style={{ background: '#FFF' }}>
              <Table>
                <CoursesProgressTableHeader
                  sortField={sortValues.field}
                  sortOrder={sortValues.sort_criteria}
                  onRequestSort={handleRequestSort}
                  disableSort={Boolean(debouncedSearch)}
                />
                <CoursesProgressTableBody courses={courses} />
              </Table>
            </TableContainer>
            <TablePaginationWOTotal
              rowsPerPage={rowsPerPage}
              paginationData={paginationData}
              nextPageClickDisabled={nextPageClickDisabled}
              elementsOnPage={courses?.length}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              nextPageClick={nextPageClick}
              prevPageClick={prevPageClick}
            />
          </TableWrapper>
        </div>
      ) : (
        <EmptyContainer>
          <NoCoursesIcon />
          <Typography variant="h5" sx={{ color: '#6D6E85', marginTop: '16px' }}>
            {t('progress.all_courses_table_empty')}
          </Typography>
        </EmptyContainer>
      )}
    </Box>
  );
};
