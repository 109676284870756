import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { environment } from '@dar/environments/environment';
import { authLmsService } from '@dar/services/authLms';
import { toast } from '@dartech/dms-ui';
import { Button, styled } from '@mui/material';
import { useChannelInfo } from 'apps/dar-front/src/contexts/channel.context';

export const LmsAuth = () => {
  const navigate = useNavigate();

  const { refetchChannel } = useChannelInfo();
  useEffect(() => {
    if (environment.griffonBucketId === environment.griffonLmsBucketId) {
      navigate('/lms');
    }
  }, [navigate]);

  useEffect(() => {
    const callback = (event: { origin: string; data: string }) => {
      if (event.origin === environment.lmsFront && event.data) {
        try {
          const payload = JSON.parse(event.data);
          if (payload.type === 'authSuccess') {
            authLmsService.refreshTokenAndProfile(payload.token).then((r) => {
              if (r) {
                toast.success('Auth successful');
                refetchChannel();
                navigate('/lms');
              }
            });
          } else if (payload.type === 'authError') {
            toast.error('Auth error');
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
    window.addEventListener('message', callback, false);
    return () => {
      window.removeEventListener('message', callback);
    };
  }, [navigate, refetchChannel]);

  const handleAuthClick = () => {
    window.open(
      `${environment.lmsFront}/auth?darleanIntegration`,
      '_blank',
      'resizable,scrollbars,status,top=200,left=500,width=480,height=620'
    );
  };

  return (
    <Wrapper>
      <Button variant="contained" onClick={handleAuthClick}>
        Authorize
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  place-items: center;
`;
