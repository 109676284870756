import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CourseCustomField,
  CourseInfo,
  FeedCourseItem,
  MentorCourseDetails,
} from '@dar/api-interfaces';

export const findCustomField = (
  fields: CourseCustomField[],
  shortName: string,
  raw?: boolean
) => {
  const field = fields?.find((field) => field.shortname === shortName);
  return raw ? field?.valueraw : field?.value;
};

export const courseAdapter = (courseDetailDto: FeedCourseItem): CourseInfo => {
  const customFields = Object.values(courseDetailDto.course_info.customfields);

  const forWhoDescriptions = customFields
    ?.filter(
      (name) =>
        name.shortname?.includes('forwho') && name.shortname !== 'course_forwho'
    )
    .map((field) => field.value);

  const coursePrerequisites = customFields?.reduce((result, field) => {
    if (field.shortname?.startsWith(`course_prerequisite`)) {
      if (field.value) {
        result.push(field.value);
      }
    }

    return result;
  }, [] as string[]);

  const isPackageFlag = findCustomField(
    customFields,
    'profession_package',
    true
  );

  const bonus_courses_by_acl =
    courseDetailDto.bonus_courses?.map((course) => +course.course_info.id) ||
    [];

  const accessPeriod = findCustomField(customFields, 'access_period')?.split(
    ';'
  )[0];

  return {
    id: courseDetailDto.course_info.id,
    paymentStatus: courseDetailDto.payment_status,
    fullName: courseDetailDto.course_info.fullname,
    prices: courseDetailDto.product_prices?.map((p) => p.price_info) ?? [],
    bySubscription: false,
    product_subscription_ids: courseDetailDto.product_subscription_ids,
    total_activities: courseDetailDto?.course_info.total_activities ?? 0,
    startdate: +(courseDetailDto?.course_info.startdate ?? 0),
    // custom fields
    language: findCustomField(customFields, 'course_language', true) ?? '',
    course_card_image: findCustomField(customFields, 'course_card_image') || '',
    level: findCustomField(customFields, 'course_level', true) || '',
    duration: Number(findCustomField(customFields, 'course_duration') || 0),
    status: findCustomField(customFields, 'course_status') || '',
    coursetype: courseDetailDto.course_info.coursetype,
    isPackage: isPackageFlag ? Boolean(+isPackageFlag) : false,
    // channel info
    channel: courseDetailDto.channel_info
      ? {
          id: courseDetailDto.channel_info.id,
          name: courseDetailDto.channel_info.name,
          logo: courseDetailDto.channel_info.logo
            ? courseDetailDto.channel_info.logo?.url
            : '',
        }
      : null,
    enddate: courseDetailDto?.course_info.invite_enddate
      ? courseDetailDto?.course_info.invite_enddate
      : courseDetailDto?.course_info.enddate,

    information: findCustomField(customFields, 'course_information') || '',
    salary: findCustomField(customFields, 'course_salary')?.split(';') || [],
    course_cardtext: findCustomField(customFields, 'course_cardtext') || '',
    forWhoTitles:
      findCustomField(customFields, 'course_forwho')?.split(';') || [],
    forWhoDescriptions: forWhoDescriptions,
    bonus_courses: courseDetailDto.course_info.bonus_courses || [],
    bonus_courses_by_acl,
    mentors: courseDetailDto.course_info.mentor ?? [],
    sections: courseDetailDto.course_info.sections ?? [],
    skills: courseDetailDto.course_info.skills ?? [],
    summary: courseDetailDto.course_info.summary || '',
    isSellable: Boolean(
      Number(findCustomField(customFields, 'is_sellable', true))
    ),
    invite_status: courseDetailDto.invite_status,
    video:
      findCustomField(customFields, 'course_trailer_gcore')?.replace(
        '.gcdn.co',
        '.servicecdn.ru'
      ) || '',
    course_prerequisites: coursePrerequisites,
    learn: customFields
      ?.filter((field) => field.shortname?.includes('learn') && field.value)
      .map((field) => field.value),
    trailer_cover: findCustomField(customFields, 'trailer_cover') || null,
    accessPeriod: accessPeriod ? Number(accessPeriod) : null,
    visible_workspace_ids:
      findCustomField(customFields, 'visible_workspace_ids', true)
        ?.split(';')
        .filter(Boolean) || [],
    workspace_id: findCustomField(customFields, 'workspace_id') || null,
    holding_id: findCustomField(customFields, 'holding_id') || null,
  };
};

export function quickSearchCourseToMentorCourseAdapter(
  course: FeedCourseItem
): MentorCourseDetails {
  const id = +course.course_info?.id || 0;
  const customfields = course.course_info?.customfields
    ? Object.values(course.course_info.customfields)
    : [];
  const fullname = course.course_info?.fullname || '';
  const summary = course.course_info?.summary || '';
  const price =
    course.product_prices?.length > 0
      ? course.product_prices[course.product_prices.length - 1].price_info
      : null;
  return {
    id,
    fullname,
    summary,
    customfields,
    price,
  };
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
