import { FC, useMemo } from 'react';
import { CourseAssignInfo } from '@dar/api-interfaces';
import { theme } from '@dartech/dms-ui';
import OutlinedFlag from '@dartech/dms-ui/dist/v2/icons/content/OutlinedFlag';
import { Box, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT } from 'apps/dar-front/src/constants';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  CardContainer,
  SecondaryText,
  StatusContainer,
  StyledProgressBar,
} from './CourseCard.style';

type Props = {
  courseInfo: CourseAssignInfo;
};

export const StatsCourseCard: FC<Props> = ({ courseInfo }) => {
  const { t } = useTranslation();

  const formattedStartDate = courseInfo?.start_date
    ? format(new Date(courseInfo.start_date), DEFAULT_DATE_FORMAT)
    : null;

  const formattedEndDate = courseInfo?.end_date
    ? format(new Date(courseInfo.end_date), DEFAULT_DATE_FORMAT)
    : null;

  const status: { text: string; color: 'primary' | 'error' } = useMemo(() => {
    if (courseInfo.end_date) {
      if (new Date(courseInfo.end_date) > new Date()) {
        return {
          text: t('employee_statistics.complete_before', {
            date: formattedEndDate,
          }),
          color: 'primary',
        };
      } else {
        return {
          text: t('employee_statistics.overdue_from', {
            date: formattedEndDate,
          }),
          color: 'error',
        };
      }
    }
    return null;
  }, [courseInfo.end_date, formattedEndDate, t]);

  return (
    <CardContainer>
      <Box>
        <Typography variant="textLarge_2">{courseInfo.course_name}</Typography>
        {status && courseInfo.progress < 100 ? (
          <StatusContainer statusColor={status.color}>
            <OutlinedFlag />
            <SecondaryText variant="textSmall_1">{status.text}</SecondaryText>
          </StatusContainer>
        ) : null}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        color={theme.palette.common.black}
        width="100%"
      >
        <StyledProgressBar
          variant="determinate"
          color={Number(courseInfo.progress) === 100 ? 'secondary' : 'primary'}
          value={courseInfo.progress ? Number(courseInfo.progress) : 0}
        />
        <SecondaryText variant="textSmall_1">
          {courseInfo.progress ? Math.round(courseInfo.progress) : 0}%
        </SecondaryText>
      </Box>
      <Box display="flex" flexDirection="column" rowGap="8px">
        <Box display="flex" justifyContent="space-between">
          <SecondaryText variant="textSmall_1">
            {t('employee_statistics.assigned_date')}:
          </SecondaryText>
          <SecondaryText variant="textSmall_1">
            {format(new Date(courseInfo.created_at), DEFAULT_DATE_FORMAT)}
          </SecondaryText>
        </Box>
        {(courseInfo.start_date || courseInfo.end_date) && (
          <Box display="flex" justifyContent="space-between">
            <SecondaryText variant="textSmall_1">
              {t('employee_statistics.access_period')}:
            </SecondaryText>
            <SecondaryText variant="textSmall_1">
              {courseInfo.start_date && courseInfo.end_date
                ? `${formattedStartDate} - ${formattedEndDate}`
                : courseInfo.start_date && !courseInfo.end_date
                ? t('employee_statistics.date_from', {
                    date: formattedStartDate,
                  })
                : !courseInfo.start_date && courseInfo.end_date
                ? t('employee_statistics.date_before', {
                    date: formattedEndDate,
                  })
                : null}
            </SecondaryText>
          </Box>
        )}
      </Box>
    </CardContainer>
  );
};
