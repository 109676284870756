import { FC } from 'react';
import { SortDirection, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultSortIcon } from '../../../../../assets/icons/sort_icon_default.svg';
import { StyledSortLabel, StyledTableCell } from './CoursesTableHeader.style';

type Props = {
  onRequestSort: (property: string, orderdDirection: SortDirection) => void;
  sortOrder: SortDirection;
  sortField: string;
  disableSort: boolean;
};

export interface CoursesProgressColumn {
  id: string;
  label: string;
  disableSort?: boolean;
  align?: 'left' | 'right' | 'center';
}

export const CoursesTableHeader: FC<Props> = ({
  sortField,
  sortOrder,
  onRequestSort,
  disableSort = false,
}) => {
  const { t } = useTranslation();

  const columns: CoursesProgressColumn[] = [
    {
      id: 'title',
      label: t('course.course_table_name'),
      disableSort,
    },
    {
      id: 'content',
      label: t('course.course_table_content'),
      align: 'left',
      disableSort,
    },
    {
      id: 'status',
      label: t('course.course_table_status'),
      align: 'left',
      disableSort,
    },
    {
      id: 'menu',
      label: '',
      align: 'left',
      disableSort,
    },
  ];

  const handleSort = (property: string) => {
    if (property === sortField) {
      onRequestSort(property, sortOrder === 'desc' ? 'asc' : 'desc');
    } else {
      onRequestSort(property, 'desc');
    }
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, idx) => {
          const isSortable = !column.disableSort;
          const isSorting = isSortable && sortField === column.id;

          return (
            <StyledTableCell
              key={column.id}
              sortDirection={isSorting ? sortOrder : false}
              align={column.align ? column.align : undefined}
            >
              <StyledSortLabel
                onClick={() => (isSortable ? handleSort(column.id) : undefined)}
                IconComponent={DefaultSortIcon}
                active={column.id === sortField && isSortable}
                hideSortIcon={!isSortable}
                disabled={!isSortable}
              >
                <Typography variant="textMedium_2">{column.label}</Typography>
              </StyledSortLabel>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
