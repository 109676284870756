import { FC } from 'react';
import { AssignCourseForm, User } from '@dar/api-interfaces';
import { AutoCompleteSelect } from '@dartech/dms-ui';
import { Box, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from './EmployeeSelect.style';

type Props = {
  control: Control<AssignCourseForm, any>;
  employeesList: User[];
  locale: { search: string; apply: string; clear: string };
  loading: boolean;
};

export const EmployeeSelect: FC<Props> = ({
  control,
  employeesList,
  locale,
  loading,
}) => {
  const { t } = useTranslation();

  const RenderOptionItem = ({ option }) => {
    if (option) {
      return (
        <Box display="flex" alignItems="center">
          <UserAvatar alt={option.displayName} src={option.photoUrl} />
          <Box display="flex" alignItems="center" gap="4px">
            <Typography variant="h6">{option.displayName}</Typography>
          </Box>
        </Box>
      );
    }
    return <Box>{'No option'}</Box>;
  };

  return (
    <Controller
      name="employees"
      rules={{
        validate: (val) => {
          if (!val || (val && val.length === 0)) {
            return t('assign_course.employeelist_empty_error');
          }
        },
      }}
      control={control}
      render={({ field, fieldState }) => (
        <AutoCompleteSelect
          {...field}
          locale={locale}
          loading={loading}
          label={t('assign_course.employee_label')}
          onSelect={(value: User) => field.onChange(value)}
          popperWidth={412}
          prompt={t('assign_course.prompt')}
          valueId="id"
          valueName="displayName"
          variant="filter"
          options={employeesList}
          error={fieldState?.error?.message}
          // styleClasses={{
          //   outlinedButton: styles.select,
          //   error: styles.autocompleteError,
          // }}
          value={field.value as unknown as User}
          renderOption={(option) => <RenderOptionItem option={option} />}
        />
      )}
    />
  );
};
