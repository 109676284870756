import { FC, useMemo, useState } from 'react';
import {
  AclAction,
  CourseInfoWithLMS,
  DmsLmsPermissions,
} from '@dar/api-interfaces';
import { useLmsRedirect } from '@dar/hooks/useLmsRedirect';
import { usePermissions } from '@dar/hooks/usePermissions';
import { getCoursesProgress } from '@dar/services/5q';
import { Statuses } from '@dartech/dms-ui';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, Skeleton, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  MenuButton,
  StyledTableRow,
  TableBody,
} from './CoursesTableBody.style';

type Props = {
  courses: CourseInfoWithLMS[];
  onAccessEdit: (courseId: number) => void;
  onEditCourseName: (id: number, name: string) => void;
  onCourseArchive: (id: number) => void;
  onCourseUnarchive: (id: number) => void;
};

const statusColors = {
  HIDDEN: 'purple',
  DARFT: 'gray',
  ACTIVATED: 'blue',
  PUBLISHED: 'blue',
};

export const CoursesTableBody: FC<Props> = ({
  courses,
  onAccessEdit,
  onEditCourseName,
  onCourseArchive,
  onCourseUnarchive,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [openMenu, setOpenMenu] = useState<number>(0);
  const open = Boolean(anchorEl);

  const { checkPermissions } = usePermissions();

  const { openCourse } = useLmsRedirect('mentor');

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    courseId: number
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenMenu(courseId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { data, isFetching: isProgressLoading } = useQuery([courses], {
    queryFn: async () => {
      const response = await getCoursesProgress({
        courses_ids: courses.map((c) => c.id.replace('course_', '')),
      }).catch((e) => {
        console.error(e);
        return null;
      });

      return courses.reduce<
        Record<string, { totalSections: number; totalActivities: number }>
      >((acc, c) => {
        const course = response?.courses.find(
          (course) => course.id === +c.id.replace('course_', '')
        );
        acc[c.id] = {
          totalActivities: course?.total_activities || 0,
          totalSections: course?.total_sections || 0,
        };
        return acc;
      }, {});
    },
  });

  const hasAccessToCourseStatusUpdate = useMemo(() => {
    return checkPermissions(
      DmsLmsPermissions.LMS_COURSE_STATUS,
      AclAction.WRITE
    );
  }, [checkPermissions]);

  const hasAccessToCourseNameUpdate = useMemo(() => {
    return checkPermissions(DmsLmsPermissions.LMS_COURSE, AclAction.WRITE);
  }, [checkPermissions]);

  const hasAccessToCourseProgramUpdate = useMemo(() => {
    return checkPermissions(
      DmsLmsPermissions.LMS_COURSE_PROGRAM,
      AclAction.WRITE
    );
  }, [checkPermissions]);

  return (
    <TableBody>
      {courses.map(({ id, status, fullName }) => {
        const courseId = +id.replace('course_', '');
        return (
          <StyledTableRow key={id} hover>
            <TableCell>{fullName}</TableCell>
            <TableCell sx={{ minWidth: '130px' }}>
              {!isProgressLoading ? (
                `${t('course_card.modules', {
                  ns: 'lms',
                  count: data[id].totalSections,
                })} ${t('course_card.lessons', {
                  ns: 'lms',
                  count: data[id].totalActivities,
                })}`
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  sx={{ borderRadius: '8px' }}
                />
              )}
            </TableCell>
            <TableCell sx={{ width: '160px' }}>
              <Statuses
                size={'small'}
                color={statusColors[status] || 'gray'}
                label={t(`course.${status.toLocaleLowerCase()}`, {
                  ns: 'lms',
                })}
              />
            </TableCell>
            <TableCell width={'48px'}>
              <MenuButton
                onClick={(e) => {
                  handleMenuClick(e, courseId);
                }}
              >
                <MoreVertIcon />
              </MenuButton>
            </TableCell>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={open && openMenu === courseId}
              onClose={handleMenuClose}
            >
              {hasAccessToCourseStatusUpdate && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuClose();
                    onAccessEdit(courseId);
                  }}
                >
                  {status === 'ACTIVATED' ||
                  status === 'PUBLISHED' ||
                  status === 'HIDDEN'
                    ? t('course.edit_access', { ns: 'lms' })
                    : t('course.publish', { ns: 'lms' })}
                </MenuItem>
              )}
              {hasAccessToCourseNameUpdate && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuClose();
                    onEditCourseName(courseId, fullName);
                  }}
                >
                  {t('course.edit_name')}
                </MenuItem>
              )}
              {status !== 'ARCHIVED' && hasAccessToCourseProgramUpdate && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuClose();
                    openCourse(courseId);
                  }}
                >
                  {t('course.edit_program')}
                </MenuItem>
              )}
              {status !== 'ARCHIVED' && hasAccessToCourseStatusUpdate && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuClose();
                    onCourseArchive(courseId);
                  }}
                >
                  {t('course.archive_course')}
                </MenuItem>
              )}
              {status === 'ARCHIVED' && hasAccessToCourseStatusUpdate && (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuClose();
                    onCourseUnarchive(courseId);
                  }}
                >
                  {t('course.unarchived_course')}
                </MenuItem>
              )}
            </Menu>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};
