import { Box, Snackbar, styled } from '@mui/material';

export const StyledToaster = styled(Snackbar)(() => ({
  width: '328px',
  height: '48px',
  borderRadius: '10px',
}));

export const ToastWrapper = styled(Box)(() => ({
  display: 'flex',
  columnGap: '16px',
  justifyContent: 'center',
  alignItems: 'center',
}));
