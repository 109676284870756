import { FC, useCallback } from 'react';
import { AssignEndAction, CourseInviteStatus } from '@dar/api-interfaces';
import { useLmsRedirect } from '@dar/hooks/useLmsRedirect';
import { getCoursesProgress } from '@dar/services/5q';
import { addMatomoEvent, MatomoKeys } from '@dar/utils/metrics';
import { Skeleton, Typography } from '@mui/material';
import { compareAsc, format, isBefore, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as FlagIcon } from '../../assets/icons/icon-flag.svg';
import { ReactComponent as CourseCardImg } from '../../assets/img/course-card-img.svg';
import {
  Button,
  ButtonsWrap,
  Img,
  ImgWrap,
  LessonsModules,
  ModulesLessonsWrap,
  Name,
  ProgressWrap,
  StatusChip,
  StyledProgress,
  Wrapper,
} from './CourseCard.styles';

type Props = {
  id: string;
  img: string;
  name: string;
  progress: number;
  inviteStatus: CourseInviteStatus;
  endDate?: string;
  startDate?: string;
  endAction?: AssignEndAction;
  onGetAccess?: () => void;
};

export const CourseCard: FC<Props> = ({
  id,
  img,
  name,
  progress,
  inviteStatus,
  endDate,
  startDate,
  endAction,
  onGetAccess,
}) => {
  const { t } = useTranslation();

  const { openCourse } = useLmsRedirect();

  const handleCourseOpen = useCallback(() => {
    openCourse(+id);
  }, [openCourse, id]);

  const {
    data: {
      totalSections,
      totalActivities,
      completedActivities,
      courseProgress,
    },
    isFetching: isProgressLoading,
  } = useQuery(['course_stat', id], {
    queryFn: async () => {
      const response = await getCoursesProgress({ courses_ids: [id] }).catch(
        (e) => {
          console.error(e);
          return null;
        }
      );

      const course =
        response && response.courses.length > 0 ? response.courses[0] : null;
      const moodleProgress = course
        ? Math.round(
            (course.completed_activities / course.total_activities) * 100
          )
        : 0;
      return {
        courseProgress: progress || course?.progress || moodleProgress || 0,
        completedActivities: course?.completed_activities || 0,
        totalActivities: course?.total_activities || 0,
        totalSections: course?.total_sections || 0,
      };
    },
    placeholderData: {
      courseProgress: 0,
      completedActivities: 0,
      totalActivities: 0,
      totalSections: 0,
    },
  });

  const isOverdue = endDate && compareAsc(parseISO(endDate), new Date()) === -1;
  const isDateBeforeNow =
    startDate && isBefore(new Date(), parseISO(startDate));

  return (
    <Wrapper>
      <ImgWrap>{img ? <Img src={img} alt="" /> : <CourseCardImg />}</ImgWrap>

      {inviteStatus === 'invited' && endDate && (
        <StatusChip isOverdue={isOverdue}>
          <FlagIcon />
          {isDateBeforeNow
            ? t('course_card.status_assign')
            : `${t('course_card.status_end')} ${format(
                parseISO(endDate),
                'dd.MM.yy'
              )}`}
        </StatusChip>
      )}

      <Name>{name}</Name>

      {inviteStatus !== 'invited' && ( // TODO нужно добавить условие: когда есть доступ, и студент сам его получил
        <LessonsModules>
          {!isProgressLoading ? (
            `${t('course_card.modules', {
              ns: 'lms',
              count: totalSections,
            })} ${t('course_card.lessons', {
              ns: 'lms',
              count: totalActivities,
            })}`
          ) : (
            <Skeleton
              variant="rectangular"
              width={'100%'}
              sx={{ borderRadius: '8px' }}
            />
          )}
        </LessonsModules>
      )}

      {completedActivities === 0 &&
        courseProgress === 0 &&
        inviteStatus === 'invited' && (
          <ModulesLessonsWrap>
            <Typography variant="textSmall_1">
              {t('course_card.modules_other', { count: totalSections })}{' '}
              {t('course_card.lessons_other', { count: totalActivities })}
            </Typography>
          </ModulesLessonsWrap>
        )}

      {/*{!isProgressLoading &&*/}
      {/*  completedActivities === 0 &&*/}
      {/*  inviteStatus === 'invited' && (*/}
      {/*    <div>*/}
      {/*      <Chip label={t('common.assigned_badge', { ns: 'lms' })} />*/}
      {/*    </div>*/}
      {/*  )}*/}

      <ButtonsWrap>
        {completedActivities > 0 &&
          courseProgress > 0 &&
          inviteStatus === 'invited' && (
            <ProgressWrap>
              <StyledProgress
                sx={{ width: '100%' }}
                value={Math.ceil(courseProgress)}
                isComplete={Math.ceil(courseProgress) === 100}
              />
              <Typography>{Math.ceil(courseProgress)}%</Typography>
            </ProgressWrap>
          )}

        {(isOverdue && endAction === AssignEndAction.CLOSE_ACCESS) ||
        isDateBeforeNow ? (
          <Button
            variant="primary"
            size="large"
            disabled
            title={
              isDateBeforeNow
                ? t('course_card.access_started', {
                    startDate:
                      startDate && format(parseISO(startDate), 'dd.MM.yy'),
                  })
                : t('course_card.access_closed', { ns: 'lms' })
            }
            onClick={() => {
              console.log('access closed');
            }}
          />
        ) : (
          <>
            {inviteStatus === 'not_invited' && (
              <Button
                variant="secondary"
                size="large"
                title={t('common.get_assign_btn', { ns: 'lms' })}
                onClick={() => {
                  addMatomoEvent(MatomoKeys.CLICK_BTN_COURSE_GET_ACCESS, {
                    courseId: id,
                  });
                  onGetAccess();
                }}
              />
            )}

            {completedActivities > 0 && inviteStatus === 'invited' && (
              <Button
                variant="secondary"
                size="large"
                title={t('common.continue_btn', { ns: 'lms' })}
                onClick={() => {
                  addMatomoEvent(MatomoKeys.CLICK_BTN_COURSE_CONTINIUE, {
                    courseId: id,
                  });
                  handleCourseOpen();
                }}
              />
            )}

            {completedActivities === 0 && inviteStatus === 'invited' && (
              <Button
                variant="primary"
                size="large"
                title={t('common.start_course_btn', { ns: 'lms' })}
                onClick={() => {
                  addMatomoEvent(MatomoKeys.CLICK_BTN_COURSE_START, {
                    courseId: id,
                  });
                  handleCourseOpen();
                }}
              />
            )}
          </>
        )}
      </ButtonsWrap>
    </Wrapper>
  );
};
