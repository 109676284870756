import {
  Avatar,
  LinearProgress,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

export const StickyTableCell = styled(TableCell)(() => ({
  left: 0,
  minWidth: '220px',
  zIndex: 10,
  display: 'flex',
  position: 'sticky',
  alignItems: 'center',
  backgroundColor: 'white',
}));

export const StyledProgressBar = styled(LinearProgress)(() => ({
  width: '40px',
  height: '6px',
  marginRight: '8px',
  borderRadius: '20px',
  '& .MuiLinearProgress-bar': {
    borderRadius: '20px',
  },
  '& .MuiLinearProgress-barColorSecondary': {
    backgroundColor: '#2DB77B!important',
  },
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: '#2628421F',
  },
}));

export const StyledTableBody = styled(TableBody)(() => ({
  background: '#fff',
}));

export const AccessText = styled(Typography)(() => ({
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '110px',
}));

export const StyledTableRow = styled(TableRow)(() => ({
  border: 'none',
  '&:hover td:first-of-type': {
    background: '#F0F0F7',
  },
}));

export const UserAvatar = styled(Avatar)(() => ({
  height: '32px',
  width: '32px',
  margin: '0 8px',
}));
