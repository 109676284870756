import { Box, styled } from '@mui/material';

export const Toolbar = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0 8px;
  padding: 8px 20px;
  border-bottom: 1px solid rgba(38, 40, 66, 0.12);
`;

export const ContentWrapper = styled(Box)(() => ({
  width: '100%',
  height: 'calc(100% - 64px)',
  padding: '16px 14px',
}));

export const Grid = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
}));
