import { styled, Pagination } from '@mui/material';

export const StyledPagination = styled(Pagination)(() => ({
  '& .MuiPaginationItem-outlinedSecondary.Mui-selected': {
    backgroundColor: '#2628421F!important',
    color: '#262842!important',
  },
  '& .MuiPaginationItem-rounded': {
    borderRadius: '10px',
    backgroundColor: 'white',
    '&:hover': {
      background: '#2628420A',
    },
  },
}));
