import { WorkspaceToken } from '@dar/api-interfaces';
import { environment } from '@dar/environments/environment';
import { apiClient } from './clients';

export const getWorkspaceToken = (workspaceId: string) => {
  return apiClient
    .get<WorkspaceToken>(
      `${environment.dmsBaseApi}/hcms/main-api/workspace/v2/${workspaceId}/token`
    )
    .then((res) => res.data);
};
