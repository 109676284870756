import { Box, styled, Popper as MuiPopper, css } from '@mui/material';

export const TimePickerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>`
  .MuiInputBase-root {
    padding-right: 12px;
    background-color: #f6f8ff;
    border-radius: 10px;

    fieldset {
      border: 2px solid transparent;
    }

    ${({ isOpen }) =>
      !isOpen &&
      css`
        &:hover,
        &.Mui-disabled {
          .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
          }
        }
      `}

    ${({ isOpen }) =>
      isOpen &&
      css`
        .MuiOutlinedInput-notchedOutline {
          border-color: #039be6 !important;
        }
      `}

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #039be6 !important;
      }
    }
  }

  .MuiInputBase-input {
    padding: 10px 14px;
  }

  .MuiButtonBase-root {
    padding: 4px;
  }

  input {
    color: #262842;
  }
`;

export const Popper = styled(MuiPopper)`
  width: 168px;
  margin-top: 5px !important;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(38, 40, 66, 0.12);
  box-shadow: 0 8px 16px 0 rgba(38, 40, 66, 0.1);

  .MuiButtonBase-root {
    margin: 0 !important;
    padding: 11px 12px;
  }
`;
