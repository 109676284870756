import { authService } from '@dar-dms/topbar';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { authLmsService } from './authLms';
import { commonExceptionHandler, requestHeaderInterceptor } from './handlers';

declare module 'axios' {
  export interface AxiosRequestConfig {
    bypassAlertOnErrorCodes?: string[];
  }
}

export const apiClient = authService.createHttpClient();

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  return requestHeaderInterceptor(config);
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  commonExceptionHandler
);

export const lmsClient = authLmsService.createHttpClient();

export const bffClient = authLmsService.createHttpClient();

bffClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.headers.set(
    'workspace-token',
    localStorage.getItem('workspace_token')
  );
  return config;
});
