import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  styled,
} from '@mui/material';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.main.surface2}`,
  height: '72px',
  padding: '24px',
}));

export const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
}));

export const PersonalInfoContainer = styled(Box)(({ theme }) => ({
  padding: '24px',
  width: '360px',
  borderRight: `1px solid ${theme.palette.main.surface2}`,
}));

export const CoursesStatisticsContainer = styled(Box)(() => ({
  width: 'calc(100% - 344px)',
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  padding: '12px 16px',
  backgroundColor: theme.palette.main.background2,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
  marginBottom: '16px',
  height: 'calc(100vh - 72px - 46px)',
  overflowY: 'auto',
  position: 'relative',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    '& path': {
      fill: theme.palette.main.permanentNeutral,
    },
  },
}));

export const StatContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
}));

export const UserPhoto = styled(Avatar)(() => ({
  width: '84px',
  height: '84px',
}));

export const FieldText = styled(Typography)(({ theme }) => ({
  color: theme.palette.main.permanentOnNeutralVariant,
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.main.permanentNeutral,
}));

export const CoursesHeaderContainer = styled(Box)(() => ({
  padding: '12px 16px',
}));

export const FilterButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  padding: '7px 12px',
  height: '32px',
  backgroundColor: active
    ? theme.palette.main.permanentPrimaryContainer
    : theme.palette.main.permanentNeutralContainer,
  '& span': {
    color: active
      ? theme.palette.main.permanentPrimaryVariant
      : theme.palette.main.permanentNeutral,
  },
  border: 'none',
  '&:hover': {
    border: 'none',
    backgroundColor: active
      ? theme.palette.main.permanentPrimaryContainer
      : theme.palette.main.permanentNeutralContainer,
  },
}));

export const StatCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.main.outline1}`,
  borderRadius: '10px',
  flex: '0 0 calc(50% - 8px)',
  flexDirection: 'column',
  padding: '8px 12px',
}));
