import {
  Avatar,
  TableCell,
  styled,
  TableBody as MuiTableBody,
  Button,
  TableSortLabel,
  Typography,
  CircularProgress,
  Box,
  TableRow,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close_icon.svg';

export const Toolbar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.main.surface2}`,
  padding: '12px 24px',
  backgroundColor: theme.palette.main.background1,
}));

export const EmptyContainer = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 223px)',
  width: 'calc(100vw - 346px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  columnGap: '16px',
  color: theme.palette.secondary['50'],
}));

export const HeaderTableCell = styled(TableCell)(() => ({
  background: 'none',
  padding: '7px 10px',
  whiteSpace: 'nowrap',
  width: 'fit-content',
}));

export const UserAvatar = styled(Avatar)(() => ({
  height: '32px',
  width: '32px',
  margin: '0 8px',
}));

export const TableBody = styled(MuiTableBody)(({ theme }) => ({
  background: theme.palette.main.background1,
}));

export const PaginationButton = styled(Button)(({ theme }) => ({
  width: '40px',
  height: '40px',
  padding: 0,
  minWidth: 0,
  marginRight: '8px',
  backgroundColor: theme.palette.main.background1,
}));

export const StyledSortLabel = styled(TableSortLabel)(({ theme }) => ({
  position: 'relative',
  flexDirection: 'row',
  color: theme.palette.main.permanentOnNeutralVariant,
  fontWeight: 600,
  fontSize: '14px',
  '& svg': {
    opacity: 1,
    overflow: 'initial',
  },
  '&.Mui-active > svg > path': {
    fill: theme.palette.main.permanentNeutral,
  },
}));

export const FilterButton = styled(Button)(() => ({
  padding: '9px 16px',
  marginLeft: '16px',
  '& svg:first-of-type': {
    marginRight: '8px',
  },
  height: '32px',
}));

export const StyledButtonText = styled(Typography)(() => ({
  marginRight: '8px',
}));

export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  margin: 0,
  height: '20px',
  width: '20px',
  '& path': {
    fill: theme.palette.main.permanentNeutral,
  },
  '&:hover': {
    '& path': {
      fill: theme.palette.main.permanentNeutralVariant,
    },
  },
}));

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  '&.MuiCircularProgress-determinate': {
    height: '24px!important',
    width: '24px!important',
  },
  '&.MuiCircularProgress-colorSecondary': {
    '& svg': {
      color: theme.palette.main.background3,
    },
  },
}));

export const ProgressIconContainer = styled(Box)(({ theme }) => ({
  height: '24px',
  width: '24px',
  borderRadius: '50%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.main.permanentSuccess,
}));

export const TableHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.main.background2,
}));
